import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ldb from 'localdata'
import axios from 'axios';
import Detail,{Orders, Profile} from './App';
import * as geolib from 'geolib';
import { db, auth } from './firebaseData';
import moment from 'moment';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import * as geofirestore from 'geofirestore';
import {
  onAuthStateChanged
} from 'firebase/auth';
import Main from './Main';
import {
  doc,
  onSnapshot,
  Timestamp,
  GeoPoint,
  collection,
  query,
  where
} from 'firebase/firestore';
import DasboardPage from './Dashboard';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#272727'
    },
    // primary: {
    //   main:'#44cf8e'
    // }
  }
});
var userData;
var loggedInUser;
const pingAgainToo = async() => {
  return new Promise(async (resolve) => {
    ////console.log('pingAgainToo');
      await loadDataAgainToo();
      resolve('resolved');
  });
}

const pingAgain = async() => {
  return new Promise(async (resolve) => {
    ////console.log('pingAgain');
  await loadDataAgain();
  resolve('resolved');
  })
}

const getAsyncItem = async (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ldb.get(value, function (value2) {
        //console.log('value',value);
        if(value === 'totalValues') {
          //console.log('totalValues', value2);
        }
        resolve(value2);
      })
    } catch(e) {
      //console.log(value,e);
      reject(new Error("Whoops!"));
    }
});
}

const loadDataAgainToo = async() => {
  return new Promise(async (resolve) => {
    ////console.log('loadDataAgainToo');
  //if(found.kDebugMode) print('loadDataAgain');
  try {
    var totalValues;
    try {
      totalValues = JSON.parse(await getAsyncItem('totalValues'));
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues'));
    }
    if(totalValues){} else {
      totalValues = {};
    } 
    if (totalValues['restUpdatedOn'] != null) {
      await getAllData().then(async ()=> {
        ////console.log('here1234');
        await loadStoreData().then(async() => {
          await getUserData().then(async() => {
            await getDisplayData();
          });
        });
      });
    }
  } catch (e) {
    ////console.log(e);
    }
    resolve('resolved');
  });
}
 
const loadDataAgain = async() => {
  return new Promise(async (resolve) => {
    ////console.log('loadDataAgain');
  //if(found.kDebugMode) print('loadDataAgain');
  try {
    var totalValues;
    try {
      totalValues = JSON.parse(await getAsyncItem('totalValues'));
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues'));
    }
    if(totalValues){} else {
      totalValues = {};
    } 
    if (totalValues.restUpdatedOn != null) {
      ////console.log('Here!',totalValues.restUpdatedOn);
    } else {
      await getRest();
    }
  } catch (e) {
    ////console.log(e);
    // if (Hive.isBoxOpen('connectedToServer')) {
    //   connectedToServer = Hive.box('connectedToServer');
    //   await connectedToServer.put('connectedToServer', false);
    // } else {
    //   connectedToServer = await Hive.openBox('connectedToServer');
    //   await connectedToServer.put('connectedToServer', false);
    // }
    // if (deletedBusinesses != null) await deletedBusinesses.cancel();
    // if (restUpdate != null) await restUpdate.cancel();
    // if (storeUpdate != null) await storeUpdate.cancel();
    // if (userUpdate != null) await userUpdate.cancel();
    // if (locationUpdate != null) await locationUpdate.cancel();
    // Fluttertoast.showToast(
    //     msg: "Cant connect to the server. Trying again...",
    //     toastLength: Toast.LENGTH_LONG,
    //     gravity: ToastGravity.BOTTOM,
    //     timeInSecForIosWeb: 3,
    //     backgroundColor: Colors.grey,
    //     textColor: Colors.black,
    //     fontSize: 16.0);
    // Future.delayed(Duration(seconds: 10), () {
    //   loadDataAgain().then((value) async {
    //     if (Hive.isBoxOpen('connectedToServer')) {
    //       connectedToServer = Hive.box('connectedToServer');
    //       await connectedToServer.put('connectedToServer', true);
    //     } else {
    //       connectedToServer = await Hive.openBox('connectedToServer');
    //       await connectedToServer.put('connectedToServer', true);
    //     }
    //   });
    // });
  }
  resolve('resolved');
});
}

const getRest =async()=>{
  return new Promise(async (resolve) => {
    ////console.log('getRest');
  //if(found.kDebugMode) print('getRest');
  var totalValues;
  var boxAllRest;
  var boxAllStore;
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      boxAllRest = JSON.parse((await getAsyncItem('boxAllRest')) ??"{}");
      boxAllStore = JSON.parse((await getAsyncItem('boxAllStore')) ??"{}");
      displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
      displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
      displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
      displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
       boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
       boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }
  ////console.log('get rest');
  var resData = await axios
        .get('https://watson-business.herokuapp.com/business/busdata/');

        ////console.log('get rest2');
        ////console.log(resData.data);
  // Fluttertoast.showToast(msg: 'status ${resData.statusCode}');
  if (resData.status === 200) {
    var restData = resData.data;
    var tot1 = 0;
    //if(found.kDebugMode) print('dds:' + value.size.toString());
    for(var ik=0;ik<restData.length;ik++) {
    // Future.forEach(restData, (element) async {
      // })
      // value.docs.forEach((element) async {
      // allDataList = value.docs;
      //var element = value.docs[tot1];
      //value.docs.forEach((element) async {
        var element = restData[ik];
      var data = {};
      data = element;
      delete data.point;
      // data.remove('point');
      //data['updatedOn'] = data['updatedOn'].toDate();
      if (data['updatedOn'] != null) {
        data['updatedOn'] = new Timestamp(element['updatedOn']['_seconds'],
                element['updatedOn']['_nanoseconds'])
            .toDate();
      }
      if (data['willBeAvailableAt'] != null) {
        data['willBeAvailableAt'] = new Timestamp(
                element['willBeAvailableAt']['_seconds'],
                element['willBeAvailableAt']['_nanoseconds'])
            .toDate();
      }
      if (data['updateOn'] != null) {
        data['updateOn'] = new Timestamp(element['updateOn']['_seconds'],
                element['updateOn']['_nanoseconds'])
            .toDate();
      }
      //data['id'] = element.id;
      //if(found.kDebugMode) print(element.id);
      boxAllRest[data['id']] = data;
      // await localStorage.setItem('boxAllRest',JSON.stringify(boxAllRest));

      ////console.log(boxAllRest);
      // if (Hive.isBoxOpen('boxAllRest')) {
      //   await boxAllRest.put(data['id'], data);
      // } else {
      //   boxAllRest = await Hive.openLazyBox('boxAllRest');
      //   await boxAllRest.put(data['id'], data);
      //   //boxAllRest.close();
      //   //if(found.kDebugMode) print('len:' + boxAllRest.length.toString());
      // }
      tot1++;
      if (restData.length === tot1) {
        totalValues['restUpdatedOn'] = Timestamp.now().toDate();
        try {
           await ldb.set('boxAllRest', JSON.stringify(boxAllRest), function(){
            window.dispatchEvent(new Event("boxAllRest"));
          });
          await ldb.set('totalValues', JSON.stringify(totalValues), function(){
            window.dispatchEvent(new Event("totalValues"));
          });
         } catch(e) {
           //console.log(e);
           await localStorage.setItem('totalValues', JSON.stringify(totalValues));
           await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
           window.dispatchEvent(new Event("totalValues"));
          window.dispatchEvent(new Event("boxAllRest"));
         }
        // totalValues.put('restUpdatedOn', DateTime.now()).then((value4) async {
          await getAllData().then((value)=> {
            // Fluttertoast.showToast(msg: 'Done ${restData.length}');
            ////console.log('Done');
            ////console.log(restData.length);
          });
        // });
        //if(found.kDebugMode) print('loaded: ' + boxAllRest.length.toString());
        // getUserData();
        // getDisplayData();
      }
    }
    // }).then((value) {
      //getAllData().then((value3) {
      await loadStoreData().then(async(value1) => {
        await getUserData().then(async(value2) => {
          await getDisplayData().then((value3) => {
            // if (Hive.isBoxOpen('connectedToServer')) {
            //   connectedToServer = Hive.box('connectedToServer');
            //   await connectedToServer.put('connectedToServer', true);
            // } else {
            //   connectedToServer = await Hive.openBox('connectedToServer');
            //   await connectedToServer.put('connectedToServer', true);
            // }
            // });
          });
          // });
        });
      });
    // });
  }

  // res.get().then((value) async {
  //   var tot1 = 0;
  //   //if(found.kDebugMode) print('dds:' + value.size.toString());
  //   Future.forEach(value.docs, (element) async {
  //     // })
  //     // value.docs.forEach((element) async {
  //     // allDataList = value.docs;
  //     //var element = value.docs[tot1];
  //     //value.docs.forEach((element) async {
  //     var data = {};
  //     data = element.data();
  //     data.remove('point');
  //     //data['updatedOn'] = data['updatedOn'].toDate();
  //     if (data['updatedOn'] != null) {
  //       data['updatedOn'] = data['updatedOn'].toDate();
  //     }
  //     if (data['willBeAvailableAt'] != null) {
  //       data['willBeAvailableAt'] = data['willBeAvailableAt'].toDate();
  //     }
  //     if (data['updateOn'] != null) {
  //       data['updateOn'] = data['updateOn'].toDate();
  //     }
  //     data['id'] = element.id;
  //     //if(found.kDebugMode) print(element.id);
  //     if (Hive.isBoxOpen('boxAllRest')) {
  //       await boxAllRest.put(element.id, data);
  //     } else {
  //       boxAllRest = await Hive.openLazyBox('boxAllRest');
  //       await boxAllRest.put(element.id, data);
  //       //boxAllRest.close();
  //       //if(found.kDebugMode) print('len:' + boxAllRest.length.toString());
  //     }
  //     tot1++;
  //     if (value.size == tot1) {
  //       totalValues.put('restUpdatedOn', DateTime.now()).then((value4) async {
  //         getAllData().then((value) {
  //           if(found.kDebugMode) print('Done');
  //         });
  //       });
  //       //if(found.kDebugMode) print('loaded: ' + boxAllRest.length.toString());
  //       // getUserData();
  //       // getDisplayData();
  //     }
  //   }).then((value) {
  //     // getAllData().then((value3) {
  //     loadStoreData().then((value1) {
  //       getUserData().then((value2) async {
  //         getDisplayData().then((value3) async {
  //           if (Hive.isBoxOpen('connectedToServer')) {
  //             connectedToServer = Hive.box('connectedToServer');
  //             await connectedToServer.put('connectedToServer', true);
  //           } else {
  //             connectedToServer = await Hive.openBox('connectedToServer');
  //             await connectedToServer.put('connectedToServer', true);
  //           }
  //           // });
  //         });
  //         // });
  //       });
  //     });
  //   });
  // });
  //});
  resolve('resolved');
});
}
var selectedDate;
var selectedWeekDay;
var deletedBusinesses;
var restUpdate;
var storeUpdate;
var userUpdate;
var locationUpdate;
const mainLoad = async() => {
  ////console.log('mainLoad');
  var date = Timestamp.now().toDate();
  var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[date.getDay()];
  // var currentDay = moment(date).weekday() - 1;
  ////console.log('currentDay',currentDay);
  var preOrder = JSON.parse(localStorage.getItem('preOrder') ?? JSON.stringify({
    selectedDate: selectedDate,
    selectedWeekDay: selectedWeekDay
  }));
  
    if(moment(new Date(preOrder['selectedDate'])).isAfter(moment())) {
      selectedDate = new Date(preOrder['selectedDate']);
    selectedWeekDay = preOrder['selectedWeekDay'];
    } else {
      ////console.log("DEEEDE",)
      await localStorage.removeItem('preOrder');
    }
  // await localStorage.removeItem('preOrder');
  window.dispatchEvent(new Event("preOrder"));
  ////console.log('hereee');
  selectedDate = date;
  selectedWeekDay = currentDay;

  window.addEventListener('preOrder', async() => {
    ////console.log("Change to local storage! -> selectedDate");
    var preOrder = JSON.parse(localStorage.getItem('preOrder') ?? {
      selectedDate: selectedDate,
      selectedWeekDay: selectedWeekDay
    });
    selectedDate = new Date(preOrder['selectedDate']);
    ////console.log('PD2',selectedDate.toString());
      selectedWeekDay = preOrder['selectedWeekDay'];
      await getBusiness();
})



var totalValues;
  try {
    totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
  } catch(e) {
    //console.log(e);
    totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
  }
    if(totalValues){} else {
      totalValues = {};
    } 
    if (totalValues['restUpdatedOn'] != null && moment(totalValues['restUpdatedOn']).isBefore(moment().subtract(5,'minutes'))) {
       totalValues = {};
try {
  await ldb.delete('displayRestClosed');
  await ldb.delete('displayRestOpen');
  await ldb.delete('displayStoreClosed');
  await ldb.delete('displayStoreOpen');
  await ldb.delete('boxAllRest');
  await ldb.delete('totalValues');
  await ldb.delete('boxAllStore');
} catch(e) {
  //console.log(e);
await localStorage.removeItem('displayRestClosed');
await localStorage.removeItem('displayRestOpen');
await localStorage.removeItem('displayStoreClosed');
await localStorage.removeItem('displayStoreOpen');
await localStorage.removeItem('boxAllRest');
await localStorage.removeItem('totalValues');
await localStorage.removeItem('boxAllStore');
}
    }
  // try {
  //   totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
  // } catch(e) {
  //   //console.log(e);
  //   totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
  // }
  //   if(totalValues){} else {
  //     totalValues = {};
  //   } 
    if (totalValues['restUpdatedOn'] != null) { 
      await pingAgainToo().then(async() => {
        // if (Hive.isBoxOpen('connectedToServer')) {
        //   connectedToServer = Hive.box('connectedToServer');
        //   await connectedToServer.put('connectedToServer', true);
        // } else {
        //   connectedToServer = await Hive.openBox('connectedToServer');
        //   await connectedToServer.put('connectedToServer', true);
        // }
      });
    } else {
await pingAgain().then(async() => {
    // if (Hive.isBoxOpen('connectedToServer')) {
    //   connectedToServer = Hive.box('connectedToServer');
    //   await connectedToServer.put('connectedToServer', true);
    // } else {
    //   connectedToServer = await Hive.openBox('connectedToServer');
    //   await connectedToServer.put('connectedToServer', true);
    // }
  });
}
  var totalValues;
  var boxAllRest;
  var boxAllStore;
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      //console.log(await getAsyncItem('totalValues'));
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      boxAllRest = JSON.parse((await getAsyncItem('boxAllRest')) ??"{}");
      boxAllStore = JSON.parse((await getAsyncItem('boxAllStore')) ??"{}");
      displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
      displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
      displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
      displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
       boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
       boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }
  //////console.log(displayRestOpen,);
};
var selectedAddress = {latitude: 0, longitude: 0};
const getBusiness = async() => {
  return new Promise(async (resolve) => {
    //console.log('getBusiness', selectedWeekDay);
  var boxAllRest;
  var boxAllStore;
    try {
      boxAllRest = JSON.parse((await getAsyncItem('boxAllRest')) ??"{}");
      boxAllStore = JSON.parse((await getAsyncItem('boxAllStore')) ??"{}");
    } catch(e) {
      //console.log(e);
       boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
       boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
    }
        try {
          await ldb.delete('displayRestClosed');
          await ldb.delete('displayRestOpen');
          await ldb.delete('displayStoreClosed');
          await ldb.delete('displayStoreOpen');
        } catch(e) {
          //console.log(e);
          await localStorage.removeItem('displayRestClosed');
          await localStorage.removeItem('displayRestOpen');
          await localStorage.removeItem('displayStoreClosed');
          await localStorage.removeItem('displayStoreOpen');
        }
  // window.dispatchEvent(new Event("displayRestClosed"));
  // window.dispatchEvent(new Event("displayRestOpen"));
  // window.dispatchEvent(new Event("displayStoreClosed"));
  // window.dispatchEvent(new Event("displayStoreOpen"));
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      displayRestOpen = JSON.parse("{}");
      displayRestClosed = JSON.parse("{}");
      displayStoreOpen = JSON.parse("{}");
      displayStoreClosed = JSON.parse("{}");
    } catch(e) {
      //console.log(e);
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }

  let boxAllRestKeys =Object.keys(boxAllRest);

  for(var iqw=0;iqw<boxAllRestKeys.length;iqw++) {
    ////console.log(iqw,boxAllRestKeys[iqw]);
    var key = boxAllRestKeys[iqw];
    var value = boxAllRest[key];
    // boxAllRest.get(key).then((value) async {
      if (value['enabled']) {
        //for (var j = 0; j < value['zones'].length; j++) {
        var polygon = [];
        var disablePolygon = [];
        // if (value['deliveryArea'] != null) {
        //   for (var i = 0; i < value['deliveryArea'].length; i++) {
        //     if (value['deliveryArea'][i]['latitude'] != null &&
        //         value['deliveryArea'][i]['longitude'] != null) {
        //       polygon.add(mp.LatLng(value['deliveryArea'][i]['latitude'],
        //           value['deliveryArea'][i]['longitude']));
        //     }
        //   }
        var canDeliver = false;
        var dynamicFeeType = -1;
        var deliveryFee = 0;

        if (value['deliveryZones'] != null) {
          for (var j = 0; j < value['deliveryZones'].length; j++) {
            polygon = [];
            var canDeliverTemp = false;
            if(value['deliveryZones'][j]['enabled']==null || value['deliveryZones'][j]['enabled']) {
            if(value['deliveryZones'][j]['isRadius']!=null && value['deliveryZones'][j]['isRadius']) {
              var canDeliverTemp = geolib.isPointWithinRadius(
                selectedAddress,
                { latitude: value['deliveryZones'][j]['center']['latitude'], longitude: value['deliveryZones'][j]['center']['longitude'] },
                value['deliveryZones'][j]['radius']
            )
            } else {
            for (var i = 0; i < value['deliveryZones'][j]['area'].length; i++) {
              if (
                value['deliveryZones'][j]['area'][i]['latitude'] != null &&
                value['deliveryZones'][j]['area'][i]['longitude'] != null
              ) {
                polygon.push({
                  latitude: value['deliveryZones'][j]['area'][i]['latitude'],
                  longitude: value['deliveryZones'][j]['area'][i]['longitude'],
                });

                //print('added data');
              }
            }
            var canDeliverTemp = geolib.isPointInPolygon(
              selectedAddress,
              polygon
            );
            }
          }
            // if(value['deliveryZones'][j]['isRadius']!=null && value['deliveryZones'][j]['isRadius']) {
            //    canDeliverTemp = geolib.isPointWithinRadius(
            //     selectedAddress,
            //     { latitude: value['deliveryZones'][j]['center']['latitude'], longitude: value['deliveryZones'][j]['center']['longitude'] },
            //     value['deliveryZones'][j]['radius']
            // )
            // } else {
            // for (var i = 0; i < value['deliveryZones'][j]['area'].length; i++) {
            //   if (
            //     value['deliveryZones'][j]['area'][i]['latitude'] != null &&
            //     value['deliveryZones'][j]['area'][i]['longitude'] != null
            //   ) {
            //     polygon.push({
            //       latitude: value['deliveryZones'][j]['area'][i]['latitude'],
            //       longitude: value['deliveryZones'][j]['area'][i]['longitude'],
            //     });

            //     //print('added data');
            //   }
            // }
            // ////console.log(selectedAddress,polygon);
            //  canDeliverTemp = geolib.isPointInPolygon(
            //   selectedAddress,
            //   polygon
            // );
            // }
                // mp.PolygonUtil.containsLocation(selectedAddress, polygon, true);
            if (canDeliverTemp && dynamicFeeType < 0 && deliveryFee <= 0) {
              canDeliver = true;
              // canDeliver = mp.PolygonUtil.containsLocation(
              //     selectedAddress, polygon, true);
              dynamicFeeType =
                  (value['deliveryZones'][j]['dynamicFeeType'] != null)
                      ? value['deliveryZones'][j]['dynamicFeeType']
                      : 1;
              deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
            } else if (canDeliverTemp) {
              canDeliver = true;
              if (deliveryFee >= value['deliveryZones'][j]['deliveryFee']) {
                dynamicFeeType =
                    (value['deliveryZones'][j]['dynamicFeeType'] != null)
                        ? value['deliveryZones'][j]['dynamicFeeType']
                        : 1;
                deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
              }
            }

            //if(found.kDebugMode) print('can deliver ID: ' + value['id']);

            // if (disablePolygon.length > 0 && !disableDelivery) {
            //   disableDelivery = mp.PolygonUtil.containsLocation(
            //       selectedAddress, disablePolygon, true);
            //   if (disableDelivery) {
            //     disableTime = value['disableArea2'][j]['disableTime'];
            //   }
            // }
          }

          if (canDeliver) {
            var disableTime = {
              'start': {
                'hour': 0,
                'minute': 0,
              },
              'end': {
                'hour': 0,
                'minute': 0,
              },
            };
            var disableDelivery = false;
            if (value['disableArea2'] != null) {
              for (var j = 0; j < value['disableArea2'].length; j++) {
                disablePolygon = [];
                for (var i = 0;
                    i < value['disableArea2'][j]['area'].length;
                    i++) {
                  if (value['disableArea2'][j]['area'][i]['latitude'] != null &&
                      value['disableArea2'][j]['area'][i]['longitude'] !=
                          null) {
                            disablePolygon.push({
                              latitude: value['disableArea2'][j]['area'][i]['latitude'],
                              longitude: value['disableArea2'][j]['area'][i]['longitude'],
                            });
                    //if(found.kDebugMode) print('added data');
                  }
                }

                //if(found.kDebugMode) print('can deliver ID: ' + value['id']);

                if (disablePolygon.length > 0 && !disableDelivery) {
                  disableDelivery = geolib.isPointInPolygon(
                    selectedAddress,
                    disablePolygon,
                    true
                  );
                  // disableDelivery = mp.PolygonUtil.containsLocation(
                  //     selectedAddress, disablePolygon, true);
                  if (disableDelivery) {
                    disableTime = value['disableArea2'][j]['disableTime'];
                  }
                }
              }
            }
            var isOpen = null;

            if (value['available']) {
              // var timezone = tz.getLocation(value['timezone']);
              // DateTime date = tz.TZDateTime.now(timezone);
              // var currentDay = date.weekday - 1;
              //America/Mexico_City
              ////console.log('getBusiness2', value['schedule'][selectedWeekDay]);
              if (value['schedule'][selectedWeekDay]['enabled']) {
                for (var i = 0;
                    i < value['schedule'][selectedWeekDay]['lapses'].length;
                    i++) {
                  //date = tz.TZDateTime.now(timezone);
                  //if(found.kDebugMode) print(date.hour);
                  if (value['schedule'][selectedWeekDay]['lapses'][i]['open']
                              ['hour'] <
                          selectedDate.getHours() &&
                      value['schedule'][selectedWeekDay]['lapses'][i]['close']
                              ['hour'] >
                          selectedDate.getHours()) {
                    isOpen = true;
                  } else if (value['schedule'][selectedWeekDay]['lapses'][i]
                          ['open']['hour'] ===
                      selectedDate.getHours()) {
                    if (value['schedule'][selectedWeekDay]['lapses'][i]['open']
                            ['minute'] <=
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  } else if (value['schedule'][selectedWeekDay]['lapses'][i]
                          ['close']['hour'] ===
                      selectedDate.getHours()) {
                    if (value['schedule'][selectedWeekDay]['lapses'][i]['close']
                            ['minute'] >
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  }
                }
              }
              if (isOpen == null) isOpen = false;
            } else
              isOpen = false;
            
              ////console.log('getBusiness3', value['schedule'][selectedWeekDay], isOpen);
            var isFav = false;
            if (isOpen && disableDelivery) {
              // var timezone = tz.getLocation(value['timezone']);
              // DateTime date = tz.TZDateTime.now(timezone);
              var date12 = selectedDate.getHours();
              var disableendhour = disableTime['end']['hour'];
              if (disableTime['start']['hour'] > disableendhour) {
                if (disableTime['start']['hour'] > date12) date12 = date12 + 24;
                disableendhour = disableendhour + 24;
              }
              if (disableTime['start']['hour'] < date12 &&
                  disableendhour > date12) {
                isOpen = false;
              } else if (disableTime['start']['hour'] === date12) {
                if (disableTime['start']['minute'] <= selectedDate.getMinutes()) {
                  isOpen = false;
                }
              } else if (disableendhour === date12) {
                if (disableTime['end']['minute'] > selectedDate.getMinutes()) {
                  isOpen = false;
                }
              }
            }
            //if(found.kDebugMode) print('UserData');
            //if(found.kDebugMode) print(userData);
            if (userData != null && userData['favorites']) {
              if (userData['favorites'].includes(key)) {
                isFav = true;
              }
            }
            var cashOnly = false;
            if (value['cashOnly'] != null) {
              cashOnly = value['cashOnly'];
            }
            var cardOnly = false;
            if (value['cardOnly'] != null) {
              cardOnly = value['cardOnly'];
            }
            var spoon = false;
            var verified = false;
            var createdAt = 0;
            var offerEndTime = 0;
            var showOffer = false;
            var offerDesc = '';
            var partner = true;
            if (value['partner'] != null) {
              partner = value['partner'];
            }
            var cardOnDelivery = false;
            if (value['cardOnDelivery'] != null) {
              cardOnDelivery = value['cardOnDelivery'];
            }
            var minimumPurchase = 0;
            if (value['minimumPurchase'] != null) {
              minimumPurchase = value['minimumPurchase'] + 0.0;
            }
            var increasePercentage = 0;
            if (value['increasePercentage'] != null) {
              increasePercentage = value['increasePercentage'] + 0.0;
            }
            var onlyInHouseDriver = false;
            if (value['onlyInHouseDriver'] != null) {
              onlyInHouseDriver = value['onlyInHouseDriver'];
            }

            var enableIncrease = false;
            if (value['enableIncrease'] != null) {
              enableIncrease = value['enableIncrease'];
            }
            var driverServiceFee = 0;
            if (value['driverServiceFee'] != null) {
              driverServiceFee = value['driverServiceFee'] + 0.0;
            }
            var driverServicePercentage = 0;
            if (value['driverServicePercentage'] != null) {
              driverServicePercentage = value['driverServicePercentage'] + 0.0;
            }
            var isCityDisable = false;
            if (value['isCityDisable'] != null) {
              isCityDisable = value['isCityDisable'];
            }
            var canPickup = false;
            if (value['canPickup'] != null) {
              canPickup = value['canPickup'];
            }
            if (value['verified'] != null) {
              verified = value['verified'];
            }
            if (value['spoon'] != null) {
              spoon = value['spoon'];
            }
            if (value['createdAt'] != null) {
              createdAt = value['createdAt'];
            }
            if (value['offerEndTime'] != null) {
              offerEndTime = value['offerEndTime'];
            }
            if (value['showOffer'] != null) {
              showOffer = value['showOffer'];
            }
            if (value['offerDesc'] != null) {
              offerDesc = value['offerDesc'];
            }
            if (isOpen) {
              var busDataToAdd = {
                name: value['name'],
                id: key,
                logo: value['logo'],
                header: value['header'],
                description: value['description'],
                about: value['about'],
                phone: value['phone'],
                dynamicPriceType: dynamicFeeType,
                deliveryPrice: deliveryFee,
                devyPercentageFee: value['fixedUsageFee'],
                percentageUsageFee: value['percentageUsageFee'],
                serviceFee: value['serviceFee'],
                partnerPercentageFee: value['tax'],
                averageRating: value['averageRating'],
                totalReviews: value['totalReviews'],
                costForOne: value['costForOne'],
                distance: geolib.getDistance(selectedAddress,{
                  latitude: value['location']['latitude'],
                  longitude: value['location']['longitude'],
                } ),
                location: value['location'],
                isFavorite: isFav,
                schedule: value['schedule'],
                timezone: value['timezone'],
                available: value['available'],
                featured: value['featured'],
                cashOnly: cashOnly,
                cardOnly: cardOnly,
                spoon: spoon,
                verified: verified,
                onlyInHouseDriver: onlyInHouseDriver,
                enableIncrease:enableIncrease,
                minimumPurchase: minimumPurchase,
                increasePercentage:increasePercentage,
                cardOnDelivery: cardOnDelivery,
                driverServiceFee: driverServiceFee,
                driverServicePercentage: driverServicePercentage,
                isCityDisable: isCityDisable,
                canPickup: canPickup,
                partner: partner,
                createdTime: createdAt,
                offerText: offerDesc,
                offerTime: offerEndTime,
                hasOffer: showOffer,
                inDisableZone: disableDelivery,
                startMinute: (disableTime != null)
                    ? disableTime['start']['minute']
                    : 0,
                startHour: (disableTime != null)
                    ? disableTime['start']['hour']
                    : 0,
                endMinute: (disableTime != null)
                    ? disableTime['end']['minute']
                    : 0,
                endHour:
                    (disableTime != null) ? disableTime['end']['hour'] : 0,
                totalOrders: (value['totalOrders'] == null)
                    ? 0
                    : value['totalOrders'],
                totalTime: (value['totalTime'] == null ||
                        value['totalOrders'] == null ||
                        value['totalOrders'] === 0 ||
                        ((value['totalTime'] / value['totalOrders']) /
                                60) <=
                            7)
                    ? 35
                    : (value['totalTime'] >= 46)
                        ? 46
                        : (value['totalTime'] / value['totalOrders']) / 60,
                menu: [],
                };
                displayRestOpen[key] = busDataToAdd;
                delete displayRestClosed[key];
                // await localStorage.setItem('displayRestOpen',JSON.stringify(displayRestOpen));
                
            } else {
              var busDataToAdd = {
                name: value['name'],
                    id: key,
                    logo: value['logo'],
                    header: value['header'],
                    description: value['description'],
                    about: value['about'],
                    phone: value['phone'],
                    dynamicPriceType: dynamicFeeType,
                    deliveryPrice: deliveryFee,
                    devyPercentageFee: value['fixedUsageFee'],
                    percentageUsageFee: value['percentageUsageFee'],
                    serviceFee: value['serviceFee'],
                    partnerPercentageFee: value['tax'],
                    averageRating: value['averageRating'],
                    totalReviews: value['totalReviews'],
                    costForOne: value['costForOne'],
                    distance: geolib.getDistance(selectedAddress,{
                      latitude: value['location']['latitude'],
                      longitude: value['location']['longitude'],
                    } ),
                    location: value['location'],
                    isFavorite: isFav,
                    schedule: value['schedule'],
                    timezone: value['timezone'],
                    available: value['available'],
                    featured: value['featured'],
                    cashOnly: cashOnly,
                    cardOnly: cardOnly,
                    spoon: spoon,
                    verified: verified,
                    onlyInHouseDriver: onlyInHouseDriver,
                    enableIncrease:enableIncrease,
                    minimumPurchase: minimumPurchase,
                    increasePercentage:increasePercentage,
                    cardOnDelivery: cardOnDelivery,
                    driverServicePercentage: driverServicePercentage,
                    driverServiceFee: driverServiceFee,
                    isCityDisable: isCityDisable,
                    canPickup: canPickup,
                    partner: partner,
                    createdTime: createdAt,
                    offerText: offerDesc,
                    offerTime: offerEndTime,
                    hasOffer: showOffer,
                    inDisableZone: disableDelivery,
                    startMinute: (disableTime != null)
                        ? disableTime['start']['minute']
                        : 0,
                    startHour: (disableTime != null)
                        ? disableTime['start']['hour']
                        : 0,
                    endMinute: (disableTime != null)
                        ? disableTime['end']['minute']
                        : 0,
                    endHour:
                        (disableTime != null) ? disableTime['end']['hour'] : 0,
                    totalOrders: (value['totalOrders'] == null)
                        ? 0
                        : value['totalOrders'],
                    totalTime: (value['totalTime'] == null ||
                            value['totalOrders'] == null ||
                            value['totalOrders'] === 0 ||
                            ((value['totalTime'] / value['totalOrders']) /
                                    60) <=
                                7)
                        ? 35
                        : (value['totalTime'] >= 46)
                            ? 46
                            : (value['totalTime'] / value['totalOrders']) / 60,
                    menu: [],
              };
              displayRestClosed[key] = busDataToAdd;
              delete displayRestOpen[key];
                // await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
                
            }
            //break;
          }
        }
        //}
      }
    // });
  }
  try {
    await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
      //console.log('displayRestOpen',displayRestOpen);
     window.dispatchEvent(new Event("displayRestOpen"));
   });
    await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
      //console.log('displayRestClosed',displayRestClosed);
     window.dispatchEvent(new Event("displayRestClosed"));
   });
  } catch(e) {
    //console.log(e);
    await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
    await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
   window.dispatchEvent(new Event("displayRestOpen"));
   window.dispatchEvent(new Event("displayRestClosed"));
  }
  var b1 = 0;
  var b2 = 0;

  let boxAllStoreKeys =Object.keys(boxAllStore);

  for(var iqw=0;iqw<boxAllStoreKeys.length;iqw++) {
  // boxAllStore.keys.toList().forEach((key) {
    var key = boxAllStoreKeys[iqw];
    var value = boxAllStore[key];
    // boxAllStore.get(key).then((value) async {
      // if(found.kDebugMode) print('checking ID: ' +
      //     value['id'] +
      //     ' ' +
      //     value['name'] +
      //     ' ' +
      //     b1.toString() +
      //     ' ' +
      //     b2.toString());
      //b2++;
      if (value['enabled']) {
        //if(found.kDebugMode) print('enabled ID: ' + value['id']);
        //for (var j = 0; j < value['zones'].length; j++) {
        var polygon = [];
        var disablePolygon = [];
// if (value['deliveryArea'] != null) {
        //   for (var i = 0; i < value['deliveryArea'].length; i++) {
        //     if (value['deliveryArea'][i]['latitude'] != null &&
        //         value['deliveryArea'][i]['longitude'] != null) {
        //       polygon.add(mp.LatLng(value['deliveryArea'][i]['latitude'],
        //           value['deliveryArea'][i]['longitude']));
        //     }
        //   }
        var canDeliver = false;
        var dynamicFeeType = -1;
        var deliveryFee = 0;

        if (value['deliveryZones'] != null) {
          for (var j = 0; j < value['deliveryZones'].length; j++) {
            polygon = [];
            var canDeliverTemp = false;
            if(value['deliveryZones'][j]['enabled']==null || value['deliveryZones'][j]['enabled']) {
            if(value['deliveryZones'][j]['isRadius']!=null && value['deliveryZones'][j]['isRadius']) {
              var canDeliverTemp = geolib.isPointWithinRadius(
                selectedAddress,
                { latitude: value['deliveryZones'][j]['center']['latitude'], longitude: value['deliveryZones'][j]['center']['longitude'] },
                value['deliveryZones'][j]['radius']
            )
            } else {
            for (var i = 0; i < value['deliveryZones'][j]['area'].length; i++) {
              if (
                value['deliveryZones'][j]['area'][i]['latitude'] != null &&
                value['deliveryZones'][j]['area'][i]['longitude'] != null
              ) {
                polygon.push({
                  latitude: value['deliveryZones'][j]['area'][i]['latitude'],
                  longitude: value['deliveryZones'][j]['area'][i]['longitude'],
                });

                //print('added data');
              }
            }
            var canDeliverTemp = geolib.isPointInPolygon(
              selectedAddress,
              polygon
            );
            }
          }
            if (canDeliverTemp && dynamicFeeType < 0 && deliveryFee <= 0) {
              canDeliver = true;
              // canDeliver = mp.PolygonUtil.containsLocation(
              //     selectedAddress, polygon, true);
              dynamicFeeType =
                  (value['deliveryZones'][j]['dynamicFeeType'] != null)
                      ? value['deliveryZones'][j]['dynamicFeeType']
                      : 1;
              deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
            } else if (canDeliverTemp) {
              canDeliver = true;
              if (deliveryFee >= value['deliveryZones'][j]['deliveryFee']) {
                dynamicFeeType =
                    (value['deliveryZones'][j]['dynamicFeeType'] != null)
                        ? value['deliveryZones'][j]['dynamicFeeType']
                        : 1;
                deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
              }
            }

            //if(found.kDebugMode) print('can deliver ID: ' + value['id']);

            // if (disablePolygon.length > 0 && !disableDelivery) {
            //   disableDelivery = mp.PolygonUtil.containsLocation(
            //       selectedAddress, disablePolygon, true);
            //   if (disableDelivery) {
            //     disableTime = value['disableArea2'][j]['disableTime'];
            //   }
            // }
          }
          if (canDeliver) {
            var disableDelivery = false;
            var disableTime = {
              'start': {
                'hour': 0,
                'minute': 0,
              },
              'end': {
                'hour': 0,
                'minute': 0,
              },
            };
            if (value['disableArea2'] != null) {
              //if(found.kDebugMode) print(value['disableArea2']);
              for (var j = 0; j < value['disableArea2'].length; j++) {
                disablePolygon = [];
                for (var i = 0;
                    i < value['disableArea2'][j]['area'].length;
                    i++) {
                  if (value['disableArea2'][j]['area'][i]['latitude'] != null &&
                      value['disableArea2'][j]['area'][i]['longitude'] !=
                          null) {
                            disablePolygon.push({
                              latitude: value['disableArea2'][j]['area'][i]['latitude'],
                              longitude: value['disableArea2'][j]['area'][i]['longitude'],
                            });
                    //if(found.kDebugMode) print('added data');
                  }
                }

                //if(found.kDebugMode) print('can deliver ID: ' + value['id']);

                if (disablePolygon.length > 0 && !disableDelivery) {
                  disableDelivery = geolib.isPointInPolygon(
                    selectedAddress,
                    disablePolygon,
                    true
                  );
                  if (disableDelivery) {
                    disableTime = value['disableArea2'][j]['disableTime'];
                  }
                }
              }
            }
            b1++;
            var isOpen = null;
            if (value['available']) {
              //if(found.kDebugMode) print('available ID: ' + value['id']);
              // var timezone = tz.getLocation(value['timezone']);
              // DateTime date = tz.TZDateTime.now(timezone);
              // var currentDay = date.weekday - 1;
              if (value['schedule'][selectedWeekDay]['enabled']) {
                for (var i = 0;
                    i < value['schedule'][selectedWeekDay]['lapses'].length;
                    i++) {
                  //date = tz.TZDateTime.now(timezone);
                  //if(found.kDebugMode) print(date.hour);
                  if (value['schedule'][selectedWeekDay]['lapses'][i]['open']
                              ['hour'] <
                          selectedDate.getHours() &&
                      value['schedule'][selectedWeekDay]['lapses'][i]['close']
                              ['hour'] >
                          selectedDate.getHours()) {
                    isOpen = true;
                  } else if (value['schedule'][selectedWeekDay]['lapses'][i]
                          ['open']['hour'] ===
                      selectedDate.getHours()) {
                    if (value['schedule'][selectedWeekDay]['lapses'][i]['open']
                            ['minute'] <=
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  } else if (value['schedule'][selectedWeekDay]['lapses'][i]
                          ['close']['hour'] ===
                      selectedDate.getHours()) {
                    if (value['schedule'][selectedWeekDay]['lapses'][i]['close']
                            ['minute'] >
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  }
                }
              }
              if (isOpen == null) isOpen = false;
            } else
              isOpen = false;
            var isFav = false;
            if (isOpen && disableDelivery) {
              // var timezone = tz.getLocation(value['timezone']);
              // DateTime date = tz.TZDateTime.now(timezone);
              var date12 = selectedDate.getHours();
              var disableendhour = disableTime['end']['hour'];
              if (disableTime['start']['hour'] > disableendhour) {
                if (disableTime['start']['hour'] > date12) date12 = date12 + 24;
                disableendhour = disableendhour + 24;
              }
              if (disableTime['start']['hour'] < date12 &&
                  disableendhour > date12) {
                isOpen = false;
              } else if (disableTime['start']['hour'] === date12) {
                if (disableTime['start']['minute'] <= selectedDate.getMinutes()) {
                  isOpen = false;
                }
              } else if (disableendhour === date12) {
                if (disableTime['end']['minute'] > selectedDate.getMinutes()) {
                  isOpen = false;
                }
              }
            }
            if (userData != null && userData['favorites']) {
              if (userData['favorites'].includes(key)) {
                isFav = true;
              }
            }
            var cashOnly = false;
            if (value['cashOnly'] != null) {
              cashOnly = value['cashOnly'];
            }
            var cardOnly = false;
            if (value['cardOnly'] != null) {
              cardOnly = value['cardOnly'];
            }
            var spoon = false;
            var verified = false;
            var createdAt = 0;
            var offerEndTime = 0;
            var showOffer = false;
            var offerDesc = '';
            var partner = true;
            if (value['partner'] != null) {
              partner = value['partner'];
            }
            var cardOnDelivery = false;
            if (value['cardOnDelivery'] != null) {
              cardOnDelivery = value['cardOnDelivery'];
            }
            var minimumPurchase = 0;
            if (value['minimumPurchase'] != null) {
              minimumPurchase = value['minimumPurchase'] + 0.0;
            }
            var increasePercentage = 0;
            if (value['increasePercentage'] != null) {
              increasePercentage = value['increasePercentage'] + 0.0;
            }
            var onlyInHouseDriver = false;
            if (value['onlyInHouseDriver'] != null) {
              onlyInHouseDriver = value['onlyInHouseDriver'];
            }

            var enableIncrease = false;
            if (value['enableIncrease'] != null) {
              enableIncrease = value['enableIncrease'];
            }
            var driverServiceFee = 0;
            if (value['driverServiceFee'] != null) {
              driverServiceFee = value['driverServiceFee'] + 0.0;
            }
            var driverServicePercentage = 0;
            if (value['driverServicePercentage'] != null) {
              driverServicePercentage = value['driverServicePercentage'] + 0.0;
            }
            var isCityDisable = false;
            if (value['isCityDisable'] != null) {
              isCityDisable = value['isCityDisable'];
            }
            var canPickup = false;
            if (value['canPickup'] != null) {
              canPickup = value['canPickup'];
            }
            if (value['verified'] != null) {
              verified = value['verified'];
            }
            if (value['spoon'] != null) {
              spoon = value['spoon'];
            }
            if (value['createdAt'] != null) {
              createdAt = value['createdAt'];
            }
            if (value['offerEndTime'] != null) {
              offerEndTime = value['offerEndTime'];
            }
            if (value['showOffer'] != null) {
              showOffer = value['showOffer'];
            }
            if (value['offerDesc'] != null) {
              offerDesc = value['offerDesc'];
            }
            if (isOpen) {
              var busDataToAdd = {
                  name: value['name'],
                  id: key,
                  logo: value['logo'],
                  header: value['header'],
                  description: value['description'],
                  about: value['about'],
                  phone: value['phone'],
                  dynamicPriceType: dynamicFeeType,
                  deliveryPrice: deliveryFee,
                  devyPercentageFee: value['fixedUsageFee'],
                  percentageUsageFee: value['percentageUsageFee'],
                  serviceFee: value['serviceFee'],
                  partnerPercentageFee: value['tax'],
                  averageRating: value['averageRating'],
                  totalReviews: value['totalReviews'],
                  costForOne: value['costForOne'],
                  distance: geolib.getDistance(selectedAddress,{
                    latitude: value['location']['latitude'],
                    longitude: value['location']['longitude'],
                  } ),
                  location: value['location'],
                  isFavorite: isFav,
                  schedule: value['schedule'],
                  timezone: value['timezone'],
                  available: value['available'],
                  featured: value['featured'],
                  cashOnly: cashOnly,
                  cardOnly: cardOnly,
                  spoon: spoon,
                  verified: verified,
                  onlyInHouseDriver: onlyInHouseDriver,
                  enableIncrease:enableIncrease,
                  minimumPurchase: minimumPurchase,
                  increasePercentage: increasePercentage,
                  cardOnDelivery: cardOnDelivery,
                  driverServiceFee: driverServiceFee,
                  driverServicePercentage: driverServicePercentage,
                  isCityDisable: isCityDisable,
                  canPickup: canPickup,
                  partner: partner,
                  createdTime: createdAt,
                  offerText: offerDesc,
                  offerTime: offerEndTime,
                  hasOffer: showOffer,
                  inDisableZone: disableDelivery,
                  startMinute: (disableTime != null)
                      ? disableTime['start']['minute']
                      : 0,
                  startHour: (disableTime != null)
                      ? disableTime['start']['hour']
                      : 0,
                  endMinute: (disableTime != null)
                      ? disableTime['end']['minute']
                      : 0,
                  endHour:
                      (disableTime != null) ? disableTime['end']['hour'] : 0,
                  totalOrders: (value['totalOrders'] == null)
                      ? 0
                      : value['totalOrders'],
                  totalTime: (value['totalTime'] == null ||
                          value['totalOrders'] == null ||
                          value['totalOrders'] === 0 ||
                          ((value['totalTime'] / value['totalOrders']) /
                                  60) <=
                              7)
                      ? 35
                      : (value['totalTime'] >= 46)
                          ? 46
                          : (value['totalTime'] / value['totalOrders']) / 60,
                  menu: [],
              };
              displayStoreOpen[key] = busDataToAdd;
                // await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
                
              // await displayStoreOpen.put(
              //     key,
              //     Business);
            } else {
              var busDataToAdd = {
                name: value['name'],
                    id: key,
                    logo: value['logo'],
                    header: value['header'],
                    description: value['description'],
                    about: value['about'],
                    phone: value['phone'],
                    dynamicPriceType: dynamicFeeType,
                    deliveryPrice: deliveryFee,
                    devyPercentageFee: value['fixedUsageFee'],
                    percentageUsageFee: value['percentageUsageFee'],
                    serviceFee: value['serviceFee'],
                    partnerPercentageFee: value['tax'],
                    averageRating: value['averageRating'],
                    totalReviews: value['totalReviews'],
                    costForOne: value['costForOne'],
                    distance: geolib.getDistance(selectedAddress,{
                      latitude: value['location']['latitude'],
                      longitude: value['location']['longitude'],
                    } ),
                    location: value['location'],
                    isFavorite: isFav,
                    schedule: value['schedule'],
                    timezone: value['timezone'],
                    available: value['available'],
                    featured: value['featured'],
                    cashOnly: cashOnly,
                    cardOnly: cardOnly,
                    spoon: spoon,
                    verified: verified,
                    onlyInHouseDriver: onlyInHouseDriver,
                    enableIncrease:enableIncrease,
                    minimumPurchase: minimumPurchase,
                    increasePercentage:increasePercentage,
                    cardOnDelivery: cardOnDelivery,
                    driverServicePercentage: driverServicePercentage,
                    driverServiceFee: driverServiceFee,
                    isCityDisable: isCityDisable,
                    canPickup: canPickup,
                    createdTime: createdAt,
                    offerText: offerDesc,
                    offerTime: offerEndTime,
                    partner: partner,
                    hasOffer: showOffer,
                    inDisableZone: disableDelivery,
                    startMinute: (disableTime != null)
                        ? disableTime['start']['minute']
                        : 0,
                    startHour: (disableTime != null)
                        ? disableTime['start']['hour']
                        : 0,
                    endMinute: (disableTime != null)
                        ? disableTime['end']['minute']
                        : 0,
                    endHour:
                        (disableTime != null) ? disableTime['end']['hour'] : 0,
                    totalOrders: (value['totalOrders'] == null)
                        ? 0
                        : value['totalOrders'],
                    totalTime: (value['totalTime'] == null ||
                            value['totalOrders'] == null ||
                            value['totalOrders'] === 0 ||
                            ((value['totalTime'] / value['totalOrders']) /
                                    60) <=
                                7)
                        ? 35
                        : (value['totalTime'] >= 46)
                            ? 46
                            : (value['totalTime'] / value['totalOrders']) / 60,
                    menu: [],
              };
              displayStoreClosed[key] = busDataToAdd;
                // await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
                
              // await displayStoreClosed.put(
              //     key,
              //     Business(
                    
              //     ));
            }
            //break;
          }
        }
        //}
      }
  //   });
  // });
    }
    try {
      await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
       window.dispatchEvent(new Event("displayStoreOpen"));
     });
      await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
       window.dispatchEvent(new Event("displayStoreClosed"));
     });
    } catch(e) {
      //console.log(e);
      await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
      await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
     window.dispatchEvent(new Event("displayStoreClosed"));
     window.dispatchEvent(new Event("displayStoreOpen"));
    }
    resolve('resolved');
  });
}

const getAllData = async() => {
  return new Promise(async (resolve) => {
    ////console.log('getAllData');
  var totalValues;
  var boxAllRest;
  var boxAllStore;
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      boxAllRest = JSON.parse((await getAsyncItem('boxAllRest')) ??"{}");
      boxAllStore = JSON.parse((await getAsyncItem('boxAllStore')) ??"{}");
      displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
      displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
      displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
      displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
       boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
       boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }


 var deletedBusinesses2 = doc(db, 'deleted', '7085XOmZXliUEcbtg4LR');
 deletedBusinesses = await onSnapshot(deletedBusinesses2, async (element) => {
  // deletedBusinesses = FirebaseFirestore.instance
  //     .collection('deleted')
  //     .doc('7085XOmZXliUEcbtg4LR')
  //     .snapshots()
  //     .listen((element) {
    if (element.data() != null && element.data()['businesses'] != null) {
      var businessIds = element.data()['businesses'];
      for(var i=0;i<businessIds.length;i++) {
        delete boxAllRest[businessIds[i]];
        // await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
        // window.dispatchEvent(new Event("boxAllRest"));
        delete boxAllStore[businessIds[i]];
        // await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
        // window.dispatchEvent(new Event("boxAllStore"));
        delete displayRestOpen[businessIds[i]];
        // await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
        // window.dispatchEvent(new Event("displayRestOpen"));
        delete displayRestClosed[businessIds[i]];
        // await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
        // window.dispatchEvent(new Event("displayRestClosed"));
        delete displayStoreOpen[businessIds[i]];
        // await localStorage.setItem('displayStoreOpen', JSON.stringify(displayStoreOpen));
        // window.dispatchEvent(new Event("displayStoreOpen"));
        delete displayStoreClosed[businessIds[i]];
        // await localStorage.setItem('displayStoreClosed', JSON.stringify(displayStoreClosed));
        // window.dispatchEvent(new Event("displayStoreClosed"));
      }
      try {
        await ldb.set('boxAllRest', JSON.stringify(boxAllRest), function(){
         window.dispatchEvent(new Event("boxAllRest"));
       });
        await ldb.set('boxAllStore', JSON.stringify(boxAllStore), function(){
         window.dispatchEvent(new Event("boxAllStore"));
       });
        await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
         window.dispatchEvent(new Event("displayRestOpen"));
       });
        await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
         window.dispatchEvent(new Event("displayRestClosed"));
       });
        await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
         window.dispatchEvent(new Event("displayStoreOpen"));
       });
        await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
         window.dispatchEvent(new Event("displayStoreClosed"));
       });
      } catch(e) {
        //console.log(e);
        await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
        await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
        await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
        await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
        await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
        await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
        window.dispatchEvent(new Event("boxAllStore"));
       window.dispatchEvent(new Event("displayRestOpen"));
       window.dispatchEvent(new Event("displayRestClosed"));
       window.dispatchEvent(new Event("boxAllRest"));
       window.dispatchEvent(new Event("displayStoreClosed"));
       window.dispatchEvent(new Event("displayStoreOpen"));
      }
    }
  });
  if (totalValues['restUpdatedOn'] != null) {
    ////console.log(totalValues['restUpdatedOn'], 'errrrr');
    const q23 = query(
      collection(db, 'businesses'),
      where('food', '==', true),
      where('updatedOn', '>', Timestamp.fromDate(new Date(totalValues['restUpdatedOn']))),
    );
    // res = doc(db, 'deleted', '7085XOmZXliUEcbtg4LR');
    ////console.log(Timestamp.fromDate(new Date(totalValues['restUpdatedOn'])).toDate())
    restUpdate = onSnapshot(q23, async (value) => {

      ////console.log("herw", value.docs.length);

    // res = FirebaseFirestore.instance
    //     .collection('businesses')
    //     .where('food', isEqualTo: true)
    //     .where('updatedOn',
    //         isGreaterThan:
    //             Timestamp.fromDate(totalValues.get('restUpdatedOn')));
    // restUpdate = res.snapshots().listen((value) {
      var tot = 0;
      for(var i=0;i<value.docs.length;i++) {
      var element = value.docs[i];
      // value.docChanges.forEach((element) => {
        tot++;
        var data = {};
        data = element.data();
        delete data['point'];
        // data.remove('point');
        var cashOnly = false;
        var cardOnly = false;
        //data['updatedOn'] = data['updatedOn'].toDate();
        if (data['updatedOn'] != null) {
          data['updatedOn'] = data['updatedOn'].toDate();
        }
        if (data['willBeAvailableAt'] != null) {
          data['willBeAvailableAt'] = data['willBeAvailableAt'].toDate();
        }
        if (data['cashOnly'] != null) {
          cashOnly = data['cashOnly'];
        }
        if (data['cardOnly'] != null) {
          cardOnly = data['cardOnly'];
        }
        var spoon = false;
        var verified = false;
        var createdAt = 0;
        var offerEndTime = 0;
        var showOffer = false;
        var offerDesc = '';
        var partner = true;
        if (data['partner'] != null) {
          partner = data['partner'];
        }
        var cardOnDelivery = false;
        if (data['cardOnDelivery'] != null) {
          cardOnDelivery = data['cardOnDelivery'];
        }
        var minimumPurchase = 0;
        if (data['minimumPurchase'] != null) {
          minimumPurchase = data['minimumPurchase'] + 0.0;
        }
        var increasePercentage = 0;
        if (data['increasePercentage'] != null) {
          increasePercentage = data['increasePercentage'] + 0.0;
        }
        var onlyInHouseDriver = false;
        if (data['onlyInHouseDriver'] != null) {
          onlyInHouseDriver = data['onlyInHouseDriver'];
        }
        var enableIncrease = false;
        if (data['enableIncrease'] != null) {
          enableIncrease = data['enableIncrease'];
        }
        var driverServiceFee = 0;
        if (data['driverServiceFee'] != null) {
          driverServiceFee = data['driverServiceFee'] + 0.0;
        }
        var driverServicePercentage = 0;
        if (data['driverServicePercentage'] != null) {
          driverServicePercentage = data['driverServicePercentage'] + 0.0;
        }
        var isCityDisable = false;
        if (data['isCityDisable'] != null) {
          isCityDisable = data['isCityDisable'];
        }
        var canPickup = false;
        if (data['canPickup'] != null) {
          canPickup = data['canPickup'];
        }
        if (data['verified'] != null) {
          verified = data['verified'];
        }
        if (data['spoon'] != null) {
          spoon = data['spoon'];
        }
        if (data['createdAt'] != null) {
          createdAt = data['createdAt'];
        }
        if (data['offerEndTime'] != null) {
          offerEndTime = data['offerEndTime'];
        }
        if (data['showOffer'] != null) {
          showOffer = data['showOffer'];
        }
        if (data['offerDesc'] != null) {
          offerDesc = data['offerDesc'];
        }
        data['id'] = element.id;
        delete boxAllStore[element.id];
        delete displayStoreOpen[element.id];
        delete displayStoreClosed[element.id];
        boxAllRest[element.id] = data;
      //   await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
      //  // window.dispatchEvent(new Event("boxAllStore"));
      //   await localStorage.setItem('displayStoreOpen', JSON.stringify(displayStoreOpen));
      //  // window.dispatchEvent(new Event("displayStoreOpen"));
      //   await localStorage.setItem('displayStoreClosed', JSON.stringify(displayStoreClosed));
      //   //window.dispatchEvent(new Event("displayStoreClosed"));
      //   await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
      //  // window.dispatchEvent(new Event("boxAllRest"));
        ////console.log(boxAllRest);
        if (displayRestOpen[element.id]) {
          var dis = displayRestOpen[element.id];

          var tem1 = {
            name: data['name'],
            id: element.id,
            logo: data['logo'],
            header: data['header'],
            description: data['description'],
            about: data['about'],
            phone: data['phone'],
            dynamicPriceType: dis.dynamicPriceType,
            deliveryPrice: dis.deliveryPrice,
            devyPercentageFee: data['fixedUsageFee'],
            percentageUsageFee: data['percentageUsageFee'],
            serviceFee: data['serviceFee'],
            partnerPercentageFee: data['tax'],
            averageRating: data['averageRating'],
            totalReviews: data['totalReviews'],
            costForOne: data['costForOne'],
            distance: dis.distance,
            location: data['location'],
            isFavorite: dis.isFavorite,
            schedule: data['schedule'],
            timezone: data['timezone'],
            available: data['available'],
            featured: data['featured'],
            cashOnly: cashOnly,
            cardOnly: cardOnly,
            spoon: spoon,
            verified: verified,
            partner: partner,
            onlyInHouseDriver: onlyInHouseDriver,
            enableIncrease:enableIncrease,
            minimumPurchase: minimumPurchase,
            increasePercentage:increasePercentage,
            cardOnDelivery: cardOnDelivery,
            driverServiceFee: driverServiceFee,
            driverServicePercentage: driverServicePercentage,
            isCityDisable: isCityDisable,
            canPickup: canPickup,
            createdTime: createdAt,
            offerText: offerDesc,
            offerTime: offerEndTime,
            hasOffer: showOffer,
            menu: [],
            inDisableZone: dis.inDisableZone,
            startHour: dis.startHour,
            endHour: dis.endHour,
            startMinute: dis.startMinute,
            endMinute: dis.endMinute,
            totalOrders:
                (data['totalOrders'] == null) ? 0 : data['totalOrders'],
            totalTime: (data['totalTime'] == null ||
                    data['totalOrders'] == null ||
                    data['totalOrders'] === 0 ||
                    ((data['totalTime'] / data['totalOrders']) / 60) <= 7)
                ? 35
                : (data['totalTime'] >= 46)
                    ? 46
                    : (data['totalTime'] / data['totalOrders']) / 60,
        };
          if (element.data()['enabled']) {
            var isOpen = null;
            if (element.data()['available']) {
              // var timezone = tz.getLocation(element.data()['timezone']);
              // DateTime date = tz.TZDateTime.now(timezone);
              // var currentDay = date.weekday - 1;
              if (element.data()['schedule'][selectedWeekDay]['enabled']) {
                for (var i = 0;
                    i <
                        element
                            .data()['schedule'][selectedWeekDay]['lapses']
                            .length;
                    i++) {
                  //date = selectedDate;
                  //if(found.kDebugMode) print(date.hour);
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                              [i]['open']['hour'] <
                          selectedDate.getHours() &&
                      element.data()['schedule'][selectedWeekDay]['lapses']
                              [i]['close']['hour'] >
                          selectedDate.getHours()) {
                    isOpen = true;
                  } else if (element.data()['schedule'][selectedWeekDay]
                          ['lapses'][i]['open']['hour'] ===
                      selectedDate.getHours()) {
                    if (element.data()['schedule'][selectedWeekDay]
                            ['lapses'][i]['open']['minute'] <=
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  } else if (element.data()['schedule'][selectedWeekDay]
                          ['lapses'][i]['close']['hour'] ===
                      selectedDate.getHours()) {
                    if (element.data()['schedule'][selectedWeekDay]
                            ['lapses'][i]['close']['minute'] >
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  }
                }
              }
              if (isOpen == null) isOpen = false;
            } else
              isOpen = false;
            if (isOpen) {
              displayRestOpen[element.id] = tem1;
              // await localStorage.setItem('displayRestOpen',JSON.stringify(displayRestOpen));
              // // window.dispatchEvent(new Event("displayRestOpen"));
              dis = null;
            } else {
              delete displayRestOpen[element.id];
              displayRestClosed[element.id] = tem1;
            //   await localStorage.setItem('displayRestOpen',JSON.stringify(displayRestOpen));
            //  // window.dispatchEvent(new Event("displayRestOpen"));
            //   await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
            //  // window.dispatchEvent(new Event("displayRestClosed"));
              dis = null;
            }
          } else {
            delete displayRestOpen[element.id];
          //   await localStorage.setItem('displayRestOpen',JSON.stringify(displayRestOpen));
          //  // window.dispatchEvent(new Event("displayRestOpen"));
          }
        }
        if (displayRestClosed[element.id]) {
          var dis = displayRestClosed[element.id];
          var tem1 = {
            name: data['name'],
            id: element.id,
            logo: data['logo'],
            header: data['header'],
            description: data['description'],
            about: data['about'],
            phone: data['phone'],
            dynamicPriceType: dis.dynamicPriceType,
            deliveryPrice: dis.deliveryPrice,
            devyPercentageFee: data['fixedUsageFee'],
            percentageUsageFee: data['percentageUsageFee'],
            serviceFee: data['serviceFee'],
            partnerPercentageFee: data['tax'],
            averageRating: data['averageRating'],
            totalReviews: data['totalReviews'],
            costForOne: data['costForOne'],
            distance: dis.distance,
            location: data['location'],
            isFavorite: dis.isFavorite,
            schedule: data['schedule'],
            timezone: data['timezone'],
            available: data['available'],
            featured: data['featured'],
            cashOnly: cashOnly,
            cardOnly: cardOnly,
            verified: verified,
            onlyInHouseDriver: onlyInHouseDriver,
            enableIncrease:enableIncrease,
            minimumPurchase: minimumPurchase,
            increasePercentage:increasePercentage,
            cardOnDelivery: cardOnDelivery,
            driverServicePercentage: driverServicePercentage,
            driverServiceFee: driverServiceFee,
            isCityDisable: isCityDisable,
            canPickup: canPickup,
            createdTime: createdAt,
            offerText: offerDesc,
            partner: partner,
            offerTime: offerEndTime,
            hasOffer: showOffer,
            spoon: spoon,
            inDisableZone: dis.inDisableZone,
            startHour: dis.startHour,
            endHour: dis.endHour,
            startMinute: dis.startMinute,
            endMinute: dis.endMinute,
            totalOrders:
                (data['totalOrders'] == null) ? 0 : data['totalOrders'],
            totalTime: (data['totalTime'] == null ||
                    data['totalOrders'] == null ||
                    data['totalOrders'] === 0 ||
                    ((data['totalTime'] / data['totalOrders']) / 60) <= 7)
                ? 35
                : (data['totalTime'] >= 46)
                    ? 46
                    : (data['totalTime'] / data['totalOrders']) / 60,
            menu: [],
          };
          if (element.data()['enabled']) {
            var isOpen = null;
            if (element.data()['available']) {
              //   var timezone = tz.getLocation(element.data()['timezone']);
              //   DateTime date = tz.TZDateTime.now(timezone);
              //   var currentDay = date.weekday - 1;
              if (element.data()['schedule'][selectedWeekDay]['enabled']) {
                for (var i = 0;
                    i <
                        element
                            .data()['schedule'][selectedWeekDay]['lapses']
                            .length;
                    i++) {
                  //date = tz.TZDateTime.now(timezone);
                  //if(found.kDebugMode) print(date.hour);
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                              [i]['open']['hour'] <
                          selectedDate.getHours() &&
                      element.data()['schedule'][selectedWeekDay]['lapses']
                              [i]['close']['hour'] >
                          selectedDate.hour) {
                    isOpen = true;
                  } else if (element.data()['schedule'][selectedWeekDay]
                          ['lapses'][i]['open']['hour'] ===
                      selectedDate.getHours()) {
                    if (element.data()['schedule'][selectedWeekDay]
                            ['lapses'][i]['open']['minute'] <=
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  } else if (element.data()['schedule'][selectedWeekDay]
                          ['lapses'][i]['close']['hour'] ===
                      selectedDate.getHours()) {
                    if (element.data()['schedule'][selectedWeekDay]
                            ['lapses'][i]['close']['minute'] >
                        selectedDate.getMinutes()) {
                      isOpen = true;
                    }
                  }
                }
              }
              if (isOpen == null) isOpen = false;
            } else
              isOpen = false;
            if (!isOpen) {
              displayRestClosed[element.id] = tem1;
            //   await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
            //  // window.dispatchEvent(new Event("displayRestClosed"));
              dis = null;
            } else {
              delete displayRestClosed[element.id];
              displayRestOpen[element.id] = tem1;
            //   await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
            //  // window.dispatchEvent(new Event("displayRestClosed"));
            //   await localStorage.setItem('displayRestOpen',JSON.stringify(displayRestOpen));
            //   //window.dispatchEvent(new Event("displayRestOpen"));
              dis = null;
            }
          } else {
            delete displayRestClosed[element.id];
            // await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
           // window.dispatchEvent(new Event("displayRestClosed"));
          }
        }
        if (value.size === tot) {
           totalValues['restUpdatedOn'] = Timestamp.now().toDate();

          //if(found.kDebugMode) print('loaded: ' + boxAllRest.length.toString());
        }
      // });
      }

      try {
        await ldb.set('boxAllRest', JSON.stringify(boxAllRest), function(){
         window.dispatchEvent(new Event("boxAllRest"));
       });
        await ldb.set('boxAllStore', JSON.stringify(boxAllStore), function(){
         window.dispatchEvent(new Event("boxAllStore"));
       });
        await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
          //console.log('displayRestOpen1',displayRestOpen);
         window.dispatchEvent(new Event("displayRestOpen"));
       });
        await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
         window.dispatchEvent(new Event("displayRestClosed"));
       });
        await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
         window.dispatchEvent(new Event("displayStoreOpen"));
       });
        await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
         window.dispatchEvent(new Event("displayStoreClosed"));
       });
       await ldb.set('totalValues', JSON.stringify(totalValues), function(){
         window.dispatchEvent(new Event("totalValues"));
       });
      } catch(e) {
        //console.log(e);
        await localStorage.setItem('totalValues', JSON.stringify(totalValues));
        await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
        await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
        await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
        await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
        await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
        await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
        window.dispatchEvent(new Event("totalValues"));
        window.dispatchEvent(new Event("boxAllStore"));
       window.dispatchEvent(new Event("displayRestOpen"));
       window.dispatchEvent(new Event("displayRestClosed"));
       window.dispatchEvent(new Event("boxAllRest"));
       window.dispatchEvent(new Event("displayStoreClosed"));
       window.dispatchEvent(new Event("displayStoreOpen"));
      }

    });
    // } else {
    //   getRest();
  }
  resolve('resolved');
});
}
 
const loadStoreData = async() => {
  return new Promise(async (resolve) => {
    ////console.log('loadStoreData');
  // var store;
  //if (totalValues.get('storeUpdatedOn') != null) {
  // store = FirebaseFirestore.instance
  //     .collection('businesses')
  //     .where('food', isEqualTo: false)
  //     .where('updatedOn',
  //         isGreaterThan: Timestamp.fromDate(totalValues.get('storeUpdatedOn')));
  // } else {
  var totalValues;
  var boxAllRest;
  var boxAllStore;
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      boxAllRest = JSON.parse((await getAsyncItem('boxAllRest')) ??"{}");
      boxAllStore = JSON.parse((await getAsyncItem('boxAllStore')) ??"{}");
      displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
      displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
      displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
      displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
       boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
       boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }
  if(totalValues['storeUpdatedOn']) {} else {
    totalValues['storeUpdatedOn'] = Timestamp.fromMillis(157219766).toDate()
  }
    const q = query(
      collection(db, 'businesses'),
      where('food', '==', false),
      where('updatedOn', '>', Timestamp.fromDate(new Date(totalValues['storeUpdatedOn']))),
    );
  // }
  storeUpdate = onSnapshot(q, async (value) => {
    ////console.log('totalStores: ' + value.size.toString(),new Date(totalValues['storeUpdatedOn']));
    var tot = 0;
    for(var i=0;i<value.docs.length;i++) {
      var element = value.docs[i];
    // value.docChanges.forEach((element) async {
      tot++;
      var data = {};
      data = element.data();
      delete data.point;
      var cashOnly = false;
      if (data['cashOnly'] != null) {
        cashOnly = data['cashOnly'];
      }
      var cardOnly = false;
      if (data['cardOnly'] != null) {
        cardOnly = data['cardOnly'];
      }
      var spoon = false;
      var verified = false;
      var createdAt = 0;
      var offerEndTime = 0;
      var showOffer = false;
      var offerDesc = '';
      var partner = true;
      if (data['partner'] != null) {
        partner = data['partner'];
      }
      var cardOnDelivery = false;
      if (data['cardOnDelivery'] != null) {
        cardOnDelivery = data['cardOnDelivery'];
      }
      var minimumPurchase = 0;
      if (data['minimumPurchase'] != null) {
        minimumPurchase = data['minimumPurchase'] + 0.0;
      }
      var increasePercentage = 0;
      if (data['increasePercentage'] != null) {
        increasePercentage = data['increasePercentage'] + 0.0;
      }
      var onlyInHouseDriver = false;
      if (data['onlyInHouseDriver'] != null) {
        onlyInHouseDriver = data['onlyInHouseDriver'];
      }
      var enableIncrease = false;
      if (data['enableIncrease'] != null) {
        enableIncrease = data['enableIncrease'];
      }
      var driverServiceFee = 0;
      if (data['driverServiceFee'] != null) {
        driverServiceFee = data['driverServiceFee'] + 0.0;
      }
      var driverServicePercentage = 0;
      if (data['driverServicePercentage'] != null) {
        driverServicePercentage = data['driverServicePercentage'] + 0.0;
      }
      var isCityDisable = false;
      if (data['isCityDisable'] != null) {
        isCityDisable = data['isCityDisable'];
      }
      var canPickup = false;
      if (data['canPickup'] != null) {
        canPickup = data['canPickup'];
      }
      if (data['verified'] != null) {
        verified = data['verified'];
      }
      if (data['spoon'] != null) {
        spoon = data['spoon'];
      }
      if (data['createdAt'] != null) {
        createdAt = data['createdAt'];
      }
      if (data['offerEndTime'] != null) {
        offerEndTime = data['offerEndTime'];
      }
      if (data['showOffer'] != null) {
        showOffer = data['showOffer'];
      }
      if (data['offerDesc'] != null) {
        offerDesc = data['offerDesc'];
      }
      //data['updatedOn'] = data['updatedOn'].toDate();
      if (data['updatedOn'] != null) {
        data['updatedOn'] = data['updatedOn'].toDate();
      }
      if (data['willBeAvailableAt'] != null) {
        data['willBeAvailableAt'] = data['willBeAvailableAt'].toDate();
      }
      if (data['updateOn'] != null) {
        data['updateOn'] = data['updateOn'].toDate();
      }
      data['id'] = element.id;
      delete boxAllRest[element.id];
        delete displayRestOpen[element.id];
        delete displayRestClosed[element.id];
        boxAllStore[element.id] = data;
      //   await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
      //   //window.dispatchEvent(new Event("boxAllStore"));
      //   await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
      //  // window.dispatchEvent(new Event("displayRestOpen"));
      //   await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
      //   //window.dispatchEvent(new Event("displayRestClosed"));
      //   await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
      //   //window.dispatchEvent(new Event("boxAllRest"));
      if (displayStoreOpen[element.id]) {
        var dis = displayStoreOpen[element.id];
        var tem1 = {
          name: data['name'],
          id: element.id,
          logo: data['logo'],
          header: data['header'],
          description: data['description'],
          about: data['about'],
          phone: data['phone'],
          dynamicPriceType: dis.dynamicPriceType,
          deliveryPrice: dis.deliveryPrice,
          devyPercentageFee: data['fixedUsageFee'],
          percentageUsageFee: data['percentageUsageFee'],
          serviceFee: data['serviceFee'],
          partnerPercentageFee: data['tax'],
          averageRating: data['averageRating'],
          totalReviews: data['totalReviews'],
          costForOne: data['costForOne'],
          distance: dis.distance,
          location: data['location'],
          isFavorite: dis.isFavorite,
          schedule: data['schedule'],
          timezone: data['timezone'],
          available: data['available'],
          featured: data['featured'],
          cashOnly: cashOnly,
          cardOnly: cardOnly,
          spoon: spoon,
          verified: verified,
          onlyInHouseDriver: onlyInHouseDriver,
          enableIncrease:enableIncrease,
          minimumPurchase: minimumPurchase,
          increasePercentage:increasePercentage,
          cardOnDelivery: cardOnDelivery,
          driverServiceFee: driverServiceFee,
          driverServicePercentage: driverServicePercentage,
          isCityDisable: isCityDisable,
          canPickup: canPickup,
          partner: partner,
          createdTime: createdAt,
          offerText: offerDesc,
          offerTime: offerEndTime,
          hasOffer: showOffer,
          inDisableZone: dis.inDisableZone,
          startHour: dis.startHour,
          endHour: dis.endHour,
          startMinute: dis.startMinute,
          endMinute: dis.endMinute,
          totalOrders: (data['totalOrders'] == null) ? 0 : data['totalOrders'],
          totalTime: (data['totalTime'] == null ||
                  data['totalOrders'] == null ||
                  data['totalOrders'] === 0 ||
                  ((data['totalTime'] / data['totalOrders']) / 60) <= 7)
              ? 35
              : (data['totalTime'] >= 46)
                  ? 46
                  : (data['totalTime'] / data['totalOrders']) / 60,
          menu: [],
        };
        if (element.data()['enabled']) {
          var isOpen = null;
          if (element.data()['available']) {
            // var timezone = tz.getLocation(element.data()['timezone']);
            // DateTime date = tz.TZDateTime.now(timezone);
            // var currentDay = date.weekday - 1;
            if (element.data()['schedule'][selectedWeekDay]['enabled']) {
              for (var i = 0;
                  i <
                      element
                          .data()['schedule'][selectedWeekDay]['lapses']
                          .length;
                  i++) {
                //date = tz.TZDateTime.now(timezone);
                //if(found.kDebugMode) print(date.hour);
                if (element.data()['schedule'][selectedWeekDay]['lapses'][i]
                            ['open']['hour'] <
                        selectedDate.getHours() &&
                    element.data()['schedule'][selectedWeekDay]['lapses'][i]
                            ['close']['hour'] >
                        selectedDate.getHours()) {
                  isOpen = true;
                } else if (element.data()['schedule'][selectedWeekDay]
                        ['lapses'][i]['open']['hour'] ===
                    selectedDate.getHours()) {
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                          [i]['open']['minute'] <=
                      selectedDate.getMinutes()) {
                    isOpen = true;
                  }
                } else if (element.data()['schedule'][selectedWeekDay]
                        ['lapses'][i]['close']['hour'] ===
                    selectedDate.getHours()) {
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                          [i]['close']['minute'] >
                      selectedDate.getMinutes()) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else
            isOpen = false;
          if (isOpen) {
            //await displayStoreOpen.put(element.id, tem1);
            // delete displayRestOpen[element.id];
            displayStoreOpen[element.id] = tem1;
            //   await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
            //  // window.dispatchEvent(new Event("displayStoreOpen"));
            //   //await localStorage.setItem('displayRestClosed',JSON.stringify(displayRestClosed));
            dis = null;
          } else {
            delete displayStoreOpen[element.id];
            displayStoreClosed[element.id] = tem1;
            //   await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
            //  // window.dispatchEvent(new Event("displayStoreOpen"));
            //   await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
            //   //window.dispatchEvent(new Event("displayStoreClosed"));
            // await displayStoreOpen.delete(element.id);
            // await displayStoreClosed.put(element.id, tem1);
            dis = null;
          }
        } else {
          //await displayStoreOpen.delete(element.id);
          delete displayStoreOpen[element.id];
            // //displayStoreClosed[element.id] = tem1;
            //   await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
            //  // window.dispatchEvent(new Event("displayStoreOpen"));
        }
      }
      if (displayStoreClosed[element.id]) {
        var dis =  displayStoreClosed[element.id];
        var tem1 = {
          name: data['name'],
          id: element.id,
          logo: data['logo'],
          header: data['header'],
          description: data['description'],
          about: data['about'],
          phone: data['phone'],
          dynamicPriceType: dis.dynamicPriceType,
          deliveryPrice: dis.deliveryPrice,
          devyPercentageFee: data['fixedUsageFee'],
          percentageUsageFee: data['percentageUsageFee'],
          serviceFee: data['serviceFee'],
          partnerPercentageFee: data['tax'],
          averageRating: data['averageRating'],
          totalReviews: data['totalReviews'],
          costForOne: data['costForOne'],
          distance: dis.distance,
          location: data['location'],
          isFavorite: dis.isFavorite,
          schedule: data['schedule'],
          timezone: data['timezone'],
          available: data['available'],
          featured: data['featured'],
          cashOnly: cashOnly,
          cardOnly: cardOnly,
          spoon: spoon,
          verified: verified,
          onlyInHouseDriver: onlyInHouseDriver,
          enableIncrease:enableIncrease,
          minimumPurchase: minimumPurchase,
          increasePercentage:increasePercentage,
          cardOnDelivery: cardOnDelivery,
          driverServicePercentage: driverServicePercentage,
          driverServiceFee: driverServiceFee,
          isCityDisable: isCityDisable,
          canPickup: canPickup,
          partner: partner,
          createdTime: createdAt,
          offerText: offerDesc,
          offerTime: offerEndTime,
          hasOffer: showOffer,
          inDisableZone: dis.inDisableZone,
          startHour: dis.startHour,
          endHour: dis.endHour,
          startMinute: dis.startMinute,
          endMinute: dis.endMinute,
          totalOrders: (data['totalOrders'] == null) ? 0 : data['totalOrders'],
          totalTime: (data['totalTime'] == null ||
                  data['totalOrders'] == null ||
                  data['totalOrders'] === 0 ||
                  ((data['totalTime'] / data['totalOrders']) / 60) <= 7)
              ? 35
              : (data['totalTime'] >= 46)
                  ? 46
                  : (data['totalTime'] / data['totalOrders']) / 60,
          menu: [],
        };
        if (element.data()['enabled']) {
          var isOpen = null;
          if (element.data()['available']) {
            // var timezone = tz.getLocation(element.data()['timezone']);
            // DateTime date = tz.TZDateTime.now(timezone);
            // var currentDay = date.weekday - 1;
            if (element.data()['schedule'][selectedWeekDay]['enabled']) {
              for (var i = 0;
                  i <
                      element
                          .data()['schedule'][selectedWeekDay]['lapses']
                          .length;
                  i++) {
                //date = tz.TZDateTime.now(timezone);
                //if(found.kDebugMode) print(date.hour);
                if (element.data()['schedule'][selectedWeekDay]['lapses'][i]
                            ['open']['hour'] <
                        selectedDate.getHours() &&
                    element.data()['schedule'][selectedWeekDay]['lapses'][i]
                            ['close']['hour'] >
                        selectedDate.getHours()) {
                  isOpen = true;
                } else if (element.data()['schedule'][selectedWeekDay]
                        ['lapses'][i]['open']['hour'] ===
                    selectedDate.getHours()) {
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                          [i]['open']['minute'] <=
                      selectedDate.getMinutes()) {
                    isOpen = true;
                  }
                } else if (element.data()['schedule'][selectedWeekDay]
                        ['lapses'][i]['close']['hour'] ===
                    selectedDate.getHours()) {
                  if (element.data()['schedule'][selectedWeekDay]['lapses']
                          [i]['close']['minute'] >
                      selectedDate.getMinutes()) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else
            isOpen = false;
          if (!isOpen) {
            //await displayStoreClosed.put(element.id, tem1);
            //delete displayStoreOpen[element.id];
            displayStoreClosed[element.id] = tem1;
            //   //await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
            //   await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
            // //  window.dispatchEvent(new Event("displayStoreClosed"));
            dis = null;
          } else {
            // await displayStoreClosed.delete(element.id);
            // await displayStoreOpen.put(element.id, tem1);
            delete displayStoreClosed[element.id];
            displayStoreOpen[element.id] = tem1;
            //   await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
            //  // window.dispatchEvent(new Event("displayStoreOpen"));
            //   await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
            //  // window.dispatchEvent(new Event("displayStoreClosed"));
            dis = null;
          }
        } else {
          //await displayStoreClosed.delete(element.id);
          delete displayStoreClosed[element.id];
            //displayStoreClosed[element.id] = tem1;
              //await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
              // await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
             // window.dispatchEvent(new Event("displayStoreClosed"));
        }
      }
      if (value.size === tot) {
         totalValues['storeUpdatedOn'] = Timestamp.now().toDate();
         try {
          await ldb.set('boxAllRest', JSON.stringify(boxAllRest), function(){
           window.dispatchEvent(new Event("boxAllRest"));
         });
          await ldb.set('boxAllStore', JSON.stringify(boxAllStore), function(){
           window.dispatchEvent(new Event("boxAllStore"));
         });
          await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
            //console.log('displayRestOpen2',displayRestOpen);
           window.dispatchEvent(new Event("displayRestOpen"));
         });
          await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
           window.dispatchEvent(new Event("displayRestClosed"));
         });
          await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
           window.dispatchEvent(new Event("displayStoreOpen"));
         });
          await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
           window.dispatchEvent(new Event("displayStoreClosed"));
         });
         await ldb.set('totalValues', JSON.stringify(totalValues), function(){
           window.dispatchEvent(new Event("totalValues"));
         });
        } catch(e) {
          //console.log(e);
          await localStorage.setItem('totalValues', JSON.stringify(totalValues));
          await localStorage.setItem('boxAllRest', JSON.stringify(boxAllRest));
          await localStorage.setItem('boxAllStore', JSON.stringify(boxAllStore));
          await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
          await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
          await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
          await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
          window.dispatchEvent(new Event("totalValues"));
          window.dispatchEvent(new Event("boxAllStore"));
         window.dispatchEvent(new Event("displayRestOpen"));
         window.dispatchEvent(new Event("displayRestClosed"));
         window.dispatchEvent(new Event("boxAllRest"));
         window.dispatchEvent(new Event("displayStoreClosed"));
         window.dispatchEvent(new Event("displayStoreOpen"));
        }

        //if(found.kDebugMode) print('loadedstores: ' + boxAllStore.length.toString());
      }
    }
  });
  resolve('resolved');
});
}
 
const getUserData = async() => {
  return new Promise(async (resolve) => {
    ////console.log('getUserData');
  //if(found.kDebugMode) print('ger');
  onAuthStateChanged(auth, async (user) => {
  if (user != null) {
    loggedInUser = user;
    const menuDoc = doc(db, 'users', user.uid);
    userUpdate = await onSnapshot(menuDoc, async (userEvent) => {
    // userUpdate = users.doc(loggedInUser.uid).snapshots().listen((userEvent) {
      userData = userEvent.data();
      //if(found.kDebugMode) print('ger2');
    });
  }
});
resolve('resolved');
  });
}

const getDisplayData = async() => {
  return new Promise(async (resolve) => {
    ////console.log('getDisplayData');
  var boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
  var totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
  var boxAllRest = JSON.parse(localStorage.getItem('boxAllRest')??"{}");
  var boxAllStore = JSON.parse(localStorage.getItem('boxAllStore')??"{}");
  var displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
  var displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
  var displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
  var displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");

  if (boxSelected['latitude'] != null) {
    selectedAddress = { latitude: boxSelected['latitude'], longitude: boxSelected['longitude'] };
    // selectedAddress = mp.LatLng(boxSelected.get('selected')['latitude'],
    //     boxSelected.get('selected')['longitude']);
    // if (displayRestOpen.isEmpty &&
    //     displayRestClosed.isEmpty &&
    //     displayStoreClosed.isEmpty &&
    //     displayStoreOpen.isEmpty) {
    await getBusiness();
    var user = loggedInUser;
    if (user != null) {

      //console.loh('ffgd');
      const GeoFirestore = geofirestore.initializeApp(db);
       await GeoFirestore.collection('users').doc(user.uid).update({
        coordinates: GeoPoint(boxSelected['latitude'], boxSelected['longitude'])
      }).then((value)=>{
        ////console.log('ddrr');
      })
      // GeoFirePovar myLocation = geo.point(
      //     latitude: boxSelected.get('selected')['latitude'],
      //     longitude: boxSelected.get('selected')['longitude']);
      // await users.doc(user.uid).update({'g': myLocation.data}).then((value) {
      //   if (found.kDebugMode) print('ddrr');
      // });
    }

    // }
  }
  window.addEventListener('selected', async() => {
    ////console.log("Change to local storage!selected");
    boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
    ////console.log(boxSelected);
    if(boxSelected['latitude']!=null){
      selectedAddress = { latitude: boxSelected['latitude'], longitude: boxSelected['longitude'] };
  await getBusiness();
  var user = loggedInUser;
  if (user != null) {
    //console.loh('ffgd');
      const GeoFirestore = geofirestore.initializeApp(db);
       await GeoFirestore.collection('users').doc(user.uid).update({
        coordinates: GeoPoint(boxSelected['latitude'], boxSelected['longitude'])
      }).then((value)=>{
        ////console.log('ddrr');
      })
    // if (found.kDebugMode) print('ffgd');
    // GeoFirePovar myLocation = geo.point(
    //     latitude: boxSelected.get('selected')['latitude'],
    //     longitude: boxSelected.get('selected')['longitude']);
    // await users.doc(user.uid).update({'g': myLocation.data}).then((value) {
    //   if (found.kDebugMode) print('ddrr');
    // });
  }
    }
});
  // locationUpdate = boxSelected.watch(key: 'selected').listen((event) async {
  //   //if(found.kDebugMode) print('ddfffd');
  //   if (boxSelected.get('selected') != null) {

  //   }
  // });
  resolve('resolved');
});
}
 
root.render(
  <ThemeProvider theme={theme}>
  <BrowserRouter>
  <Routes>
            <Route path="/business/:id" element={<Detail />}/> 
            <Route path="/profile" element={<Profile />}/> 
            <Route path="/profile/:id" element={<Profile />}/> 
            <Route path="/my-orders" element={<Orders />}/> 
            <Route path="/my-orders/:id" element={<Orders />}/> 
            <Route path="/" element={<Main />}/> 
            <Route path="/dashboard" element={<DasboardPage />}/> 
            <Route path="/business/" element={<Main />}/> 
</Routes>
  </BrowserRouter>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
mainLoad();
