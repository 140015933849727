import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';
import { strings } from './translations';

export const CheckoutForm = ({total,label,handlePaymentMethodReceived}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'ES',
        currency: 'eur',
        total: {
          label: label,
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        console.log(result);
        if (result && (result.applePay || result.googlePay)) {
            pr.on('paymentmethod', handlePaymentMethodReceived);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return <>
    {/* <Typography
                    gutterBottom
                    align="center"
                    style={{ color: '#626a71', margin: '0' }}
                  >
                    {strings["o"]}
                  </Typography>
                  <br /> */}
    <PaymentRequestButtonElement options={{paymentRequest}} />
    </>
  }

  // Use a traditional checkout form.
  return <></>;
}