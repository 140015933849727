import './Main.css';
import * as React from 'react';
import * as geolib from 'geolib';
import { Alert, AppBar, Autocomplete, Backdrop, Badge, Box, Button, CircularProgress, Container, CssBaseline, Dialog, DialogContent, DialogTitle, Divider, FilledInput, FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, Snackbar, Stack, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { AccountCircle, LocationOn, Visibility, VisibilityOff } from '@mui/icons-material';
import Geocode from 'react-geocode';
import _ from 'lodash';
import ldb from 'localdata'
import parse from 'autosuggest-highlight/parse';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import DataSaverOnRoundedIcon from '@mui/icons-material/DataSaverOnRounded';
import AppleIcon from '@mui/icons-material/Apple';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import validator from 'validator';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2'
import List from '@mui/material/List';
import GoogleMapReact from 'google-map-react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CancelIcon from '@mui/icons-material/Cancel';
import AndroidIcon from '@mui/icons-material/Android';
import { strings } from './translations';
import { auth, db } from './firebaseData';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import en from 'react-phone-number-input/locale/en.json';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { collection, doc, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import axios from 'axios';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { isIOS } from 'react-device-detect';
import { constants } from './components/constants';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InstagramIcon from '@mui/icons-material/Instagram';

const logo = require('./assets/loading.gif');
const mainLogo = require('./assets/logo.png');
const mainLogo2 = require('./assets/logo2.png');
const userOn1 = require('./assets/user-on-1.png');
const userOn2 = require('./assets/user-on-2.png');
const Dandroid = require('./assets/Dandroid.png');
const Dapple = require('./assets/Dapple.png');

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <FormControl variant="filled" sx={{ minWidth: 75 }}>
    <InputLabel id="demo-simple-select-filled-label">Code</InputLabel>
    <Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <MenuItem value="">{labels['ZZ']}</MenuItem>
      {getCountries().map((country) => (
        // <MenuItem key={country} value={"+"+getCountryCallingCode(country)}>
        //   {labels[country]} +{getCountryCallingCode(country)}
        // </MenuItem>
        <MenuItem key={country} value={'+' + getCountryCallingCode(country)}>
          +{getCountryCallingCode(country)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

function SimpleDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Mis direcciones']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function AllergyDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

AllergyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

function MyCardsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['profileMyCard']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

MyCardsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function RegisterDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Registro']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

RegisterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function LoginDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ fontSize: '1.5rem', }}>
        {strings['Ingresar']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

const getAsyncItem = async (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ldb.get(value, function (value2) {
        console.log('value',value);
        if(value === 'totalValues') {
          console.log('totalValues', value2);
        }
        resolve(value2);
      })
    } catch(e) {
      console.log(value,e);
      reject(new Error("Whoops!"));
    }
});
}
class Main extends React.Component {

  constructor(props, context) {
    super(props, context);
    this._ismounted = true;
    this.state = {
      orders: [],
      addedNotes: false,
      orderLoading: false,
      codeData: '',
      codeError: false,
      canPickup: false,
            toggledOff:false,
            cashOnly:false,
      newAllergy: '',
      busId: '',
      alertMsg: '',
      openAddCard: false,
      allergyActivate: true,
      newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: '',
        'save': false,
      },
      categoriesList: [
        {name: strings['Fitness'],image: "Fitness.jpg"},
        {name: strings['Comida Rápida'],image: "FastFood.jpg"},
        {name: strings['Alitas'],image: "Wings.jpg"},
        {name: strings['Tacos'],image: "Tacos.jpg"},
        {name: strings['Pizzas'],image: "Pizzas.jpg"},
        {name: strings['Hamburguesas'],image: "Burgers.jpg"},
        {name: strings['Oriental'],image: "Oriental.jpg"},
        {name: strings['Pollos'],image: "Chickens.jpg"},
        {name: strings['Mariscos'],image: "SeaFood.jpg"},
        {name: strings['Mexicana'],image: "Mexican.jpg"},
        {name: strings['Internacional'],image: "International.jpg"},
        {name: strings['Postres'],image: "Deserts.jpg"},
      ],
      selectLocationName: null,
      showAllergyDisclaimer: true,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showAllergy: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      isMounted: true,
      anchorEl: null,
      open: false,
      openLocation: false,
      openCards: false,
      openPreOrder: false,
      showTomorrow: false,
      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null,
      currenciesList2Selected: null,
      currenciesListSelected: null,
      currenciesList3Selected: null,
      currenciesList4Selected: null,
      currenciesList5Selected: null,
      currenciesList6Selected: null,
      currenciesList7Selected: null,
      currenciesList8Selected: null,
      openLogin: false,
      showGuest: false,
      isGuest: false,
      guestOrder: null,
      showAdditionalDetails: false,
      guestDetails: {
        'name': '',
        'email': '',
        'phone': ''
      },
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      offersDetails: {},
      tempOrder: {},
      hasAllData: true,
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+34',
      showPassword: false,
      openRegister: false,
      openOTP: false,
      showMinimum: false,
      openDialogAlert: false,
      openDialogCancel: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      showAllergyData: true,
      allergy: [],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      addresses: [],
      defaultCountry: 'ES',
      selectedLocationDetails: {},
      cards: [],
      loading: true,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      circleData: null,
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      upsellingProd: [],
      upsellingProd2: [],
      userDetails: null,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isTomorrow: false,
      isToday: false,
      isPreOrder: false,
      isNewUser: true,
      isStore: false,
      order: {
        offerCode: '',
        selectedOffer: {},
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      selectedOffer: null,
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
      currenciesList: [],
      currenciesList3: [],
      currenciesList4: [],
      currenciesList5: [],
      currenciesList6: [],
      currenciesList7: [],
      currenciesList8: [],
      currenciesList2: [],
      vh: window.innerHeight * 0.01,
    };
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const q = query(
          collection(db, 'orders'),
          where('userId', '==', uid),
          where('delivered', '==', false),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            if (true) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
              if (docMenuSnap.data()['custId'] == null) {
                try {
                  var response = await axios.post(
                    'https://watson-order.herokuapp.com/drivers/createcust/',null,
                    {
                    params: {
                      'email': docMenuSnap.data().email,
                      'name': docMenuSnap.data().fullName,
                      'phone': docMenuSnap.data().phoneNumber,
                      'uid': uid,
                    },
                  }
                  );
                  if (response.status === 200) {
                    console.log(response.data['id']);
                    if (response.data['id'] != null) {
                    } else {
                      console.log('failed');
                    }
                  } else {
                    console.log('failed');
                  }
                } catch (e) {
                  console.log('exception $e',e);
                }
              } else {
                // custId = (event.data() as Map)['custId'].toString();
                // print(custId);
                // //custId = 'cus_JwCyg2xRg0XoOT';
                // updateCards();
              }
            } else {
              if(!this.state.openOTP && this.state.isMounted && this._ismounted) {
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+34',
                showPassword: false,
              });
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend,this.state.isMounted, this._ismounted);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://watson-order.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  console.log(response);
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
            }
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: 'Error de inicio de sesión',
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });
              auth.signOut();
            }
          }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });

        //       signInWithEmailAndPassword(auth, 'demo@demo.com', 'demo1234')
        // .then((userCredential) => {
        //   // Signed in
        //   console.log('signing',userCredential);
        //   const user = userCredential.user;
        //   const uid = user.uid;
        //   this.setState({ userId: uid });
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorMessage,errorCode);
        // });
        // User is signed out
        // ...
      }
    });

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setState({ vh: vh });
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      this.setState({ vh: vh });
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
}

componentDidMount() {
  this.checkTotal();
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}


async checkTotal() {
  var totalValues;
    try {
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
    } catch(e) {
      console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
    }
    if(totalValues!=null && ((totalValues['restUpdatedOn'] != null || totalValues['storeUpdatedOn']!=null))) {
      this.setState({loading: false});
    } else {
      this.setState({loading: true});
    }
    window.addEventListener('totalValues', async() => {
      var totalValues;
      try {
        totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      } catch(e) {
        console.log(e);
        totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
      }
      if(totalValues!=null && ((totalValues['restUpdatedOn'] != null || totalValues['storeUpdatedOn']!=null))) {
        this.setState({loading: false});
      }  else {
        this.setState({loading: true});
      }
    });
}

async componentWillMount() {
  var locationData;
  try {
    locationData = JSON.parse(
    localStorage.getItem('location')
  );
} catch(e) {
console.log(e);
}

if(locationData  == null) {
locationData = [];
}
  var showAllergyDisclaimer;
  try {
    showAllergyDisclaimer = JSON.parse(localStorage.getItem('showAllergyDisclaimer'))
} catch(e) {
console.log(e);
}
  if (showAllergyDisclaimer == null) {
    showAllergyDisclaimer = true;
  }
  for (var i = 0; i < locationData.length; i++) {
    if (locationData[i]['selected']) {
      this.setState({
        selectLocationName: locationData[i]['geoAddress'],
      });
      // console.log(locationData[i]);
      break;
    }
  }
  this.setState({ addresses: locationData, showAllergyDisclaimer: showAllergyDisclaimer }, (newState) => {
    // console.log(
    //   'locationData',
    //   this.state.addresses,
    //   typeof this.state.addresses
    // );
  });
  var selected;
  try {
    selected = JSON.parse(localStorage.getItem('selected'));
} catch(e) {
console.log(e);
}
  if (selected == null) {
    selected = { latitude: 0, longitude: 0 };
  }
  this.setState({ selectedAddress: selected }, (newState) => {
    // console.log(
    //   'selectedAddress',
    //   this.state.selectedAddress,
    //   typeof this.state.selectedAddress
    // );
  });
  // console.log('locationData', locationData);
  // const { id } = this.props.match.params;
  var date2 = new Date();
  var weekDay = [6,0,1,2,3,4,5];
  var currentDay = weekDay[date2.getDay()];
  this.setState({ selectedDate: date2, selectedWeekDay: currentDay, busId: '' });
  // this.setState({ businessData: {} });

  // await this.getCities(id);
}



render() {
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(this.state.anchorEl);
  const handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  const handleProfileMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
    // this.state.anchorEl = event.currentTarget;
  };
  const openAlert = (alertType, alertMsg) => {
    this.setState({
      openAlert: true,
      alertType: alertType,
      alertMsg: alertMsg,
    });
  };
  const handleClickOpenLogin = () => {
    this.setState({
      openLogin: true,
      showGuest: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+34',
      showPassword: false,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCloseLogin();
    };
  };
  const handleCloseLogin = () => {
    this.setState({
      openLogin: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+34',
      showPassword: false,
    });
  };
  const handleClickOpenRegister = () => {
    this.setState({
      openRegister: true,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+34',
      showPassword: false,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCloseRegister();
    };
  };
  const handleCloseRegister = () => {
    this.setState({
      openRegister: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+34',
      showPassword: false,
    });
  };
  const handleCardClickOpen = () => {
    this.setState({ openCards: true, cards:(this.state.userDetails && this.state.userDetails.cards) ? this.state.userDetails.cards : [], });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCardClose();
    };
  };
  const handleCardClose = (value) => {
    this.setState({ openCards: false });
  };
  const handleAllergyOpen = (event) => {
    this.setState({
      showAllergy: true,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      this.setState({ showAllergy: false });
    };
    // this.state.anchorEl = event.currentTarget;
  };
  const handleClickOpen = () => {
    if(!this.state.selectLocationName) {
    this.setState({ openLocation: true, addedNotes: false });
    this.setState({ hasClickedLoc: false });
    this.setState({
      selectLocationData: {
        id: '',
        name: strings['mi hogar'],
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: this.state.defaultProps.center.lat,
        longitude: this.state.defaultProps.center.lng,
      },
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleClose();
    };
  } else {
    window.open('/dashboard',"_self");
  }
  };
  const handleClose = (value) => {
    this.setState({ openLocation: false, addedNotes: false });
    this.setState({ hasClickedLoc: false });
    this.setState({ autoLoc: '' });
    this.setState({ selectedValue: value });
    this.setState({
      selectLocationData: {
        id: '',
        name: strings['mi hogar'],
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: this.state.defaultProps.center.lat,
        longitude: this.state.defaultProps.center.lng,
      },
    });
  };
  const renderMenu = (
    <>
      {!this.state.userId ? (
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem style={{ fontFamily: 'Nunito', fontWeight: '500', }}
            onClick={() => {
              handleMenuClose();
              handleClickOpenLogin();
            }}
          >
          <Stack
               direction="row"
               justifyContent="center"
               alignItems="center"
               spacing={0}
              >
              <LoginRoundedIcon
                 style={{
                   height: this.state.width > 599 ? '17px' : '20px',
                   fontSize: this.state.width > 599 ? '17px' : '20px',
                   fontWeight: 'bold',
                   color: constants.primaryColor,
                   marginRight: '10px',
                 }}
               />
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 padding: '5px 11px',
                 color: '#3e4547',
                 fontSize: this.state.width > 599 ? '16px' : '18px',
               }}
             >
               {strings["Iniciar sesión"]}
             </Typography>
            </Stack>
          </MenuItem>
          <MenuItem style={{ fontFamily: 'Nunito', fontWeight: '500', }}
            onClick={() => {
              handleMenuClose();
              handleClickOpenRegister();
              // handleClickOpenOTP();
            }}
          >
          <Stack
               direction="row"
               justifyContent="center"
               alignItems="center"
               spacing={0}
              >
              <DataSaverOnRoundedIcon
                 style={{
                   height: this.state.width > 599 ? '17px' : '20px',
                   fontSize: this.state.width > 599 ? '17px' : '20px',
                   fontWeight: 'bold',
                   color: constants.primaryColor,
                   marginRight: '10px',
                 }}
               />
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 padding: '5px 11px',
                 color: '#3e4547',
                 fontSize: this.state.width > 599 ? '16px' : '18px',
               }}
             >
               {strings["Registrarse"]}
             </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {this.state.userDetails && (
            <>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/profile'}
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  {strings['Perfil']}
                </Link>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/my-orders'}
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  {strings['Mis Pedidos']}
                </Link>
              </MenuItem>
              {/* <MenuItem
                style={{
                  color: 'rgb(43 44 52)',
                  textDecoration: 'none',
                  width: '100%',
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  fontSize: '18px',
                }}
                onClick={() => {
                  handleMenuClose();
                  handleCardClickOpen();
                  // handleClickOpenOTP();
                }}
                >
                  {strings['profileMyCard']}
              </MenuItem> */}
              {(this.state.showAllergyData) ?
               <MenuItem
                 style={{
                   color: 'rgb(43 44 52)',
                   textDecoration: 'none',
                   width: '100%',
                   fontFamily: 'Nunito',
                   fontWeight: '600',
                   fontSize: '18px',
                 }}
                 onClick={() => {
                   handleMenuClose();
                   handleAllergyOpen();
                   // handleClickOpenOTP();
                 }}
               >{strings['profileAllergy']}
               </MenuItem>
              :null }
            </>
          )}
          <MenuItem
          style={{
            color: 'rgb(43 44 52)',
            textDecoration: 'none',
            width: '100%',
            fontFamily: 'Nunito',
            fontWeight: '600',
            fontSize: '18px',
          }}
            onClick={async () => {
              handleMenuClose();
              await auth.signOut();
            }}
          >
            {strings['Cerrar sesión']}
          </MenuItem>
        </Menu>
      )}
    </>
  );
  var _sessionToken = uuidv4();
  return (
    <>
    {/* <div id="loading_indicator" className="container">
      <img style={{width: this.state.width < 1000? "40vw":"15vw"}} src={logo} alt="loading" />
    </div> */}
    <CssBaseline />
    <Backdrop
          onClick={() => {}}
          style={{ color: '#fff', zIndex: 9999999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <Snackbar
        style={{zIndex: 9999999}}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            this.setState({ openAlert: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }

              this.setState({ openAlert: false });
            }}
            severity={this.state.alertType}
            sx={{ width: '100%' }}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
    <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            color="transparent"
            elevation={1}
            style={{
              border: '0px solid #e2e8f0',
              boxShadow: 'none',
              background: 'white',
              height: '60px',
            }}
          >
            <Toolbar>
            <Box
              textAlign="center"
              height={'auto'}
              // style={{ borderRadius: '50%' }}
            >
              <img
                src={mainLogo}
                alt="logo"
                style={{ height: '40px', verticalAlign: 'middle', cursor: 'pointer', }}
              />
            </Box>


              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ display: { xs: 'flex', md: 'flex' } }}
                style={{
                  margin: '1px',
                  borderLeft: '0px solid #cccccc',
                  color: constants.primaryColor,
                }}
              >

                <IconButton
                  style={{ background: 'white',
                    boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                    width: '40px',
                    height: '40px',
                    marginRight: '0px',
                    marginTop: this.state.width > 1000 ? '0' : '0',
                  }}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >

                    <AccountCircle />

                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMenu}
        </Box>
        <AllergyDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showAllergy}
          onClose={() => {
            this.setState({ showAllergy: false });
          }}
          title={this.state.width}
          displayList={
            <>{ (this.state.showAllergyDisclaimer)?<Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      textAlign:"center",
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms1"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div className = "head-text" style={{width: `${this.state.width > 600 ? 370: this.state.width - 30}px`, height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`}}>
        <div>
          <img
          style={{width: `${this.state.width > 600 ? 385: this.state.width}px`,padding: "0px 15px 0px"}}
          src = {require('./assets/user-allergy.png')} alt = {"Alergia"} />
        </div>
          <div class='text-on-image'
          style={{
            padding: "0px 15px 0px",
            width: `${this.state.width > 600 ? 380: this.state.width - 20}px`,
            height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`,
            paddingLeft: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.37}px`,
            paddingRight: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.15}px`,
            top: this.state.width > 600 ? '3%' : '29%',
          }}>
          <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '700',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerTitle"]}
                  </Typography>
                  <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '400',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerSubTitle"]}
                  </Typography>
          </div>
      </div>
      <div style={{paddingTop: "20px"}}></div>
      <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms2"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms3"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms4"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms5"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms6"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms7"]}
                  </Typography>
                  <div style={{paddingTop: "8px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms8"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms9"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms10"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms11"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms12"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms13"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms14"]}
                  </Typography>
                  <div style={{paddingTop: "5px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Stack
                  direction={'column'}
                  spacing={0}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms15"]}
                  </Typography>
                  <div style={{paddingTop: "10px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms16"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms17"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms18"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms19"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms20"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms21"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms22"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms23"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms24"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms25"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms26"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms27"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms28"]}
                  </Typography>
                </Stack>
                  </div>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                      {strings["allergyTerms29"]}
                  </Typography>
                  <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={() => {
                          this.setState({ showAllergyDisclaimer: false });
                          try {
                          localStorage.setItem('showAllergyDisclaimer', JSON.stringify(false));
                          window.dispatchEvent(new Event("showAllergyDisclaimer"));

                          } catch(e) {}
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '24px' : '22px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['begin']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
                {/* <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard == null &&
                  this.state.userDetails == null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          width: '88%',
                          cursor: 'pointer',
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['Ingresar']}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  ) : null} </> */}
              </Stack>
            </Container>:
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "15px 15px 15px",
                      color: 'slategray',
                    }}
                  >
                    {strings["allergyText1"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      fontFamily: 'Nunito',
                      letterSpacing: 'normal',
                      padding: '0 15px',
                    }}
                  >
                    {strings['my_allergies_list_tittle']}
                  </Typography>
              {this.state.allergy?.map((allergy, i) => (
                <div style={{padding: "8px"}}>
                  <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                  <Stack direction={'row'} spacing={0} justifyContent="space-between" alignItems="center">
                  <Typography
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '20px',
                      width: `${this.state.width > 600 ? 350: this.state.width - 50}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',
                      padding: "15px 15px 15px"
                    }}
                  >
                  {allergy}
                  </Typography>
                  <DeleteForeverIcon
                  onClick={
                    async()=>{
                      var temp = this.state.allergy;
                      var index = temp.indexOf(allergy);
                      if(index>=0) {
                        temp.splice(index,1);
                      }
                      // console.log(temp, index);
                            this.setState({
                              allergy: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />
                  </Stack></div></div>
                      ))}
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{padding: "15px 15px 15px"}}>
                  <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                // error={
                //   !validator.isEmail(this.state.email) &&
                //   this.state.email.length > 0
                // }
                fullWidth
                id="filled-basicallergyAdd"
                label={strings["allergy_holder"]}
                variant="filled"
                value={this.state.newAllergy}
                onChange={(event) => {
                  this.setState({ newAllergy: event.target.value });
                }}
              /></div>
                                <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={async() => {
                          if(this.state.newAllergy && this.state.newAllergy!=='') {
                            var temp = this.state.allergy;
                            temp.push(this.state.newAllergy);
                            this.setState({
                              newAllergy: '',
                              allergy: temp
                            });
                            try {
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
          } catch(e) {
            console.log(e);
          }
                          }
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          borderRadius: this.state.width < 600 ? '12px' : '12px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%',
                          height: '61px',
                          boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px',
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                                marginTop: this.state.width < 600 ? '0' : '3px',
                              }}
                            >
                              {strings['allergyAdd']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
              </Stack>
            </Container>
            }</>
          }
        ></AllergyDialog>
                        <SimpleDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLocation}
          onClose={handleClose}
          displayList={
            <DialogContent dividers>
              {this.state.addresses <= 0 ?
                <div style={{ textAlign: 'center', marginBottom: '12px', }}>
                  <img src = {require('./assets/location_home.png')} alt = {"Location"} style={{ height: '60px',width: 'auto', }}/>
                </div>
              :null}
              {this.state.addresses <= 0 ?
                <Typography style={{ fontSize: '20px', fontWeight: '800', letterSpacing: 'normal', color: '#2e2c36', marginBottom: '26px', textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección2"]}
                </Typography>
              :null}
              {this.state.addresses <= 0 ?
                <Typography gutterBottom style={{ textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección4"]}
                </Typography>
              :null}
              {this.state.addresses != 0 ?
              <Typography gutterBottom>
                {strings["Selecciona tu ubicación o ingrese una nueva dirección"]}
              </Typography>
              :null}
              <br />
              <Autocomplete
                style={{ width: '100%' }}
                id="google-map-demo"
                sx={{ width: 300 }}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={this.state.locOptions}
                loading={true}
                loadingText={strings['Buscando direcciones']}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={this.state.autoLoc}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  this.setState({ hasClickedLoc: false });
                  if (newValue.description) {

                    Geocode.setApiKey(
                      constants.googleMapsAPI
                    );
                    Geocode.setLanguage('es');
                    Geocode.setRegion('ES');
                    Geocode.setLocationType('ROOFTOP');
                    Geocode.enableDebug();
                    Geocode.fromAddress(newValue.description).then(
                      (response) => {
                        const { lat, lng } =
                          response.results[0].geometry.location;
                        // console.log(lat, lng);
                        this.setState({
                          defaultProps: {
                            center: {
                              lat: lat,
                              lng: lng,
                            },
                            zoom: 16,
                          },
                          selectLocationData: {
                            id: this.state.selectLocationData.id
                              ? this.state.selectLocationData.id
                              : '',
                            name: this.state.selectLocationData.name
                              ? this.state.selectLocationData.name
                              : strings['mi hogar'],
                            address: this.state.selectLocationData.address
                              ? this.state.selectLocationData.address
                              : '',
                            geoAddress: newValue.description,
                            addressNotes: this.state.selectLocationData
                              .addressNotes
                              ? this.state.selectLocationData.addressNotes
                              : '',
                            latitude: lat,
                            longitude: lng,
                          },
                        });
                        this.setState({ hasClickedLoc: true });
                        this.setState({ autoLoc: newValue });
                      },
                      (error) => {
                        console.error(error);
                      }
                    );
                  }
                  // setOptions(newValue ? [newValue, ...options] : options);
                  // setValue(newValue);
                }}
                onInputChange={_.debounce(async (event, newInputValue) => {
                  // setInputValue(newInputValue);
                  const { value } = event.target;
                  if (newInputValue.length > 4) {
                    var API_KEY = constants.googleMapsAPI;
                    // var type = '(regions)';
                    var baseURL =
                      'https://watson-order.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                    var request =
                      baseURL +
                      '?input=' +
                      value +
                      '&key=' +
                      API_KEY +
                      '&sessiontoken=' +
                      _sessionToken;
                    await axios.get(request).then(async (response) => {
                      // console.log(response);
                      this.setState({ locOptions: response.data.predictions });
                    });
                  }
                },2000)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="filled-basic"
                    label={strings["Ingrese la dirección de entrega"]}
                    variant="filled"
                    style={{ width: '100%' }}
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocationOnIcon}
                            sx={{ color: 'text.secondary', mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}

                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {/* <TextField id="filled-basic" label="Enter delivery address" variant="filled" style={{width: "100%"}}
                  onChange={async (event) => {
          const { value } = event.target;
          if(value.length>4) {
            var API_KEY =
                                                "AIzaSyDG8OvakDZ2x2OonPWn2Yj_kmFlI1LUXvM";
                                                var type = '(regions)';
                                                var baseURL =
                                                'https://watson-order.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                                                var request =
                                                baseURL+'?input='+value+'&key='+API_KEY+'&sessiontoken='+_sessionToken;
                                                await axios.get(request).then(async (response) => {
                                                  console.log(response);
                                                })
                                              }
        }}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }}
          /> */}
              {this.state.hasClickedLoc ? (
                <>
                  <br />
                  <GoogleMapReact
                    defaultCenter={this.state.defaultProps.center}
                    defaultZoom={this.state.defaultProps.zoom}
                    resetBoundsOnResize={true}
                    options={{
                      fullscreenControl: false,
                      scrollwheel: false,
                      panControl: false,
                      zoomControl: false,
                      rotateControl: false,
                    }}
                    bootstrapURLKeys={{
                      key: constants.googleMapsAPI,
                    }}
                    style={{ height: '23vh' }}
                    onChange={(changeData) => {
                      // console.log(changeData);
                      this.setState({
                        defaultProps: {
                          center: {
                            lat: changeData.center.lat,
                            lng: changeData.center.lng,
                          },
                          zoom: changeData.zoom,
                        },
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: changeData.center.lat,
                          longitude: changeData.center.lng,
                        },
                      });
                    }}
                  >
                    {/* <div
        style={{  display: "flex",
  width: "50px",
  opacity: "0.5",
  height: "50px",
  backgroundColor: "green",
  borderRadius: "50%"}}
        lat={this.state.defaultCircleProps.center.lat}
          lng={this.state.defaultCircleProps.center.lng}
      /> */}
                    <LocationOnIcon
                      style={{
                        color: constants.primaryColor,
                        transform: 'translate(-50%, -100%)',
                      }}
                      fontSize={'large'}
                      lat={this.state.defaultProps.center.lat}
                      lng={this.state.defaultProps.center.lng}
                      // text={'Google Map'}
                    />
                  </GoogleMapReact>
                  <br />
                  <TextField
                    fullWidth
                    id="filled-basic"
                    label={strings["Detalles adicionales"]}
                    error={this.state.selectLocationData.addressNotes.length<=0 && this.state.addedNotes}
                    variant="filled"
                    value={this.state.selectLocationData.addressNotes}
                    onChange={(event) => {
                      this.setState({
                        addedNotes: true,
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes: event.target.value,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  />
                  <br />
                  <br />
                  <ToggleButtonGroup
                    fullWidth
                    // color="primary"
                    value={this.state.selectLocationData.name}
                    exclusive
                    onChange={(event, newAlignment) => {
                      this.setState({
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: newAlignment,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  >
                    <ToggleButton
                      value={strings["mi hogar"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Hogar"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["mi Trabajo"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Trabajo"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["otra dirección"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Visita"]}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <br />
                  <br />
                  <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                    }}
                  >
                    {strings["Cancelar"]}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ backgroundColor: constants.primaryColor }}
                    style={{
                      float: 'right',
                      color: '#ffffff',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      this.setState({addedNotes: true});
                      if(this.state.selectLocationData.addressNotes.length>0) {
                      var locationData;
    try {
      locationData = JSON.parse(
      localStorage.getItem('location')
    );
} catch(e) {
console.log(e);
}
                      if (locationData == null) {
                        locationData = [];
                      }
                      if (this.state.selectLocationData.id === '') {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        var idLoc = uuidv4();
                        locationData.push({
                          id: idLoc,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        // console.log('locationData', locationData);
                        var selected = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e){}
                        if (selected == null) {
                          selected = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      } else {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === this.state.selectLocationData.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        locationData.push({
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        var selected2 = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected2)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e) {}
                        if (selected2 == null) {
                          selected2 = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected2,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      }
                      this.setState({ addresses: locationData });
                      try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                      } catch(e) {}
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                      this.setState(
                        { canDeliverHere: false, businessData: null },
                        (newState) => {
                          handleClose();
                          window.open('/dashboard',"_self");
                          // const { id } = this.props.match.params;
                          // // this.setState({ businessData: {} });
                          // this.getCities(id);
                        }
                      );
                    } else {
                      openAlert('error', strings['Proporcione detalles adicionales']);
                    }
                    }}
                  >
                    {strings["Guardar"]}
                  </Button>
                </>
              ) : (
                <>
                  {/* <GoogleMapReact
                defaultCenter={this.state.defaultProps.center}
        defaultZoom={this.state.defaultProps.zoom}
        resetBoundsOnResize={true}
        bootstrapURLKeys={{ key: constants.googleMapsAPI }}
        style={{height: '50px'}}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text={'Google Map'}
        />
      </GoogleMapReact> */}
                  <br />
                  <List sx={{ pt: 0 }}>
                    {this.state.addresses != null &&
                    Array.isArray(this.state.addresses)
                      ? this.state.addresses.map((email, ind) => (
                          <div key={ind}>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '1px solid #e6ebf1',
                                padding: '5px 0px 5px 0px',
                                height: '75px',
                              }}
                            >
                              <div
                                style={{
                                  width: 'calc(30px)',
                                  float: 'left',
                                  padding: '18px 0px 0px 0px',
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    color: email.selected
                                      ? constants.primaryColor
                                      : '#000',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: 'calc(100% - 31px)',
                                  float: 'right',
                                }}
                              >
                                <div
                                  style={{
                                    width: 'calc(100% - 55px)',
                                    float: 'left',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    var locationData;
                                        try {
                                          locationData = JSON.parse(
                                          localStorage.getItem('location')
                                        );
                                    } catch(e) {
                                    console.log(e);
                                    }
                                    if (locationData == null) {
                                      locationData = [];
                                    }
                                    for (
                                      var i = 0;
                                      i < locationData.length;
                                      i++
                                    ) {
                                      locationData[i]['selected'] = false;
                                    }
                                    const indexOfTaskToDelete =
                                      locationData.findIndex(
                                        (task) => task.id === email.id
                                      );
                                    locationData.splice(indexOfTaskToDelete, 1);
                                    locationData.push({
                                      id: email.id,
                                      name: email.name,
                                      address: email.address,
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                      geoAddress: email.geoAddress,
                                      addressNotes: email.addressNotes,
                                      selected: true,
                                    });
                                    var selected2 = {
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                    };
                                    if (
                                      this.state.userId &&
                                      this.state.userId !== ''
                                    ) {
                                      updateDoc(
                                        doc(db, 'users', this.state.userId),
                                        {
                                          locations: locationData,
                                        }
                                      );
                                    }
                                    try {
                                    localStorage.setItem(
                                      'selected',
                                      JSON.stringify(selected2)
                                    );
                                    window.dispatchEvent(new Event("selected"));
                                    } catch(e) {}
                                    if (selected2 == null) {
                                      selected2 = { latitude: 0, longitude: 0 };
                                    }
                                    this.setState(
                                      {
                                        selectedAddress: selected2,
                                        selectLocationName: email.geoAddress,
                                      },
                                      (newState) => {
                                        handleClose();
                                        this.setState({ addresses: locationData });
                                        try {
                                    localStorage.setItem(
                                      'location',
                                      JSON.stringify(locationData)
                                    );
                                    window.dispatchEvent(new Event("location"));
                                        } catch(e) {}
                                    this.setState({
                                      canDeliverHere: false,
                                      businessData: null,
                                    });
                                    window.open('/dashboard',"_self");
                                    // handleClose();
                                    // const { id } = this.props.match.params;
                                    // // this.setState({ businessData: {} });
                                    // this.getCities(id);
                                        // console.log(
                                        //   'selectedAddress',
                                        //   this.state.selectedAddress,
                                        //   typeof this.state.selectedAddress
                                        // );
                                      }
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {email.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{ fontSize: '14px' }}
                                  >
                                    {email.geoAddress}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '13px',
                                      color: 'slategray',
                                    }}
                                  >
                                    {email.addressNotes}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    width: 'calc(54px)',
                                    float: 'right',
                                    padding: '18px 0px 0px 0px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span>
                                    <EditIcon
                                      style={{
                                        color: email.selected
                                          ? constants.primaryColor
                                          : '#000',
                                      }}
                                      onClick={() => {
                                        // this.setState({ hasClickedLoc: false });
                                        // console.log(email);
                                        if (email.latitude != null) {
                                          // const { lat, lng } = response.results[0].geometry.location;

                                          this.setState({
                                            defaultProps: {
                                              center: {
                                                lat: email.latitude,
                                                lng: email.longitude,
                                              },
                                              zoom: 16,
                                            },
                                            selectLocationData: {
                                              id: email.id,
                                              name: email.name,
                                              address: email.address,
                                              geoAddress: email.geoAddress,
                                              addressNotes: email.addressNotes,
                                              latitude: email.latitude,
                                              longitude: email.longitude,
                                            },
                                          });
                                          this.setState({
                                            autoLoc: email.geoAddress,
                                          });
                                          this.setState({
                                            hasClickedLoc: true,
                                          });
                                        }
                                        // setOptions(newValue ? [newValue, ...options] : options);
                                        // setValue(newValue);
                                        // );
                                      }}
                                    />
                                    &nbsp;
                                    {email.selected ? null : (
                                      <DeleteForeverIcon
                                      onClick={
                                        ()=>{
                                          var locationData = this.state.addresses;
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === email.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        this.setState({ addresses: locationData });
                        try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                        } catch(e) {}
                                        }
                                      }
                                        style={{ color: '#e53837' }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          // <ListItem button>
                          //   <ListItemAvatar>
                          //     <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                          //       <PersonIcon />
                          //     </Avatar>
                          //   </ListItemAvatar>
                          //   <ListItemText primary={email.geoAddress} />
                          // </ListItem>
                        ))
                      : null}

                    {/* <ListItem autoFocus button>
                      <ListItemAvatar>
                        <Avatar>
                          <AddIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Add account" />
                    </ListItem> */}
                  </List>

                  <Typography
                    gutterBottom
                    align="center"
                    style={{ color: '#626a71', margin: '0' }}
                  >
                    {strings["o"]}
                  </Typography>
                  <br />
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      startIcon={<MyLocationIcon />}
                      disableElevation
                      align="center"
                      style={{
                        backgroundColor: constants.primaryColor,
                        textTransform: 'none',
                        height: '61px',
                        borderRadius: '12px',
                        boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px',
                        fontSize: '16px',
                      }}
                      onClick={async () => {
                        await navigator.geolocation.getCurrentPosition(
                          (position) => {
                            // console.log(position.coords.latitude);
                            this.setState({ hasClickedLoc: false });
                            if (position.coords.latitude != null) {
                              Geocode.setApiKey(
                                constants.googleMapsAPI
                              );
                              Geocode.setLanguage('es');
                              Geocode.setRegion('ES');
                              Geocode.setLocationType('ROOFTOP');
                              Geocode.enableDebug();
                              Geocode.fromLatLng(
                                position.coords.latitude,
                                position.coords.longitude
                              ).then(
                                (response) => {
                                  // const { lat, lng } = response.results[0].geometry.location;
                                  // console.log(lat, lng);
                                  this.setState({
                                    defaultProps: {
                                      center: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                      },
                                      zoom: 16,
                                    },
                                    selectLocationData: {
                                      id: this.state.selectLocationData.id
                                        ? this.state.selectLocationData.id
                                        : '',
                                      name: this.state.selectLocationData.name
                                        ? this.state.selectLocationData.name
                                        : strings['mi hogar'],
                                      address: this.state.selectLocationData
                                        .address
                                        ? this.state.selectLocationData.address
                                        : '',
                                      geoAddress:
                                        response.results[0].formatted_address,
                                      addressNotes: this.state
                                        .selectLocationData.addressNotes
                                        ? this.state.selectLocationData
                                            .addressNotes
                                        : '',
                                      latitude: position.coords.latitude,
                                      longitude: position.coords.longitude,
                                    },
                                  });
                                  this.setState({ hasClickedLoc: true });
                                  this.setState({
                                    autoLoc:
                                      response.results[0].formatted_address,
                                  });
                                },
                                (error) => {
                                  console.error(error);
                                }
                              );
                            }
                            // setOptions(newValue ? [newValue, ...options] : options);
                            // setValue(newValue);
                          },
                          (err) => console.log(err)
                        );
                      }}
                    >
                      {strings["Usar mi ubicación actual"]}
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          }
        />
        <MyCardsDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openCards}
          onClose={handleCardClose}
          displayList={
            <DialogContent dividers>
              <>
                                              {this.state.cards.map((items, t) => (
                                                <div style={{padding: "8px"}}>
                      <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                      <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="space-between"
                              alignItems="center"
                >
                  <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="flex-start"
                              alignItems="center"
                              style={{padding: "15px 15px 15px"}}
                >
                   <img
                                style={{
                                  width: '25px',
                                  height:"auto",
                                  border: '0',
                                  marginRight: "15px"
                                }}

                                src={(items['card']['brand']
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes('visa'))
                                                  ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fvisa.png?alt=media&token=1925130f-c1f1-40cf-96ce-07b6d12fb826"
                                                  : (items['card']
                                                              ['brand']
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes('master'))
                                                      ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fmastercard.png?alt=media&token=aed207cf-4abf-4617-9554-c7e4edb66d66"
                                                      : (items['card']
                                                                  ['brand']
                                                              .toString()
                                                              .toLowerCase()
                                                              .includes(
                                                                  'discover'))
                                                          ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiscover.png?alt=media&token=3321b25f-44d1-429a-916a-b10b7bf3fdc0"
                                                          : (items
                                                                          ['card']
                                                                      ['brand']
                                                                  .toString()
                                                                  .toLowerCase()
                                                                  .includes('diners'))
                                                              ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiners.png?alt=media&token=c08b27e3-a721-4fc0-9819-657716d471f4"
                                                              :"https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png"}
                              />
                        <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      width: `${this.state.width > 600 ? 300: this.state.width - 175}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',

                    }}
                  >
                    {"  "+strings['cardData241']+items['card']['last4']
                                                  .toString()}
                  </Typography></Stack>
                 { (this.state.userDetails?.subscription == null ||
                                      this.state.userDetails?.subscription['default_payment_method'] ==
                                          null ||
                                      (this.state.userDetails?.subscription['status'] !== 'active' &&
                                      this.state.userDetails?.subscription['status'] !==
                                              'trialing') ||
                                      items['id'] !==
                                      this.state.userDetails?.subscription[
                                              'default_payment_method'])? <DeleteForeverIcon
                  onClick={
                    async()=>{
                      this.setState({
                              loading: true
                            });
                      var temp = this.state.cards;
                      var deletedCardId = items['id'];
                      // var index = temp.indexOf(items);
                      if(t>=0) {
                        temp.splice(t,1);
                      }
                      // console.log(temp, t);
                            this.setState({
                              cards: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              cards:temp,
            });
            try {
              await axios
                                      .post(
                                        'https://watson-order.herokuapp.com/drivers/detachcustomer/',
                                        null,
                                        {
                                          params: {
                                            id: deletedCardId,
                                          },
                                        }
                                      )
                                      .then(async (response) => {
                                        this.setState({ loading: false });
                                        // console.log(response);
                                      })
                                      .catch((e) => {
                                        this.setState({ loading: false });
                                      });

            } catch(e) {
              this.setState({ loading: false });
            }
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />:null }
                  </Stack></div></div>
                                              ))}
                                            </>
                                            <>
                    {/* {(this.state.businessData && !this.state.businessData.cashOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(0))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                          onClick={()=>{
                            handleClickAddCardOpen();
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Agregar nueva tarjeta2"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null} */}
                  </>
            </DialogContent>
          }
        />
                <RegisterDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openRegister}
          onClose={handleCloseRegister}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '35px',
                  }}
                >
                  {strings['Bienvenido de regreso']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  {strings['Ingresa tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic45"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PhoneInput error={
                      this.state.phoneNumber.length < 9 &&
                      this.state.phoneNumber.length > 0
                    } country={'es'} onChange={(value)=>{
  //  this.setState({
  //     phone: value
  //  });
   console.log(value);
   this.setState({ phoneNumber: value });
}}/>
                {/* <CountrySelect
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 9 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 9 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                  />
                </FormControl> */}
              </Box>

              <br />
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor, }}
                fullWidth
                variant="contained"
                sx={{backgroundColor: constants.primaryColor}}
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6 &&
                    this.state.phoneNumber.length >= 9 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ loading: true });
                    createUserWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then(async (userCredential) => {
                        const user = userCredential.user;
                        const uid = user.uid;

                        var nameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var insertNameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var i = 0;
                        var dataInsert = '';
                        while (true) {
                          if (i === nameSearch.length) {
                            break;
                          }
                          if (dataInsert === '') {
                            dataInsert = nameSearch[i];
                          } else {
                            dataInsert = dataInsert + ' ' + nameSearch[i];
                            insertNameSearch.push(dataInsert);
                          }
                          i = i + 1;
                        }
                        await setDoc(doc(db, 'users', uid), {
                          fullName: this.state.fullName,
                          nameSearch: insertNameSearch,
                          email: this.state.email,
                          fromNew: true,
                          phoneNumber:
                            this.state.phoneCountry + this.state.phoneNumber,
                          loginType: 'Email/Password',
                          needcount: true,
                          orders: {
                            '0-2021': 0,
                            '1-2021': 0,
                            '2-2021': 0,
                            '3-2021': 0,
                            '4-2021': 0,
                            '5-2021': 0,
                            '6-2021': 0,
                            '7-2021': 0,
                            '8-2021': 0,
                            '9-2021': 0,
                            '10-2021': 0,
                            '11-2021': 0,
                          },
                          isSignedIn: false,
                          lastLogin: null,
                          lastOrder: null,
                          isTestUser: false,
                          phoneNumberVerified: true,
        });

                        // sendOTP(
                        //   this.state.phoneCountry + this.state.phoneNumber
                        // );
                        this.setState({ loading: false, openRegister: false });
                        this.setState({
                          openAlert: true,
                          alertType: 'success',
                          alertMsg: strings['Datos correctos'],
                        });
                        this.setState({
                          userId: uid,
                          userDetails: {
                            fullName: this.state.fullName,
                            nameSearch: insertNameSearch,
                            email: this.state.email,
                            fromNew: true,
                            phoneNumber:
                              this.state.phoneCountry + this.state.phoneNumber,
                            loginType: 'Email/Password',
                            orders: {
                              '0-2021': 0,
                              '1-2021': 0,
                              '2-2021': 0,
                              '3-2021': 0,
                              '4-2021': 0,
                              '5-2021': 0,
                              '6-2021': 0,
                              '7-2021': 0,
                              '8-2021': 0,
                              '9-2021': 0,
                              '10-2021': 0,
                              '11-2021': 0,
                            },
                            isSignedIn: false,
                            lastLogin: null,
                            lastOrder: null,
                            isTestUser: false,
                            phoneNumberVerified: true,
                          },
                          cards: [],
                        });
                        // handleClickOpenOTP();
                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        if(errorCode !== 'auth/email-already-in-use')
                        openAlert(
                          'error',
                          strings['Existe un error en el registro']
                        );
                        else {
                          openAlert(
                          'error',
                          strings['La identificación de correo electrónico ya está en uso']
                        );
                        }
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Crear cuenta']}
              </Button>
            </DialogContent>
          }
        />
        <LoginDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLogin}
          onClose={handleCloseLogin}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '65px',
                  }}
                >
                  {strings['Bienvenido']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '35px',
                  }}
                >
                  {strings['Crea tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Typography
                align="center"
                onClick={async ()=>{
                  if (
                    validator.isEmail(this.state.email)
                  ) {
                    this.setState({ loading: true });
                  await sendPasswordResetEmail(
                    auth,
                      this.state.email,
                  ).then((userCredential) => {
                    this.setState({ loading: false });
                    openAlert('success', strings['Restablecer instrucciones enviadas al correo electrónico ingresado']);
                  }).catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        openAlert('error', strings['No puedo encontrar el correo electrónico o algo salió mal.']);
                        // console.log(errorMessage, errorCode);
                      });
                } else {
                  openAlert('error', strings['Por favor ingrese un correo electrónico válido primero']);
                }
                }}
                style={{ marginBottom: '0', fontWeight: '700', fontSize: "16px", cursor: "pointer" }}
              >
                {strings['¿Olvidaste tu contraseña?']}
              </Typography>
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor, }}
              sx={{ backgroundColor: constants.primaryColor }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6
                  ) {
                    this.setState({ loading: true });
                    signInWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then((userCredential) => {
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorMessage, errorCode);
                        signInWithEmailAndPassword(
                      auth,
                      this.state.email.trim(),
                      'watsonwatson'
                    )
                      .then(async(userCredential) => {

                        await updatePassword(
                          userCredential.user,
                          this.state.password
                        )
                          .then((res) => {
                            // console.log('Result after updating password', res);
                          });
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error2) => {
                        const errorCode = error2.code;
                        const errorMessage = error2.message;
                        this.setState({ loading: false });
                        openAlert('error', 'Los datos ingresados no son correctos');
                        console.log(errorMessage, errorCode);
                      });
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Iniciar sesión']}
              </Button>
              <Typography
                gutterBottom
                align="center"
                style={{ marginTop: '8px' }}
              >
                {strings['o']}
              </Typography>
              <Button style={{ background: 'none', boxShadow: 'none', color: constants.primaryColor, textTransform: 'inherit', fontSize: '18px', }}
              sx={{ backgroundColor: constants.primaryColor }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  handleCloseLogin();
                  handleClickOpenRegister();
                }}
              >
                {strings['Registro']}
              </Button>
            </DialogContent>
          }
        />
<div
            style={{
              width: '100%',
              // height: isIOS
              //   ? 'calc((var(--vh, 1vh) * 100) - 60px )'
              //   : 'calc( 100vh - 60px )',
              overflow: 'hidden',
            }}
          >
          <div
              id="scroll-container"
              style={{
                width: 'calc(100%)',
                // height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                backgroundColor:  '#ffffff',
                float: 'right',
                overflow: 'auto',
              }}
            >
              <Stack
              direction="column"
              justifyContent="center"
              alignItems="inherit"
              spacing={2}
            >
            {/* Main first tittle page */}
            <div style={{backgroundImage: `url(/mainbackground.png)`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh', }}>
              <div style={{paddingTop: "80px"}}></div>
              <Typography
                 style={{
                   fontWeight: '800',
                   fontFamily: 'Nunito',
                   textTransform: 'none',
                   padding: this.state.width > 700 ? '5px 50px 0 50px' : '5px 11px 0 11px',
                   color: '#000',
                   textAlign: this.state.width > 700 ? 'left' : 'center',
                   fontSize: this.state.width > 700 ? '42px' : '28px',
                   marginTop: this.state.width > 700 ? '170px' : '190px',
                   marginBottom: this.state.width > 700 ? undefined : '8px',
                 }}
               >
                 {strings["Entregamos todo por ti"]}
               </Typography>
               <Typography
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Nunito',
                    textTransform: 'none',
                    padding: this.state.width > 700 ? '0 50px' : '0 11px',
                    textAlign: this.state.width > 700 ? 'left' : 'center',
                    color: '#000',
                    fontSize: this.state.width > 700 ? '24px' : '18px',
                    margin: '0',
                    marginBottom: this.state.width > 700 ? undefined : '28px',
                  }}
                >
                  {strings["Entregamos todo por ti subtittle"]}
                </Typography>
               <div onClick={handleClickOpen} style={{
                cursor: 'pointer',
                marginLeft: this.state.width > 700 ? '50px' : '20px',
                marginTop: this.state.width > 700 ? '30px' : undefined,
                marginBottom: this.state.width > 700 ? '135px' : '105px',
                //placeSelf: this.state.width > 700 ? undefined : 'center',
                width: this.state.width > 700 ? undefined : 'calc(100% - 40px)',
                width: `${(this.state.width<=700)?this.state.width * 0.9:this.state.width * 0.5}px`,
                backgroundColor: constants.textFieldColor,
                backgroundColor: constants.primaryColor,
                borderRadius: `${this.state.height * 50}px`,
                padding: `${(this.state.width<=700)?12:15}px`
               }}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {/* <LocationOn style={{ color: constants.primaryColor, fontSize: `${(this.state.width <= 700) ? this.state.width * 0.04 : this.state.width * 0.015}px`, }} /> */}
                  <div style={{paddingLeft: `${(this.state.width <= 700) ? 12 : 16.0}px`}}>
                    <Typography
                     style={{
                       fontWeight: '700',
                       fontFamily: 'Nunito',
                       textTransform: 'none',
                       color: constants.textFieldColor,
                       fontSize: `${(this.state.width <= 700) ? this.state.width * 0.04 : this.state.width * 0.015}px`,
                     }}
                   >
                     {strings["¡Ordenar ahora!"]}
                   </Typography>
                  </div>
                </Stack>
               </div>


             {(this.state.width > 700)?
             <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
              <div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700) ? this.state.width * 0.015 : this.state.width * 0.015}px`,}}
              >
               {strings["Fitness"]}
             </Typography>

              </div>
              <div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
              <div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Tacos"]}
             </Typography>

</div>
<div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
<div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
<Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Pizzas"]}
             </Typography>

</div>
<div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
<div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
<Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Postres"]}
             </Typography>

</div>
<div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
<div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
<Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Alitas"]}
             </Typography>

</div>
<div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
<div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
<Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Hamburguesas"]}
             </Typography>

</div>
<div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.025 : this.state.width * 0.025}px`}}></div>
<div style={{padding: `${(this.state.width <= 700) ? 16 : 16.0}px`}}>
<Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 //textShadow: '0px 0px 3px white',
                 color: '#424242',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.015
                                        : this.state.width * 0.015}px`,
               }}
             >
               {strings["Mariscos"]}
             </Typography>

</div>
</Stack>
             :null}
             </div>
             {/* Main first tittle page */}

             <div style={{paddingTop: `${(this.state.width <= 700) ? 20 : 20}px`}}></div>
             <Stack
              direction={(this.state.width <= 700)?"column":"row"}
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
            <Stack style={{ width: this.state.width > 700 ? '50%' : undefined }}
              direction='column'
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
            <img
                src={userOn1}
                alt="logo"
                style={{ width: `${(this.state.width < 720)
                                      ? this.state.width * 0.5
                                      : this.state.width * 0.18}px`, verticalAlign: 'middle',borderRadius: "20px"}}
              />
              <div style={{paddingTop: `${(this.state.width <= 700) ? 5 : 5}px`}}></div>
              <Stack
  direction='row'
  justifyContent="center"
  alignItems="center"
  spacing={0}
>
<div style={{
  width: `${(this.state.width < 720)
                                        ? (this.state.width * 0.2)
                                        : this.state.width * 0.075}px`,
  height: `${(this.state.width < 720)
                                        ? (this.state.width * 0.2)
                                        :this.state.width * 0.075}px`,
  backgroundColor: constants.textFieldColor,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px"
}}>
<FastfoodIcon style={{
  fontSize: "35px",
  color: "grey"
}} />
</div>
<div style={{
  width: `${(this.state.width < 720)
                                        ? (this.state.width * 0.6)
                                        : this.state.width * 0.2}px`,
  padding: "15px"
}}>
  <Typography
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 color: constants.textColor,
                 fontSize: `${(this.state.width <= 700)
                                        ? 17
                                        : 20}px`,
               }}
             >
               {strings["Ordena tus comidas o postres favoritos y recíbelos en menos de 1 hora"]}
             </Typography>
</div>
</Stack>
            </Stack>
            <Stack style={{ width: this.state.width > 700 ? '50%' : undefined }}
              direction='column'
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
            <img
                src={userOn2}
                alt="logo"
                style={{ width: `${(this.state.width < 720)
                                      ? this.state.width * 0.5
                                      : this.state.width * 0.18}px`, verticalAlign: 'middle',borderRadius: "20px"}}
              />
              <div style={{paddingTop: `${(this.state.width <= 700) ? 5 : 5}px`}}></div>
              <Stack
  direction='row'
  justifyContent="center"
  alignItems="center"
  spacing={0}
>
<div style={{
  width: `${(this.state.width < 720)
                                        ? (this.state.width * 0.2)
                                        : this.state.width * 0.075}px`,
  height: `${(this.state.width < 720)
                                        ? (this.state.width * 0.2)
                                        :this.state.width * 0.075}px`,
  backgroundColor: constants.textFieldColor,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px"
}}>
<ShoppingBagIcon style={{
  fontSize: "35px",
  color: "grey"
}} />
</div>
<div style={{
  width: `${(this.state.width < 720)
                                        ? (this.state.width * 0.6)
                                        : this.state.width * 0.2}px`,
  padding: "15px"
}}>
  <Typography
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 color: constants.textColor,
                 fontSize: `${(this.state.width <= 700)
                                        ? 17
                                        : 20}px`,
               }}
             >
               {strings["Encuentra tus tiendas favoritas y pide desde cientos de productos disponibles en sólo unos clics"]}
             </Typography>
            </div>
            </Stack>
            </Stack>
            </Stack>
            <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
            {(this.state.categoriesList.length>0)?
                    <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                    style={{
                      display: 'flex',
                      width: this.state.width > 700 ? 'calc(100% - 60px)' : undefined,
                      marginLeft: this.state.width > 700 ? '30px' : undefined,
                      flexShrink: 0,
                      overflowX: 'auto',
                      position: 'inherit',
                      textAlign: 'center',
                      // height: `${(this.state.width <= 700) ? 120 : 150}px`,
                      top: 0
                    }}
                  >
                    {/* <div   > */}
                    {/* <ul style={{ listStyle: 'none' }}> */}
                    {this.state.categoriesList.map((item, index) => (
                      <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                    style={{
                      padding: "10px"
                     }}
                  >
                    <div style={{
                      width: `${(this.state.width <= 700) ? 70 : 85}px`,
                      height: `${(this.state.width <= 700) ? 70 : 85}px`,
                      color: constants.textFieldColor,
                      borderRadius: `${(this.state.width <= 700) ? 10 : 20}px`,
                      boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                      padding: "4px"
                     }}>
                      <img
                src={require(`./assets/category/${item.image}`)}
                alt="logo"
                style={{ borderRadius: `${(this.state.width <= 700) ? 9 : 16}px`, objectFit: "cover", height: `${(this.state.width <= 700) ? 70 : 77}px`,width: `${(this.state.width <= 700) ? 70 : 77}px`}}
              />
                     </div>
                     <div style={{paddingTop: `${(this.state.width <= 700) ? 8 : 10}px`}}></div>
                     <Typography
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 color: constants.textColor,
                 whiteSpace: 'pre',
                 fontSize: `${(this.state.width <= 700)
                                        ? this.state.width * 0.025
                                        : 17}px`,
               }}
             >
               {item.name}
             </Typography>
                  </Stack>
                    ))}
                  </Stack>: null}
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 100 : 120}px`}}></div>
                  <div style={{margin: "0px 20px", width: `${(this.state.width <= 700) ? this.state.width - 40 : this.state.width * 0.5}px`, padding: "8px", placeSelf: 'center', }}>
                    <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                  >
                    <img
                src={mainLogo2}
                alt="logo"
                style={{ borderRadius: `${(this.state.width <= 700) ? 16 : 16}px`,width: `${(this.state.width <= 700) ? this.state.width * 0.125 : 85}px`}}
              />
              <div style={{paddingLeft: "10px"}}></div>
              <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                  >
                    <div style={{width: `${(this.state.width <= 700)
                                        ? this.state.width -
                                            (10 + 40 + 16) -
                                            (this.state.width * 0.125)
                                        : this.state.width * 0.33}px`}}>
                                          <Typography
               style={{
                 fontFamily: 'Nunito',
                 fontWeight: 500,
                 textTransform: 'none',
                 color: constants.textColor,
                 textAlign: "center",
                 fontSize: this.state.width > 700 ? '20px' : '18px',
               }}
             >
               {strings['Descarga la app y conoce mucho más que te encantará']}
             </Typography>
                                        </div>
                                        <div style={{paddingTop: "10px"}}></div>
                                        <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                  >
                    <div style={{paddingTop: `${(this.state.width <= 700) ? 0 : 50}px`}}></div>
                    <div style={{padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.03 : 20}px`}}>
                    <div onClick={()=>{
              window.open(
                          constants.appleAppLink,
                          '_blank'
                        );
             }} style={{cursor: 'pointer',height: "45px", width: `${(this.state.width < 720) ? this.state.width * 0.35 : this.state.width * 0.15}px`, backgroundColor: constants.primaryColor, borderRadius: `${this.state.height * 50}px`,padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.015 : 10}px` }}>
                     <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                    style={{height: "45px",}}
                  >
                    <AppleIcon style={{ fontSize: this.state.width > 700 ? '20px' : '18px', color: constants.textFieldColor}} />
                    <Typography
                     style={{
                       fontFamily: 'Nunito',
                       fontWeight: 500,
                       padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.01 : 10}px`,
                       textTransform: 'none',
                       color: constants.textFieldColor,
                       textAlign: "center",
                       fontSize: this.state.width > 700 ? '18px' : '16px',
                     }}
                   >
                     {"iPhone"}
             </Typography>
                    </Stack>
</div>
                    </div>
                    <div style={{padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.03 : 20}px`}}>
                    <div onClick={()=>{
              window.open(
                          constants.androidAppLink,
                          '_blank'
                        );
             }} style={{cursor: 'pointer',height: "45px", width: `${(this.state.width < 720)
                                                ? this.state.width * 0.35
                                                : this.state.width * 0.15}px`, backgroundColor: constants.primaryColor, borderRadius: `${this.state.height * 50}px`,padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.015 : 10}px` }}>
                     <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                    style={{height: "45px",}}
                  >
                    <AndroidIcon style={{ fontSize: this.state.width > 700 ? '20px' : '18px', color: constants.textFieldColor }} />
                    <Typography
                       style={{
                         fontFamily: 'Nunito',
                         fontWeight: 500,
                         padding: `0px ${(this.state.width <= 700) ? this.state.width * 0.01 : 10}px`,
                         textTransform: 'none',
                         color: constants.textFieldColor,
                         textAlign: "center",
                         fontSize: this.state.width > 700 ? '18px' : '16px',
                       }}
                     >
                       {"Android"}
                     </Typography>
                    </Stack>
</div>
                    </div>
                  </Stack>
                  </Stack>
                  </Stack>
                  </div>
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 80 : 100}px`}}></div>
                  <div style={{display: this.state.width > 700 ? 'flex' : 'block', }}>
                    <div style={{width: this.state.width > 700 ? '50%': 'calc(100% - 40px)', height: '190px', border: '1px solid #e6ebf1', alignItems: 'center', borderRadius: '8px', margin: '0px 20px 20px 20px', }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         fontWeight: '800',
                         textAlign: 'center',
                         textTransform: 'none',
                         marginBottom: '15px',
                         marginTop: '5px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '24px' : '18px',
                       }}
                      >
                      {strings["Registra tu restaurante"]}
                      </Typography>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textAlign: 'center',
                         textTransform: 'none',
                         padding: '0 7px',
                         marginBottom: '20px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '15px' : '15px',
                       }}
                      >
                      {strings["Registra tu restaurante o negocio2"]}
                      </Typography>
                      <div onClick={()=>{ window.open(constants.businessUrl, '_blank');}}
                        style={{alignItems: "center",
                          cursor: 'pointer',
                          display: "flex",
                          margin: '0px 20px',
                          justifyContent: "center",
                          //width: `${(this.state.width <= 700) ? this.state.width - 40 : this.state.width * 0.35}px`,
                          backgroundColor: constants.primaryColor,
                          borderRadius: `${this.state.height * 50}px`,
                          padding: "15px",
                        }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textTransform: 'none',
                         fontWeight: '800',
                         color: constants.textFieldColor,
                         fontSize: this.state.width > 700 ? '16px' : '15px',
                       }}
                      >
                      {strings["Registra tu restaurante o negocio"]}
                      </Typography>
                      </div>
                    </div>
                    <div style={{width: this.state.width > 700 ? '50%': 'calc(100% - 40px)', height: '190px', border: '1px solid #e6ebf1', alignItems: 'center', borderRadius: '8px', margin: '0px 20px 20px 20px', }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         fontWeight: '800',
                         textAlign: 'center',
                         textTransform: 'none',
                         marginBottom: '15px',
                         marginTop: '5px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '24px' : '18px',
                       }}
                      >
                      {strings["Más sobre nosotros"]}
                      </Typography>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textAlign: 'center',
                         textTransform: 'none',
                         padding: '0 7px',
                         marginBottom: '20px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '15px' : '15px',
                       }}
                      >
                      {strings["Más sobre nosotros2"]}
                      </Typography>
                      <div onClick={()=>{ window.open(constants.aboutUsAppLink, '_blank');}}
                        style={{alignItems: "center",
                          cursor: 'pointer',
                          display: "flex",
                          margin: '0px 20px',
                          justifyContent: "center",
                          //width: `${(this.state.width <= 700) ? this.state.width - 40 : this.state.width * 0.35}px`,
                          backgroundColor: constants.primaryColor,
                          borderRadius: `${this.state.height * 50}px`,
                          padding: "15px",
                        }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textTransform: 'none',
                         fontWeight: '800',
                         color: constants.textFieldColor,
                         fontSize: this.state.width > 700 ? '16px' : '15px',
                       }}
                      >
                      {strings["mas sobre nosotros"]}
                      </Typography>
                      </div>
                    </div>
                    {/* <div style={{width: this.state.width > 700 ? '33.3333%': 'calc(100% - 40px)', height: '190px', border: '1px solid #e6ebf1', alignItems: 'center', borderRadius: '8px', margin: '0px 20px 20px 20px', }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         fontWeight: '800',
                         textAlign: 'center',
                         textTransform: 'none',
                         marginBottom: '15px',
                         marginTop: '5px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '24px' : '18px',
                       }}
                      >
                      {strings["Ingresa como Repartidor"]}
                      </Typography>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textAlign: 'center',
                         padding: '0 7px',
                         textTransform: 'none',
                         marginBottom: '20px',
                         color: constants.textColor,
                         fontSize: this.state.width > 700 ? '15px' : '15px',
                       }}
                      >
                      {strings["Ingresa como Repartidor2"]}
                      </Typography>
                      <div onClick={()=>{ window.open(constants.driverUrl, '_blank');}}
                        style={{alignItems: "center",
                          cursor: 'pointer',
                          display: "flex",
                          margin: '0px 20px',
                          justifyContent: "center",
                          //width: `${(this.state.width <= 700) ? this.state.width - 40 : this.state.width * 0.35}px`,
                          backgroundColor: constants.primaryColor,
                          borderRadius: `${this.state.height * 50}px`,
                          padding: "15px",
                        }}>
                      <Typography
                       style={{
                         fontFamily: 'Nunito',
                         textTransform: 'none',
                         fontWeight: '800',
                         color: constants.textFieldColor,
                         fontSize: this.state.width > 700 ? '16px' : '15px',
                       }}
                      >
                      {strings["Registrate como repartidor"]}
                      </Typography>
                      </div>
                    </div> */}
                  </div>
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 80 : 100}px`}}></div>
                  <div style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}
                  >
                    <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
                    <Stack
                    direction={(this.state.width <= 700)?'column': 'row'}
                    justifyContent="space-evenly"
                    alignItems="center"
                    style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}
                    spacing={0}
                  >
                    <Stack
                    direction='column'
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                  >
                    <img
                src={mainLogo}
                alt="logo"
                style={{ width: `100px`, margin: "10px"}}
              />
              <Typography
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 color: constants.textColor,
                 fontSize: `16px`,
               }}
             >
               {strings["Síguenos y obtén promociones exclusivas"]}
             </Typography>
             <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
             <Stack
                    direction='row'
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                  >
                    <TwitterIcon onClick={()=>{
              window.open(
                          constants.aboutUsFacebook,
                          '_blank'
                        );
             }} style={{margin: "10px", fontSize: "40px",cursor: 'pointer', color: constants.textColor}} />
                    <div style={{paddingLeft: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
                    <InstagramIcon onClick={()=>{
              window.open(
                          constants.aboutUsInstagram,
                          '_blank'
                        );
             }} style={{margin: "10px", fontSize: "40px",cursor: 'pointer', color: constants.textColor}} />
                  </Stack>
                  </Stack>
                  <Stack
                    direction='column'
                    justifyContent="space-between"
                    alignItems="start"
                    spacing={0}
                  >
                    <Typography
                    onClick={()=>{
              window.open(
                          constants.aboutUsAppLink,
                          '_blank'
                        );
             }}
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 cursor: 'pointer',
                 color: constants.textColor,
                 fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
               }}
             >
               {strings["Más sobre nosotros"]}
             </Typography>
             <div style={{paddingTop: `${(this.state.width <= 700) ? 25 : 25}px`}}></div>
             <Typography
             onClick={()=>{
              window.open(
                          constants.businessUrl,
                          '_blank'
                        );
             }}
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 cursor: 'pointer',
                 color: constants.textColor,
                 fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
               }}
             >
               {strings["Registra tu restaurante"]}
             </Typography>
             <div style={{paddingTop: `${(this.state.width <= 700) ? 25 : 25}px`}}></div>
             {/* <Typography
             onClick={()=>{
              window.open(
                          constants.driverUrl,
                          '_blank'
                        );
             }}
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 color: constants.textColor,
                 cursor: 'pointer',
                 fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
               }}
             >
               {strings["Ingresa como Repartidor"]}
             </Typography> */}
                  </Stack>
                  <Stack
                    direction='column'
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                  >
                    <Typography
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 fontWeight: 700,
                 color: constants.textColor,
                 fontSize: `${(this.state.width < 720) ? 16 : 18}px`,
               }}
             >
               {strings["Descarga la app"]}
             </Typography>
             <img
             onClick={()=>{
              window.open(
                          constants.appleAppLink,
                          '_blank'
                        );
             }}
                src={Dapple}
                alt="logo"
                style={{ width: `135px`, margin: "5px 10px",cursor: 'pointer',}}
              />
              <img
              onClick={()=>{
              window.open(
                          constants.androidAppLink,
                          '_blank'
                        );
             }}
                src={Dandroid}
                alt="logo"
                style={{ width: `135px`, margin: "5px 10px",cursor: 'pointer',}}
              />
                  </Stack>
                  </Stack>
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
                  <Divider
                                            style={{
                                              width: `${this.state.width}px`,
                                              padding: "0px 8px",
                                              borderBottomWidth: '3px',
                                              backgroundColor: constants.primaryColor,
                                            }}
                                          />
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
                  <Stack
                    direction='row'
                    style={{width: `${this.state.width}px`}}
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                  >
                    <Typography
                    onClick={()=>{
              window.open(
                          constants.privacyUrl,
                          '_blank'
                        );
             }}
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 cursor: 'pointer',
                 fontWeight: 400,
                 color: 'slategrey',
                 fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
               }}
             >
               {strings["Politica de privacidad"]}
             </Typography>
             <div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.035 : 20}px`}}></div>
             <Typography
             onClick={()=>{
              window.open(
                          constants.aboutUsTerms,
                          '_blank'
                        );
             }}
               style={{
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 cursor: 'pointer',
                 fontWeight: 400,
                 color: 'slategrey',
                 fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
               }}
             >
               {strings["Términos"]}
             </Typography>
             <div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.035 : 20}px`}}></div>
                  </Stack>
                  <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
                  </Stack>
                  </div>
              </Stack>
              </div></div>
  </>
  );
  }
}

export default Main;
