import { strings } from "../translations";

export const constants = {
 appName : 'Watson',
 appNameSmall : 'watson',
 appNameCaps : 'WATSON',
 appDesc : strings['appDesc'],
 aboutUsTerms : 'https://thewatsonapp.com/terminos-condiciones',
 herokuOrderApp : 'https://watson-order.herokuapp.com',
 herokuOrderAppHttp : 'watson-order.herokuapp.com',
 clubMaximumPrice : 35.00,
 clubPrice : 139.00,
 clubMinimumPrice : 189.00,
 clubTrialTimeDays : 7,
 referralTotalAmount : 50.00,
 referralFriendAmount : 50.00,
 referralFirstAmount : 25.00,
 referralSecondAmount : 25.00,
 referralThirdAmount : 0.00,
 referralForthAmount : 0.00,
 referralFifthAmount : 0.00,
 referralRemainingAmount : 25.00,
 serverKey : 'AAAAyHQaan8:APA91bEC7NugKyh3Cv0ikCgOTjKAr-AQMzC61ittLTH09CtrPjAApwS098Wsv43KGNVQsLGerECJz_p8QwR85PdBBvh0NxF6e6kW7_jAj6bjCySdgesoAsuQpiIzUkt-lTWUkq2DaJfX',
 paypalClientId :
     'ARst12ApGFA1iqMFMXELdy0SyY2AZREk-1g1wOGw1c9r7hwXHFvcjeQ3vgpCWRw3Vf45toID1ow3WYC4',
 paypalSecret :
     'ECpk_P7JdNA_Rjg18INAAuQn0FaMAWfz-eBea7QEbUMXMHms-TvIkr5kXLHG-N6j0eohqR-Jt8XyOiBO',
 stripeAccountId : 'acct_1Alln7Dl9XYpSi4F',
 stripePublic : 'pk_live_lBuAacfW60cByOCdB719LpCW',
  googleMapsAPI : 'AIzaSyDDfJ6vj0CCkefXdOrwgQf67WkI1Es4Ft8',
 aboutUsAppLink : 'https://thewatsonapp.com',
 aboutUsFacebook : 'https://twitter.com/appwatson',
 aboutUsInstagram : 'https://www.instagram.com/watsonapp',
//  welcome1Title : 'welcome1Title'.tr(),
//  welcome1SubTitle : 'welcome1SubTitle'.tr(),
//  welcome2Title : 'welcome2Title'.tr(),
//  welcome2SubTitle : 'welcome2SubTitle'.tr(args: [appName]),
//  welcome3Title : 'welcome3Title'.tr(args: [appName]),
//  welcome3SubTitle : 'welcome3SubTitle'.tr(),
 orderSupport : 'soporte@watson.app',
 appDomain : 'www.watson.app',
 businessUrl : 'https://thewatsonapp.com',
 driverUrl : 'https://thewatsonapp.com',
 appleAppLink : '',
 androidAppLink : '',
 privacyUrl : 'https://thewatsonapp.com/terminos-condiciones/',
 userAppDomain : 'https://negocios.watson.app',
 herokuBusinessApp : 'https://watson-business.herokuapp.com',
 stripePICust :
    'https://us-central1-watson-79114.cloudfunctions.net/StripePICust',
 firebaseLogo :
    'https://firebasestorage.googleapis.com/v0/b/watson-79114.appspot.com/o/applogo.png?alt=media&token=9307640a-8e71-422d-a873-5775177024b9',
primaryColor : '#44cf8e',
secondaryColor : '#272727',
backgroundColor : '#fff',
buttonColor : '#000000',
iconColor : '#9f9fad',
circleColor : '#000000',
textFieldColor : '#f8f9fb',
textColor : '#121212',
shadowColor : '#9E9E9E',
 spinGamePrizeCaps : strings['spinGamePrizeCaps'],
 spinGamePrizeSmall : strings['spinGamePrizeSmall'],
 jackpotGamePrize : 30.00,
 scratchGamePrize : 10.00,
 storeName : 'Oxxo',
 storeAdditional : 9.00,
}
