import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyD7Js0J0BKgpZrLiSiNCtHIuOMC-LB04Js",
//   authDomain: "watson-79114.firebaseapp.com",
//   projectId: "watson-79114",
//   storageBucket: "watson-79114.appspot.com",
//   messagingSenderId: "860941347455",
//   appId: "1:860941347455:web:953110ae8b7e0f03896d4a",
//   measurementId: "G-3SVD2TR4RS"
//   Cafi maps apiKey: "AIzaSyBdevUaSIrwQRLevqytuKMudb7q7PbYMd4",
// };

const firebaseConfig = {
  apiKey: "AIzaSyD7Js0J0BKgpZrLiSiNCtHIuOMC-LB04Js",
  authDomain: "watson-79114.firebaseapp.com",
  projectId: "watson-79114",
  storageBucket: "watson-79114.appspot.com",
  messagingSenderId: "860941347455",
  appId: "1:860941347455:web:953110ae8b7e0f03896d4a",
  measurementId: "G-3SVD2TR4RS"
};

  export const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);
  export const auth = getAuth(app);
  export const storage = getStorage(app);
