// ES6 module syntax
import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
 en:{
   'language':"en",
  'Dom':"Sun",
  'Lun':"Mon", 'Mar':"Tue", 'Mié':"Wed", 'Jue':"Thu", 'Vie':"Fri", 'Sáb':"Sat",
  "Información adicional": "Additional information",
  "Mis direcciones":"My addresses",
  "Registro":"Register",
  "OTP": "OTP",
  "negociodesactivado":"The restaurant cannot accept any more orders at this time, please try again later",
  "el día": "The day ",
  "VerifiedHover": "This business was verified meeting the highest quality standards",
  "lo antes posible": "(As soon as possible)",
  "Teléfono":"Phone:",
  "Selecciona tu método de pago":"Select payment method",
  "Promociones": "Promotions",
  "Pedido programado para":"Order set for",
  "Ingresar":"Login",
  "Progamar pedido": "Preorder",
  "Progamar pedido2": "Preorder",
  "¿Un último antojo?":"One last product?",
  "Bienvenido": "Welcome",
  "Bienvenido de regreso": "Welcome back",
  "Crea tu cuenta para continuar":"Create an account to continue",
  "Ingresa tu cuenta para continuar":"Enter your credentials to continue",
  "closed_today":"Close",
  "Ver pedido": "View basket",
  "Inicio de sesión exitoso":"Successful login",
  "Error de inicio de sesión":"Login error",
  "Esperando a que el negocio acepte su pedido": "Waiting for the business to accept your order",
  "El negocio está preparando su producto.": "The business is preparing its product.",
  " está en camino para recoger su pedido":" is on its way to pick up your order",
  " llegó al negocio":" reached business",
  " ha recogido tu pedido":" has picked up your order",
  "Su orden ha sido completada":"Your order has been completed",
  "Su pedido ha sido cancelado": "Your order has been canceled",
  "Iniciar sesión":"Log in",
  "Registrarse": "Register",
  "Perfil": "Profile",
  "Agregar €":"Add €",
  "Mis Pedidos": "My orders",
  "Cerrar sesión": "Log out",
  "Hogar": "Home",
  "Su cupón ha sido agregado con éxito.": "Your coupon has been successfully added.",
  "Añade al menos ":"Add atleast ",
  " productos promocionales.": " promotional products.",
  "Los productos máximos deben ser €":"Max products should be €",
  "Los productos mínimos deben ser €": "Minimum products must be €",
  "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"This coupon has been used recently, please try again later.",
  " Costo de envío: ":" Delivery fee ",
  "envio":"Delivery",
  " Descuento: ": " Discount: ",
  "Seleccionar mi ubicación": "Select my location",
  "Buscar producto": "Search product",
  "ID del pedido: ": "Order ID: ",
  "Obligatorio" :"Mandatory, choose ",
  "Obligatorio2" :"Necessary",
  "Selecciona al menos ": "Select at least ",
  "Selecciona de ": "Select from ",
  ".Hasta ": ".Till ",
  "Selecciona hasta ": "Select up to ",
  "Email": "Email",
  "Tu repartidor":"Your driver",
  "Contraseña":"Password",
  "o":"or",
  "Nombre completo": "Full name",
  "Número de teléfono":"Phone number",
  "Datos correctos":"Data added correctly",
  "Existe un error en el registro":"There is an error in the registration",
  "Crear cuenta": "Create account",
  "Cambiar número Éxito":"Change number Success",
  "Reenviar":"Resend",
  "Mensaje enviado correctamente al número anterior":"Message successfully sent to previous number",
  "Has ingresado correctamente":"You have entered correctly",
  "Envío OTP con éxito":"OTP Send Successfully",
  "El código ingresado no es correcto, intenta nuevamente":"The code entered is not correct, please try again",
  "Calificación:":"Rating:",
  "Correo:":"Email:",
  "Dirección:":"Address:",
  "Selecciona tu ubicación o ingrese una nueva dirección":"Select your location or enter a new address",
  "Selecciona tu ubicación o ingrese una nueva dirección2":"We want to make sure we have coverage in your area",
  "Selecciona tu ubicación o ingrese una nueva dirección4":"Please add your address",
  "Ingrese la dirección de entrega":"Enter the delivery address",
  "Detalles adicionales":"Floor, door or extra details",
  "mi hogar":"my home",
  "mi Trabajo":"my work",
  "otra dirección":"a visit address",
  "Trabajo":"Work",
  "Visita":"Visit",
  "Cancelar":"Cancel",
  "Guardar":"Save",
  "Usar mi ubicación actual":"Use my current location",
  "Agregar nueva tarjeta":"Card payment",
  "Pago en efectivo":"Cash payment",
  "Tarjeta a la entrega":"Card on delivery",
  ". Ver detalles":". See details",
  ". Menos detalles":". Less details",
  "Comprar ":"Buy ",
  " productos y pago ":" products and pay for ",
  " en productos seleccionados":" on selected products",
  "Productos promocionados:":"Promoted Products:",
  "Se eliminará la tarifa de envío":"Delivery fee will be removed",
  "El número mínimo de productos debe ser €":"The minimum total of products must be €",
  "El número máximo de productos debe ser €":"The maximum total of products must be €",
  "Descuento €":"Discount €",
  "Obtener €":"Get €",
  " al final del día en Wallet":" at the end of the day in Wallet",
  "Obtener ":"Get ",
  "% de cashback sobre el total de tus productos en Wallet":"% of cashback on the total of your products in Wallet",
  "% de descuento en todos sus productos.":"% discount on all your products.",
  "Consigue un descuento mínimo de €":"Get a minimum discount of €",
  "Sube hasta €":"Up to €",
  " descuento":" discount",
  "Aplicar":"Apply",
  "Lo sentimos, no hay descuentos disponibles en este momento.":"Sorry, there are no discounts available at this time.",
  "Entrega de:":"Delivery",
  "Recoger":"Pickup in",
  "Recoger en:":"Pick up in",
  "min aprox":"min approx",
  "costo de envío: €":"Delivery fee",
  "envio":"Delivery",
  "recoger en":"Pickup un in",
  "entrega en":"Delivery in",
  "Distancia":"Distance",
  " ¡Promociones! ":" Promotions! ",
  "Para recoger tu pedido debes dirigirte a:":"To collect your order you must go to:",
  "Entrega a domicilio":"Delivery",
  "Recoger en tienda":"Pickup",
  "Menú":"Menu",
  "Información":"Information",
  "Informaciónshort":"Info",
  "Tu pedido":"Your order",
  "entrega":"Deliver",
  "Entregar a ":"Deliver to ",
  "Agregar instrucciones de entrega":"Add delivery instructions",
  "Para recoger tu pedido debes dirigirte a":"To pick up your order you must go to",
  "Ver el mapa":"View map",
  "Sí enviar cubiertos":"The restaurant will add cutlery for you",
  "No envíar cubiertos":"Only request cutlery when you need it",
  "Agregar instrucciones de preparación":"Order Notes",
  "Selecciona un cupón de descuento":"Select discount coupons",
  "Seleccione más cupones de descuento":"Select more discount coupons",
  "Selecciona un cupón de descuento2":"Login to enjoy the promotions if available",
  "Borrar":"Remove",
  "Registrate como repartidor":"Register as driver",
  "mas sobre nosotros":"More abour us",
  "Agregar propina para el repartidor":"Add tip for driver",
  "¡Las entregas solo son posible gracias a ellos!":"Deliveries are only possible thanks to them!",
  "Todos los productos":"Subtotal",
  "Tarifa de servicio":"Service fee",
  "Costo de envío":"Delivery fee",
  "Descuento": "Discount",
  "Propina":"Tip",
  "Método de pago":"Payment method",
  "Total":"Total",
  "Elige tu método de pago ":"Choose your payment method",
  "Esperando a que el negocio acepte el pedido":"Waiting for the business to accept the order",
  "Nuevo pedido de ":"New order from ",
  "Nuevo orden":"New order",
  "Pedido realizado con éxito":"Order made successfully",
  "Algo salió mal al crear el pedido":"Something went wrong when creating the order",
  "El negocio está cerrado":"Business is closed",
  "Pagar pedido ":"Pay and order",
  "Ver mapa":"View map",
  "Hacer pedido ":"Place order",
  "Detalles del pedido":"Order details",
  "Cancelar pedido":"Cancel order",
  "Pedido número":"Order ID",
  "ID del pedido":"Order ID",
  "Entrega estimada": "Estimate delivery",
  "Recogida estimada":"Estimate pickup",
  "Ver más":"See more",
  "Ver menos":"See less",
  "Te avisaremos una vez que el negocio acepte tu pedido":"We will notify you once the business accepts your order",
  "El negocio está preparando tu pedido":"The business is preparing your order",
  "Tu pedido está siendo preparado":"Your order is being prepared",
  " está en camino para recoger tu pedido":" is on its way to pick up your order",
  " llegó al negocio":" reached the business",
  " ha llegado al negocio y recogerá tu pedido":" has arrived at the business and will pick up your order",
  "Tu pedido ha sido entregado":"Your order has been delivered",
  "Tu pedido ha sido cancelado":"Your order has been canceled",
  "Tu ubicación":"Your location",
  "Nivel: ":"Level: ",
  "Dirección de entrega":"Delivery address",
  "Tarjeta contra entrega":"Card on delivery",
  "Efectivo":"Cash",
  "Tarjeta":"Card",
  "Día y hora de su pedido":"Date and time of your order",
  "Enviar cubiertos":"Send cutlery",
  "Sí":"Yes",
  "No":"No",
  "Detalles del pedido:":"Order details:",
  "Total parcial":"Subtotal",
  "Pagado con ":"Paid with ",
  "tarjeta":"card",
  "Pagar en efectivo":"Pay cash",
  "Regresar":"Back",
  "Nombre":"Name",
  "Teléfono":"Phone:",
  "Perfil actualizado correctamente":"Profile updated successfully",
  "Por favor ingresa de nuevo para actualizar tu contraseña":"Please login again to update your password",
  "Algo salió mal":"Something went wrong",
  "Actualizar Perfil":"Update profile",
  "Ahora puedes cambiar tu contraseña":"Now you can change your password",
  "No puedo iniciar sesión":"Cant login",
  "No existen pedidos para mostrar":"There are no orders to display",
  "Solicitar ID":"Order ID",
  "Tu orden ha sido entregada":"Your order has been delivered",
  "Pendiente":"Pending",
  "Tarjeta con terminación ":"Card with termination ",
  "Agregar tarjeta":"Insert card details",
  "Número de tarjeta":"Card number",
  "Nombre de la titular":"Card holder name",
  "Ahora":"Now",
  " Hoy, ":" Today, ",
  " Mañana, ":" Tomorrow, ",
  "hoy":"Today",
  "mańana":"Tomorrow",
  "Guardar tarjeta":"Save card",
  "Programar tu entrega":"Schedule your delivery",
  "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Your order will arrive within the selected period",
  "Programar y avanzar":"Preorder now",
  "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"The bank has declined the transaction. Create and enter a digital card here to make more secure purchases",
  "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"The card details are incorrect. Please verify them to continue.",
  "Algo salió mal al crear el pago":"Something went wrong creating the payment",
  "Hubo un error al confirmar el pago. Detalles: ":"There was an error confirming the payment. Details: ",
  "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"There was an error confirming the payment. Please try again with another card",
  "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"Payment is still in \" processing \" status . This is unusual please contact us",
  "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"Sorry, we don't have products available for purchase at this moment, please try another time",
  "allergyText1": "Enter your food allergies and intolerances on how you want to receive your food. These comments will be seen by the restaurant when preparing your order.",
  "allergyHello": "Hello ",
  "profileAllergy": "Food Preferences / Allergies",
  "allergyAdd": "Add Allergy",
  "allergy": "Allergies",
  "ckeckout_allergy_tittle": "Enable your allergies",
  "ckeckout_allergy_descr": "¡Our staff will see this so this so we can take care of you!",
  "edit_allergy": "Edit allergies",
  "allergy_holder": "Add your allergies here",
  "my_allergies_list_tittle": "My active allergies list",
  "save": "Save",
  "Agotado":"Sold out",
  "Restablecer instrucciones enviadas al correo electrónico ingresado":"Reset instructions send to entered email",
  "No puedo encontrar el correo electrónico o algo salió mal.":"Cant find email or something went wrong.",
  "Por favor ingrese un correo electrónico válido primero":"Please enter a valid email first",
  "profileMyCard":"My Cards",
  "Enviar":"Send",
  "Ingrese OTP una vez que reciba":"Enter OTP once you receive",
  "Editar teléfono":"Edit phone",
  "cardData241": "Card with termination ",
  "¿Olvidaste tu contraseña?":"Forgot your password?",
  "Bienvenido": "Bienvenido",
  "Bienvenido de regreso": "Bienvenido de regreso",
  "Crea tu cuenta para continuar":"Crea una cuenta para continuar",
  "Ingresa tu cuenta para continuar":"Ingresa tu cuenta para continuar",
  "Alergias inactivas":"Allergies disabled for this order",
  "Alergias activadas":"Allergies activated for this order",
  "allergyBannerTitle": "Learn about allergies",
  "allergyBannerSubTitle": "and food intolerances",
  "allergyTerms1": "What can I eat?",
  "allergyTerms2": "We created this profile to promote a safe intake of our users who have some type of allergy or food intolerance, since 25% of the country's population suffers from it. In this way we intend to help you obtain a safe purchase prioritizing and highlighting your allergies and food intolerances in all your orders without having to put them in each of your orders. If you don't know about this topic, you can read until the end and learn its importance.",
  "allergyTerms3": "What is a food allergy?",
  "allergyTerms4": "Food allergy occurs when the person reacts with symptoms to contact, ingestion or inhalation of food proteins, causing an immune reaction of the body to that external substance that enters it and manifests itself clinically through different signs and symptoms, milder or more severe, depending on the affected organ. A food intolerance does not arise at the time of ingestion, it can take hours or days and will only cause discomfort, no fatal risk such as those that an allergy can cause.",
  "allergyTerms5": "What reaction can an allergen cause?",
  "allergyTerms6": "Food allergy reactions vary greatly depending on the person affected. Some of these reactions can be very mild and affect only one part or system of the body. Others can be more serious and affect more than one body system. Reactions may occur within the first few minutes or take several hours to appear after exposure to the food allergen.",
  "allergyTerms7": "The symptoms or clinical manifestations will vary according to the organ or system of the affected organism:",
  "allergyTerms8": "Respiratory System: Persistent cough, wheezing, shortness of breath (choking, difficulty swallowing, voice change, and rhinitis.",
  "allergyTerms9": "Skin system (frequent): Urticaria, hives (beans). Itching and tightness in the palate and throat. Inflammation of the lips and eyelids. Conjunctivitis.",
  "allergyTerms10": "Digestive System: Vomiting, nausea, diarrhea, acute abdominal pain.",
  "allergyTerms11": "Nervous System: Unconsciousness.",
  "allergyTerms12": "Cardiovascular System: Dizziness, hypotension, fainting, cyanosis.",
  "allergyTerms13": "Anaphylaxis: Severe allergic reaction of rapid onset and life-threatening, after contact with the allergen, and when 2 or more organs or body systems are affected.",
  "allergyTerms14": "The most serious consequences are: airway obstruction (laryngeal edema) and cardiorespiratory arrest due to bronchospasm and/or vascular collapse. The most frequent causes include food, drugs and insect bites (bees and wasps).",
  "allergyTerms15": "There are more than 120 foods described as causing food allergies, among the most common are: ",
  "allergyTerms16": "- Cereals containing gluten.",
  "allergyTerms17": "- Crustaceans and crustacean-based products.",
  "allergyTerms18": "- Eggs and egg-based products.",
  "allergyTerms19": "- Fish and fish-based products.",
  "allergyTerms20": "- Soy and soy-based products.",
  "allergyTerms21": "- Milk and its derivatives (including lactose).",
  "allergyTerms22": "- Nuts and derived products. (peanuts, walnuts, etc.)",
  "allergyTerms23": "- Celery and derived products.",
  "allergyTerms24": "- Mustard and derived products.",
  "allergyTerms25": "- Sesame seeds.",
  "allergyTerms26": "- Sulfur dioxide and sulfite.",
  "allergyTerms27": "- Legumes ",
  "allergyTerms28": "- Molluscs and mollusc-based products.",
  "allergyTerms29": "Now you know about this topic, we invite you to create your profile in a conscious way and if you are not within this 25% of the population, to make them aware in case they need your help at any time. ",
  "begin": "Begin",
  "No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable":"There's no delivery location selected or we don't offer delivery service on that area",
  "Continuar":"Next",
  "Fecha":"Date",
  "Ingresar dirección":"Add address",
  "Por favor ingresa tu dirección":"Add your address",
  "Ingresar dirección2":"Add product",
  "Por favor ingresa tu dirección2":"Add product",
  " Tarifa de servicio: ":" Service fee: ",
  "Añadir más productos":"Add more products",
  "La compra mínima debe ser de €":"Minimum purchase should be €",
  "Domingo":"Sunday",
  "Lunes":"Monday",
  "Martes":"Tuesday",
  "Miércoles":"Wednesday",
  "Jueves":"Thursday",
  "Viernes":"Friday",
  "Sábado":"Saturday",
  "Gratis":"Free",
  "¿Un último antojo?": "One last craving?",
  "Buscando direcciones":"Searching for addresses...",
  "Continuar como invitado":"Continue as guest",
  "Proporcione detalles adicionales para continuar":"Provide additional details to continue",
  "Proporcione detalles adicionales para continuar3":"Please enter your phone number before continue",
  "Agregar nueva tarjeta2":"Add new card",
  "Introduce el código de descuento":"Enter the discount code",
  "La identificación de correo electrónico ya está en uso":"Email ID is already in use",
  "Codigo invalido":"Invalid code",
  "Creando orden, no cierre ni actualice la ventana":"Creating order, Do not close or refresh the window",
  "Buscando direcciones":"Searching for addresses...",
  "Reducir la cantidad de": "Reduce quantity of",
  "Proporcione detalles adicionales":"Please provide additional details",
  "Hay ":"There's ",
  " disponibles, reduce la cantidad o programa tu pedido para otro momento":" available, reduce the quantity or schedule your order for another time",
  "Su pedido ha sido cancelado con éxito.":"Your order has been cancelled successfully.",
  "Entregamos todo por ti":"We deliver everything for you",
  "Entregamos todo por ti subtittle":"Receive all your favorite items without having to go out",
  "Ingresa tu dirección de entrega":"Enter your delivery address",
  "Fitness":"Fitness",
  "Comida Rápida":"Fast food",
  "Tacos":"Tacos",
  "Pizzas":"Pizzas",
  "Postres":"Desserts",
  "Alitas":"Wings",
  "Hamburguesas":"Burgers",
  "Mariscos":"Seafood",
  "Oriental":"Oriental",
  "Pollos":"Chickens",
  "Mexicana":"Mexican",
  "Internacional":"International",
  "Ordena tus comidas o postres favoritos y recíbelos en menos de 1 hora":"Order your favorite meals or desserts and receive them in less than 1 hour",
  "Encuentra tus tiendas favoritas y pide desde cientos de productos disponibles en sólo unos clics":"Find your favorite stores and order from hundreds of products available in just a few clicks",
  "Descarga la app y conoce mucho más que te encantará":"Download the app and learn much more that you will love",
  "Registra tu restaurante o negocio":"Register your restaurant or business",
  "Registra tu restaurante o negocio2":"Discover the benefits of the +2,000 allies that already work with us",
  "Síguenos y obtén promociones exclusivas":"Follow us and get exclusive promotions",
  "Más sobre nosotros":"More about us",
  "Más sobre nosotros2":"Know more about your new ally for taking your sells one step forward",
  "Registra tu restaurante":"Register your restaurant",
  "Ingresa como Repartidor":"Register as driver",
  "Ingresa como Repartidor2":"Earn extra money delivering with us, we have the best rates and benefits",
  "Descarga la app":"Download the app",
  "Politica de privacidad":"Privacy Policy",
  "Términos":"Terms",
  "Restaurantes":"Restaurants",
  "Tiendas":"Stores",
  "Favoritos":"Favorites",
  "Buscar negocios":"Search for food, products or stores",
  "Todos los negocios":"All businesses available for you",
  "No hay negocios disponibles":"No business available",
  "Actualmente cerrado":"Currently closed",
  "¡Ordenar ahora!":"Order now!",
  "Disponible:":"Available: ",
  "Usar":"Use",
  "Cartera":"Wallet",
  "MiCartera":"Wallet",
  "Ingresa el monto a utilizar en esta orden":"Enter the amount to use in this order",
  "Máximo posible en esta orden: ":"Maximum possible in this order: ",
  "Saldo utilizado: ":"Balance used: ",
  "Eliminar":"Remove",
  "El reembolso se acreditará dentro de las 24 horas.":"Cashback will be credited within 24 hours.",
  "Reembolso máximo de €":"Maximum cashback of €",
  "Detalles adicionales2":"Additional details before continue",
  "DEJA TU OPINIÓN":"LEAVE YOUR OPINION",
  "Haz click en las estrellas para hacer tu review":"Click on the stars to make your review",
  "Comentarios":"Comments",
  "ESPERAMOS QUE VUELVAS PRONTO!":"WE HOPE YOU COME BACK SOON!",
  "¡MUCHAS GRACIAS!":"THANK YOU!",
  "Hemos recibido tu valoración correctamente":"We have received your evaluation correctly",
  "Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?":"Changing the delivery type will delete your cart. Are you sure you want to continue?",
  "No puedo aplicar ":"Cant apply ",
  "Actualmente solo estamos aceptando pedidos anticipados dentro de las ":"The minimum preparation time for our orders is ",
  " horas a partir de ahora":" hours. Choose the time you want to schedule your order",
  " minutos a partir de ahora":" minutes. Choose the time you want to schedule your order",
  "Te faltan €":"You are missing €",
  " para completar el pedido mínimo y así evitar la tarifa de de pedido pequeño":" to fill the minimum order and avoid the small order fee",
  "Tarifa de pedido pequeño":"Small order fee",
  "Esta tarifa nos ayuda a gestionar nuestro sistema y a cubrir los costes de pedidos pequeños":"This fee helps us run our system and cover the costs of small orders",
  "Tarifa de bolsa":"Package/bag fee"
 },
 es: {
   'language':"es",
    'Dom':"Dom",
    'Lun':"Lun", 'Mar':"Mar", 'Mié':"Mié", 'Jue':"Jue", 'Vie':"Vie", 'Sáb':"Sáb",
  "Información adicional": "Información",
    "Mis direcciones":"Mis direcciones",
  "Registro":"Registrarse",
    "Progamar pedido": "Programar pedido",
    "Progamar pedido2": "Programar",
    "Pedido programado para":"Pedido solicitado para",
    "OTP": "OTP",
  "negociodesactivado":"El restaurante no puede aceptar más pedidos en este momento, por favor prueba de nuevo más tarde",
    "el día": "El día ",
    "Bienvenido": "Bienvenido",
  "Bienvenido de regreso": "Bienvenido de nuevo",
  "Crea tu cuenta para continuar":"Crea una cuenta para continuar",
  "Ingresa tu cuenta para continuar":"Ingresa tu cuenta para continuar",
    "VerifiedHover": "Este negocio fue verificado cumpliendo los mayores estándares de calidad",
    "lo antes posible": "(Lo antes posible)",
    "closed_today":"Cerrado",
    "Selecciona tu método de pago":"Selecciona tu método de pago",
    "Promociones": "Promociones",
  "Ingresar":"Iniciar sesión",
    "¿Un último antojo?":"¿Un último antojo?",
    "Ver pedido": "Ver pedido",
    "Recoger":"Recoger en",
    "mas sobre nosotros":"Más sobre nosotros",
    "Inicio de sesión exitoso":"Inicio de sesión exitoso",
    "Error de inicio de sesión":"Error de inicio de sesión",
    "Esperando a que el negocio acepte su pedido": "Esperando a que el restaurante acepte su pedido",
    "El negocio está preparando su producto.": "El restaurante está preparando su pedido",
    " está en camino para recoger su pedido":" está de camino para recoger su pedido",
    " llegó al negocio":" llegó al negocio",
    " ha recogido tu pedido":" ha recogido tu pedido",
  "Su orden ha sido completada":"Su pedido ha sido completado",
    "Su pedido ha sido cancelado": "Su pedido ha sido cancelado",
    "Iniciar sesión":"Iniciar sesión",
    "Registrarse": "Registrarse",
    "Perfil": "Perfil",
  "Mis Pedidos": "Mis pedidos",
    "Cerrar sesión": "Cerrar sesión",
  "Hogar": "Casa",
  "Agregar €":"Agregar €",
  "Su cupón ha sido agregado con éxito.": "Tu cupón ha sido agregado con éxito",
    "Añade al menos ":"Añade al menos ",
    " productos promocionales.": " productos promocionales.",
  "Los productos máximos deben ser €":"Los productos máximos deben ser €",
  "Los productos mínimos deben ser €": "Los productos mínimos deben ser €",
    "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.",
  " Costo de envío: ":" Coste de envío ",
    " Descuento: ": " Descuento: ",
    "Seleccionar mi ubicación": "Seleccionar mi ubicación",
    "Buscar producto": "Buscar producto",
    "ID del pedido: ": "ID del pedido: ",
  "Obligatorio" :"Obligatorio, elige ",
    "Obligatorio2" :"Necesario",
    "Selecciona al menos ": "Selecciona al menos ",
    "Selecciona de ": "Selecciona de ",
    ".Hasta ": ".Hasta ",
    "Selecciona hasta ": "Selecciona hasta ",
    "Email": "Email",
    "Contraseña":"Contraseña",
    "o":"o",
    "entrega":"Entrega",
    "Nombre completo": "Nombre completo",
    "Número de teléfono":"Número de teléfono",
  "Datos correctos":"Datos ingresados correctamente",
    "Existe un error en el registro":"Existe un error en el registro",
  "Crear cuenta": "Registrarse",
    "Cambiar número Éxito":"Cambiar número Éxito",
    "Reenviar":"Reenviar",
  "Mensaje enviado correctamente al número anterior":"Mensaje enviado correctamente",
  "Has ingresado correctamente":"Has iniciado correctamente",
  "El código ingresado no es correcto, intenta nuevamente":"El código introducido no es correcto, intenta nuevamente",
    "Calificación:":"Calificación:",
    "Correo:":"Correo:",
    "Guardar tarjeta":"Guardar tarjeta",
    "Dirección:":"Dirección:",
  "Selecciona tu ubicación o ingrese una nueva dirección":"Selecciona tu ubicación o introduce una nueva dirección",
    "Selecciona tu ubicación o ingrese una nueva dirección2":"Queremos asegurarnos de que tenemos cobertura en tu zona",
    "Selecciona tu ubicación o ingrese una nueva dirección4":"Porfavor ingresa tu dirección",
  "Ingrese la dirección de entrega":"Introduzca la dirección de entrega (calle y número)",
  "Detalles adicionales":"Detalles adicionales (piso, puerta, etc)",
    "mi hogar":"mi hogar",
    "mi Trabajo":"mi trabajo",
    "Envío OTP con éxito":"Envío OTP con éxito",
  "otra dirección":"otra dirección",
    "Trabajo":"Trabajo",
  "Visita":"Otra",
    "Cancelar":"Cancelar",
    "Guardar":"Guardar",
    "Usar mi ubicación actual":"Usar mi ubicación actual",
    "Agregar nueva tarjeta":"Agregar nueva tarjeta",
    "Pago en efectivo":"Pago en efectivo",
  "Tarjeta a la entrega":"Pago con tarjeta en la entrega",
    ". Ver detalles":". Ver detalles",
    ". Menos detalles":". Menos detalles",
    "Comprar ":"Comprar ",
    " productos y pago ":" productos y pago ",
    " en productos seleccionados":" en productos seleccionados",
    "Productos promocionados:":"Productos promocionados:",
    "Se eliminará la tarifa de envío":"Se eliminará la tarifa de envío",
  "El número mínimo de productos debe ser €":"El costo mínimo de productos debe ser €",
  "El número máximo de productos debe ser €":"El costo máximo de productos debe ser €",
  "Descuento €":"Descuento €",
  "Obtener €":"Obtén €",
    " al final del día en Wallet":" al final del día en Wallet",
  "Obtener ":"Obtén ",
    "% de cashback sobre el total de tus productos en Wallet":"% de cashback sobre el total de tus productos en Wallet",
  "% de descuento en todos sus productos.":"% de descuento en todos tus productos.",
  "Consigue un descuento mínimo de €":"Consigue un descuento mínimo de €",
  "Sube hasta €":"Límite de descuento otorgado hasta €",
    " descuento":" descuento",
    "Aplicar":"Aplicar",
    "Lo sentimos, no hay descuentos disponibles en este momento.":"Lo sentimos, no hay descuentos disponibles en este momento.",
  "Entrega de:":"Entrega en",
    "Recoger en:":"Recoger en",
    "min aprox":"min aprox",
  "costo de envío: €":"Coste de envío",
    "envio":"Envío",
    "recoger en":"Recoger en",
    "entrega en":"Entrega en",
    "Distancia":"Distancia",
    " ¡Promociones! ":" ¡Promociones! ",
    "Para recoger tu pedido debes dirigirte a:":"Para recoger tu pedido debes dirigirte a:",
    "Entrega a domicilio":"Entrega a domicilio",
    "Recoger en tienda":"Recoger en tienda",
    "Menú":"Menú",
  "Tu repartidor":"Tu repartidor",
    "Información":"Información",
    "Informaciónshort":"Info",
    "Tu pedido":"Tu pedido",
    "Entregar a ":"Entregar a ",
  "Agregar instrucciones de entrega":"Añadir instrucciones de entrega",
    "Para recoger tu pedido debes dirigirte a":"Para recoger tu pedido debes dirigirte a",
    "Ver el mapa":"Ver el mapa",
  "Sí enviar cubiertos":"Cubiertos",
  "No envíar cubiertos":"Cubiertos",
  "Agregar instrucciones de preparación":"Añadir instrucciones de preparación",
  "Selecciona un cupón de descuento":"Seleccionar un cupón de descuento",
  "Seleccione más cupones de descuento":"Seleccionar más cupones de descuento",
  "Selecciona un cupón de descuento2":"Ingresa en tu cuenta para disfrutar de descuentos si hay disponibles",
    "Borrar":"Borrar",
  "Agregar propina para el repartidor":"Añadir propina para el repartidor",
    "¡Las entregas solo son posible gracias a ellos!":"¡Las entregas solo son posible gracias a ellos!",
  "Todos los productos":"Subtotal",
    "Tarifa de servicio":"Tarifa de servicio",
  "Costo de envío":"Coste de envío",
    "Descuento": "Descuento",
    "Propina":"Propina",
    "Método de pago":"Método de pago",
    "Total":"Total",
    "Teléfono":"Teléfono:",
    "Elige tu método de pago ":"Elige tu método de pago ",
  "Esperando a que el negocio acepte el pedido":"Esperando a que el restaurante acepte el pedido",
    "Nuevo pedido de ":"Nuevo pedido de ",
  "Nuevo orden":"Nuevo pedido",
    "Pedido realizado con éxito":"Pedido realizado con éxito",
    "Algo salió mal al crear el pedido":"Algo salió mal al crear el pedido",
    "El negocio está cerrado":"El negocio está cerrado",
    "Pagar pedido ":"Pagar pedido ",
    "Hacer pedido ":"Hacer pedido ",
    "Detalles del pedido":"Detalles del pedido",
    "Cancelar pedido":"Cancelar pedido",
    "Pedido número":"Pedido número",
    "ID del pedido":"ID del pedido",
    "Entrega estimada": "Entrega estimada",
    "Recogida estimada":"Recogida estimada",
    "Ver más":"Ver más",
    "Ver menos":"Ver menos",
  "Te avisaremos una vez que el negocio acepte tu pedido":"Te avisaremos cuando el restaurante acepte tu pedido",
  "El negocio está preparando tu pedido":"El restaurante está preparando tu pedido",
    "Tu pedido está siendo preparado":"Tu pedido está siendo preparado",
    " está en camino para recoger tu pedido":" está en camino para recoger tu pedido",
  " llegó al negocio":" llegó al restaurante",
    " ha llegado al negocio y recogerá tu pedido":" ha llegado al negocio y recogerá tu pedido",
    "Tu pedido ha sido entregado":"Tu pedido ha sido entregado",
    "Tu pedido ha sido cancelado":"Tu pedido ha sido cancelado",
    "Tu ubicación":"Tu ubicación",
    "Nivel: ":"Nivel: ",
    "Dirección de entrega":"Dirección de entrega",
  "Tarjeta contra entrega":"Pagar con tarjeta en entrega",
    "Efectivo":"Efectivo",
    "Tarjeta":"Tarjeta",
  "Día y hora de su pedido":"Día y hora de tu pedido",
    "Enviar cubiertos":"Enviar cubiertos",
    "Sí":"Sí",
    "No":"No",
    "Detalles del pedido:":"Detalles del pedido:",
  "Total parcial":"Subtotal",
    "Pagado con ":"Pagado con ",
    "tarjeta":"tarjeta",
    "Pagar en efectivo":"Pagar en efectivo",
  "Regresar":"Volver",
    "Nombre":"Nombre",
    "Perfil actualizado correctamente":"Perfil actualizado correctamente",
  "Por favor ingresa de nuevo para actualizar tu contraseña":"Por favor inicia de nuevo para actualizar tu contraseña",
    "Algo salió mal":"Algo salió mal",
    "Actualizar Perfil":"Actualizar Perfil",
    "Ahora puedes cambiar tu contraseña":"Ahora puedes cambiar tu contraseña",
    "No puedo iniciar sesión":"No puedo iniciar sesión",
    "No existen pedidos para mostrar":"No existen pedidos para mostrar",
    "Solicitar ID":"Solicitar ID",
    "Tu orden ha sido entregada":"Tu orden ha sido entregada",
    "Pendiente":"Pendiente",
    "Ver mapa":"Ver mapa",
    "Tarjeta con terminación ":"Tarjeta terminada en ",
  "Agregar tarjeta":"Añadir tarjeta",
    "Número de tarjeta":"Número de tarjeta",
  "Nombre de la titular":"Nombre del titular",
  "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"El banco ha rechazado la transacción. Crea e introduce aquí una tarjeta digital para realizar compras más seguras",
    "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.",
    "Algo salió mal al crear el pago":"Algo salió mal al crear el pago",
    "Ahora":"Ahora",
    " Hoy, ":" Hoy, ",
    " Mañana, ":" Mañana, ",
    "hoy":"Hoy",
    "mańana":"Mañana",
    "envio":"Envío",
    "Programar tu entrega":"Programar pedido",
  "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Tu pedido llegará entre en lapso seleccionado",
    "Programar y avanzar":"Programar y avanzar",
    "Hubo un error al confirmar el pago. Detalles: ":"Hubo un error al confirmar el pago. Detalles: ",
    "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta",
    "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos",
  "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"El restaurante está cerrado o no puede aceptar pedidos en este momento, programa tu pedido para más tarde",
    "allergyText1": "Ingresa tus alergias e intolerancias alimentarias sobre como deseas recibir tus alimentos. Estos comentarios los verá el restaurante al momento de preparar tu orden.",
  "allergyHello": "Hola ",
  "profileAllergy": "Preferencias de alimentos / alergias",
  "allergyAdd": "Añadir alergia",
  "allergy": "Alergias",
  "ckeckout_allergy_tittle": "Habilita tus alergias",
  "ckeckout_allergy_descr": "¡Nuestro personal las podrá ver para que podamos cuidar de ti!",
  "edit_allergy": "Editar alergias",
  "allergy_holder": "Agrega aquí tus alergias",
  "my_allergies_list_tittle": "Mi lista de alergias activas",
  "save": "Guardar",
  "Agotado":"Agotado",
  "Restablecer instrucciones enviadas al correo electrónico ingresado":"Te hemos enviado a tu correo electrónico las instrucciones para restablecer tu contraseña",
  "No puedo encontrar el correo electrónico o algo salió mal.":"No puedo encontrar el correo electrónico o algo salió mal.",
  "Por favor ingrese un correo electrónico válido primero":"Por favor ingrese un correo electrónico válido",
  "profileMyCard":"Mis tarjetas",
  "Enviar":"Enviar",
  "Crea tu cuenta para continuar":"Crea una cuenta para continuar",
  "Ingresa tu cuenta para continuar":"Ingresa tu cuenta para continuar",
  "Ingrese OTP una vez que reciba":"Introduzca el código recibido",
  "Editar teléfono":"Editar teléfono",
  "cardData241": "Tarjeta terminada en ",
  "¿Olvidaste tu contraseña?":"¿Olvidaste tu contraseña?",
  "Alergias inactivas":"Alergias desactivadas para este pedido",
  "Alergias activadas":"Alergias activadas para este pedido",
  "allergyBannerTitle": "Saber más sobre las alergias",
  "allergyBannerSubTitle": "e intolerancias alimenticias",
  "allergyTerms1": "¿Qué puedo comer?",
  "allergyTerms2": "Creamos este perfil para promover una ingesta segura de nuestros usuarios que presentan a algún tipo de alergia o intolerancia alimentaria, ya que el 25% de la población del país lo padece. De esta manera pretendemos ayudarte a obtener una compra segura priorizando y resaltando en todos tus pedidos tus alergias e intolerancias alimentarias sin necesidad de ponerlos en cada una de tus órdenes. Si no conoces de este tema puedes leer hasta el final y aprender su importancia.",
  "allergyTerms3": "¿Qué es una alergia alimentaria?",
  "allergyTerms4": "La alergia a alimentos se produce cuando la persona reacciona con síntomas al contacto, ingestión o inhalación de las proteínas de un alimento provocando una reacción inmunitaria del organismo ante esa sustancia externa que entra en él y se manifiesta clínicamente a través de diferentes signos y síntomas más leves o más graves según el órgano afectado. Una intolerancia alimentaria no surge en el momentode la ingesta puede tardar horas o días y solo causara malestares, ningún riesgo fatal como los que puede causar una alergia.",
  "allergyTerms5": "¿Qué reacción puede ocasionar un alérgeno?",
  "allergyTerms6": "Las reacciones propias de las alergias alimentarias varían mucho en función de la persona afectada. Algunas de estas reacciones pueden ser muy leves y afectar sólo a una parte o sistema del organismo. Otros pueden ser más graves y afectar más de un sistema del organismo. Las reacciones pueden ocurrir durante los primeros minutos o tardar varias horas en aparecer tras la exposición al alérgeno alimentario.",
  "allergyTerms7": "La sintomatología o manifestaciones clínicas, variarán según el órgano o sistema del organismo afectado:",
  "allergyTerms8": "Sistema respiratorio: Tos persistente, silbidos, dificultad respiratoria (ahogo, dificultad para tragar, cambio de voz y rinitis.",
  "allergyTerms9": "Sistema cutáneo (frecuentes): Urticaria, ronchas (habas). Picor y opresión en el paladar y en la garganta. Inflamación labios y párpados. Conjuntivitis.",
  "allergyTerms10": "Sistema digestivo: Vómitos, náuseas, diarreas, dolor abdominal agudo.",
  "allergyTerms11": "Sistema nervioso: Pérdida del conocimiento.",
  "allergyTerms12": "Sistema cardiovascular: Mareo, hipotensión, desmayo, cianosis.",
  "allergyTerms13": "Anafilaxia: Reacción alérgica grave de instauración rápida y potencialmente mortal, después del contacto con el alérgeno, y cuando hay afectación de 2 o más órganos o sistemas del organismo.",
  "allergyTerms14": "Las consecuencias más graves son: la obstrucción de la vía aérea (edema laríngeo) y la parada cardiorrespiratoria por broncoespasmo y / o colapso vascular. Las causas más frecuentes incluyen los alimentos, los fármacos y las picaduras de insectos (abejas y avispas).",
  "allergyTerms15": "Hay más de 120 alimentos descritos como causantes de alergias alimentarias, entre los más comunes están: ",
  "allergyTerms16": "- Cereales que contienen gluten.",
  "allergyTerms17": "- Crustáceos y productos a base de crustáceos.",
  "allergyTerms18": "- Huevos y productos a base de huevo.",
  "allergyTerms19": "- Pescado y productos a base de pescado.",
  "allergyTerms20": "- Soya y productos a base de soya.",
  "allergyTerms21": "- Leche y sus derivados (incluida la lactosa).",
  "allergyTerms22": "- Frutos de secos y productos derivados. (cacahuates, nueces, etc.)",
  "allergyTerms23": "- Apio y productos derivados.",
  "allergyTerms24": "- Mostaza y productos derivados.",
  "allergyTerms25": "- Granos de sésamo.",
  "allergyTerms26": "- Dióxido de azufre y sulfito.",
  "allergyTerms27": "- Leguminosas ",
  "Bienvenido": "Bienvenido",
  "Bienvenido de regreso": "Bienvenido de regreso",
  "allergyTerms28": "- Moluscos y productos a base de moluscos.",
  "allergyTerms29": "Ahora conoces de este tema te invitamos a crear tu perfil de manera consiente y si no estas dentro de este 25% de la población a concientizarlo por si en algún momento necesitan de tu ayuda. ",
  "begin": "Comenzar",
  "No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable":"Lo sentimos, no entregamos en la ubicación de entrega seleccionada",
  "Fecha":"Fecha",
  "Continuar":"Continuar",
  "Ingresar dirección":"Introducir dirección",
  "Por favor ingresa tu dirección":"Introducir tu dirección",
  "Ingresar dirección2":"Agregar",
  "Por favor ingresa tu dirección2":"Agregar al pedido",
  " Tarifa de servicio: ":" Tarifa de servicio: ",
  "Añadir más productos":"Añadir más productos",
  "La compra mínima debe ser de €":"El pedido mínimo es de €",
  "Domingo":"Domingo",
  "Lunes":"Lunes",
  "Martes":"Martes",
  "Miércoles":"Miércoles",
  "Jueves":"Jueves",
  "Viernes":"Viernes",
  "Sábado":"Sábado",
  "Gratis":"Gratis",
  "¿Un último antojo?":"¿Un último antojo?",
  "Buscando direcciones":"Buscando direcciones...",
  "Continuar como invitado":"Continuar como invitado",
  "Proporcione detalles adicionales para continuar":"Proporcione detalles adicionales para continuar",
  "Proporcione detalles adicionales para continuar3":"Por favor, ingresa tu número telefónico antes de continuar",
  "Agregar nueva tarjeta2":"Agregar nueva tarjeta",
  "Introduce el código de descuento":"Introduce tu cupón de descuento",
  "La identificación de correo electrónico ya está en uso":"Este correo electrónico ya está en uso",
  "Codigo invalido":"Cupón invalido",
  "Creando orden, no cierre ni actualice la ventana":"Creando orden, no cierre ni actualice la ventana",
  "Buscando direcciones":"Buscando direcciones...",
  "Reducir la cantidad de": "Reducir la cantidad de",
  "Proporcione detalles adicionales":"Proporcione detalles adicionales",
  "Hay ":"Hay ",
  " disponibles, reduce la cantidad o programa tu pedido para otro momento":" disponibles, reduce la cantidad o programa tu pedido para otro momento",
  "Su pedido ha sido cancelado con éxito.":"Su pedido ha sido cancelado con éxito.",
  "Entregamos todo por ti":"Entregamos todo por ti",
  "Entregamos todo por ti subtittle":"Recibe tus productos favoritos sin necesidad de salir",
  "Ingresa tu dirección de entrega":"Ingresa tu dirección de entrega",
  "Fitness":"Fitness",
  "Comida Rápida":"Comida Rápida",
  "Tacos":"Tacos",
  "Registrate como repartidor":"Regístrate como repartidor",
  "Pizzas":"Pizzas",
  "Postres":"Postres",
  "Alitas":"Alitas",
  "Hamburguesas":"Hamburguesas",
  "Mariscos":"Mariscos",
  "Oriental":"Oriental",
  "Pollos":"Pollos",
  "Mexicana":"Mexicana",
  "Internacional":"Internacional",
  "Ordena tus comidas o postres favoritos y recíbelos en menos de 1 hora":"Ordena tus comidas o postres favoritos y recíbelos en menos de 1 hora",
  "Encuentra tus tiendas favoritas y pide desde cientos de productos disponibles en sólo unos clics":"Encuentra tus tiendas favoritas y pide desde cientos de productos disponibles en sólo unos clics",
  "Descarga la app y conoce mucho más que te encantará":"Descarga la app y conoce mucho más que te encantará",
  "Registra tu restaurante o negocio":"Registra tu restaurante o negocio",
  "Registra tu restaurante o negocio2":"Descubre los beneficios que tienen los +2,000 socios que ya trabajan con nosotros",
  "Síguenos y obtén promociones exclusivas":"Síguenos y obtén promociones exclusivas",
  "Más sobre nosotros":"Más sobre nosotros",
  "Más sobre nosotros2":"Conoce más sobre tu nuevo aliado para incrementar tus ventas",
  "Registra tu restaurante":"Registra tu restaurante",
  "Ingresa como Repartidor":"Ingresa como Repartidor",
  "Ingresa como Repartidor2":"Gana dinero extra entregando pedidos, tenemos las mejores tarifas y beneficios",
  "Descarga la app":"Descarga la app",
  "Politica de privacidad":"Politica de privacidad",
  "Términos":"Términos",
  "Restaurantes":"Restaurantes",
  "Tiendas":"Tiendas",
  "Favoritos":"Favoritos",
  "Buscar negocios":"Buscar comida, productos o negocios",
  "Todos los negocios":"Disponibles para ti",
  "No hay negocios disponibles":"No hay negocios disponibles",
  "Actualmente cerrado":"Actualmente cerrado",
  "¡Ordenar ahora!":"¡Ordenar ahora!",
  "Disponible:":"Disponible: ",
  "Usar":"Usar",
  "Cartera":"Cartera",
  "MiCartera":"Mi cartera",
  "Ingresa el monto a utilizar en esta orden":"Ingresa el monto a utilizar en esta orden",
  "Máximo posible en esta orden: ":"Máximo posible en esta orden: ",
  "Saldo utilizado: ":"Saldo utilizado: ",
  "Eliminar":"Eliminar",
  "El reembolso se acreditará dentro de las 24 horas.":"El reembolso se acreditará dentro de las 24 horas.",
  "Reembolso máximo de €":"Reembolso máximo de €",
  "Detalles adicionales2":"Detalles adicionales antes de continuar",
  "DEJA TU OPINIÓN":"DEJA TU OPINIÓN",
  "Haz click en las estrellas para hacer tu review":"Haz click en las estrellas para hacer tu review",
  "Comentarios":"Comentarios",
  "ESPERAMOS QUE VUELVAS PRONTO!":"ESPERAMOS QUE VUELVAS PRONTO!",
  "¡MUCHAS GRACIAS!":"¡MUCHAS GRACIAS!",
  "Hemos recibido tu valoración correctamente":"Hemos recibido tu valoración correctamente",
  "Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?":"Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?",
  "No puedo aplicar ":'No es posible aplicar ',
  "Actualmente solo estamos aceptando pedidos anticipados dentro de las ":"El tiempo de preparación mínimo de nuestros pedidos es de ",
  " horas a partir de ahora":" horas. Elige la hora en la que quieres programar tu pedido",
  " minutos a partir de ahora":" minutos. Elige la hora en la que quieres programar tu pedido",
  "Te faltan €":"Te faltan €",
  " para completar el pedido mínimo y así evitar la tarifa de de pedido pequeño":" para completar el pedido mínimo y así evitar la tarifa de de pedido pequeño",
  "Tarifa de pedido pequeño":"Tarifa de pedido pequeño",
  "Esta tarifa nos ayuda a gestionar nuestro sistema y a cubrir los costes de pedidos pequeños":"Esta tarifa nos ayuda a gestionar nuestro sistema y a cubrir los costes de pedidos pequeños",
  "Tarifa de bolsa":"Tarifa de bolsa/empaque"
},
 pt: {
   'language':"pt",
   'Dom':"dom.",
   'Lun':"seg.",
   'Mar':"ter.",
   'Mié':"qua.",
   'Jue':"qui.",
   'Vie':"sex.",
   'Sáb':"Sáb.",
   "Información adicional": "informação adicional",
   "Mis direcciones":"Meus endereços",
   "Registro":"Registo",
   "Progamar pedido": "Agendar pedido",
   "Pedido programado para":"Pedido solicitado para",
   "OTP": "OTP",
   "negociodesactivado":"O restaurante não pode aceitar mais pedidos no momento, tente novamente mais tarde",
   "el día": "O dia ",
   "Bienvenido": "Bem-vindo",
   "Bienvenido de regreso": "Bem vindo de novo",
   "Crea tu cuenta para continuar":"Crie uma conta para continuar",
   "Ingresa tu cuenta para continuar":"Entre na sua conta para continuar",
   "lo antes posible": "(O mais breve possível)",
   "closed_today":"Fechado",
   "Selecciona tu método de pago":"Selecione o seu método de pagamento",
   "Promociones": "Promoções",
   "Ingresar":"Iniciar sessão",
   "¿Un último antojo?":"Um último capricho?",
   "Ver pedido": "Ver pedido",
   "Inicio de sesión exitoso":"Login bem sucedido",
   "Error de inicio de sesión":"Erro de login",
   "Esperando a que el negocio acepte su pedido": "A aguardar o restaurante aceitar o seu pedido",
   "El negocio está preparando su producto.": "O restaurante está a prepara o seu pedido",
   " está en camino para recoger su pedido":" está a caminho para recolher o seu pedido",
   " llegó al negocio":" chegou ao restaurante",
   " ha recogido tu pedido":" recolheu o seu pedido",
   "Su orden ha sido completada":"O seu pedido foi concluído",
   "Su pedido ha sido cancelado": "O seu pedido foi cancelado",
   "Iniciar sesión":"Iniciar sessão",
   "Registrarse": "Crie o seu perfil",
   "Perfil": "Perfil",
   "Mis Pedidos": "Os meus pedidos",
   "Cerrar sesión": "Encerrar Sessão",
   "Hogar": "Casa",
   "Agregar €":"Adicionar €",
   "Su cupón ha sido agregado con éxito.": "O seu vale foi adicionado com sucesso",
   "Añade al menos ":"Adicione pelo menos ",
   " productos promocionales.": " produtos promocionais.",
   "Los productos máximos deben ser €":"Os produtos máximos devem ser €",
   "Los productos mínimos deben ser €": "Os produtos mínimos devem ser €",
   "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"Este vale foi usado recentemente, tente novamente mais tarde.",
   " Costo de envío: ":" Custo de envio ",
   " Descuento: ": " Desconto: ",
   "Seleccionar mi ubicación": "Selecione minha localização",
   "Buscar producto": "Pesquisar produto",
   "ID del pedido: ": "ID do pedido: ",
   "Obligatorio" :"Obrigatório, escolha ",
   "Obligatorio2" :"Necessário",
   "Selecciona al menos ": "Selecione pelo menos ",
   "Selecciona de ": "Selecione de ",
   ".Hasta ": ".Até ",
   "Selecciona hasta ": "Selecione até ",
   "Email": "E-mail",
   "Contraseña":"Senha",
   "o":"ou",
   "Nombre completo": "Nome completo",
   "Número de teléfono":"Número de telefone",
   "Datos correctos":"Dados corretos",
   "Existe un error en el registro":"Há um erro no registo",
   "Crear cuenta": "Criar conta",
   "Cambiar número Éxito":"Alterar número Sucesso",
   "Reenviar":"Reenviar",
   "Mensaje enviado correctamente al número anterior":"Mensagem enviada corretamente",
   "Has ingresado correctamente":"Acedeu com sucesso",
   "El código ingresado no es correcto, intenta nuevamente":"O código digitado não está correto, tente novamente",
   "Calificación:":"Qualificação:",
   "Correo:":"Email:",
   "Guardar tarjeta":"Salvar cartão",
   "Dirección:":"Endereço:",
   "Selecciona tu ubicación o ingrese una nueva dirección":"Selecione sua localização ou digite um novo endereço",
   "Selecciona tu ubicación o ingrese una nueva dirección2":"Queremos ter certeza de que temos cobrimos a sua área",
   "Selecciona tu ubicación o ingrese una nueva dirección4":"Por favor, insira seu endereço",
   "Ingrese la dirección de entrega":"Digite o endereço de entrega (rua e número)",
   "Detalles adicionales":"Detalhes adicionais (andar, porta, etc.)",
   "mi hogar":"casa",
   "mi Trabajo":"trabalho",
   "Envío OTP con éxito":"OTP enviado com sucesso",
   "otra dirección":"outro endereço",
   "Trabajo":"Trabalho",
   "Visita":"Outro",
   "Cancelar":"Cancelar",
   "Guardar":"Guardar",
   "Usar mi ubicación actual":"Usar a minha localização atual",
   "Agregar nueva tarjeta":"Adicionar novo cartão",
   "Pago en efectivo":"Pagamento em dinheiro",
   "Tarjeta a la entrega":"Pagamento a cartão na entrega",
   ". Ver detalles":". Veja detalhes",
   ". Menos detalles":". Menos detalhes",
   "Comprar ":"Comprar ",
   " productos y pago ":" produtos e pagamento ",
   " en productos seleccionados":" em produtos selecionados",
   "Productos promocionados:":"Produtos Promovidos:",
   "Se eliminará la tarifa de envío":"A taxa de envio será removida",
   "El número mínimo de productos debe ser €":"O custo mínimo dos produtos deve ser €",
   "El número máximo de productos debe ser €":"O custo máximo dos produtos deve ser €",
   "Descuento €":"Desconto €",
   "Obtener €":"Obter €",
   " al final del día en Wallet":" no final do dia no Wallet",
   "Obtener ":"Obter ",
   "% de cashback sobre el total de tus productos en Wallet":"% de cashback sobre o total de seus produtos na Wallet",
   "% de descuento en todos sus productos.":"% de desconto em todos os seus produtos.",
   "Consigue un descuento mínimo de €":"Obtenha um desconto mínimo de €",
   "Sube hasta €":"Limite de desconto concedido até €",
   " descuento":" desconto",
   "Aplicar":"Aplicar",
   "Lo sentimos, no hay descuentos disponibles en este momento.":"Desculpe, não há descontos disponíveis no momento.",
   "Entrega de:":"Entrega de",
   "Recoger en:":"Recolher em",
   "min aprox":"min aprox",
   "costo de envío: €":"Taxa de entrega",
   "envio":"Envio",
   "recoger en":"Recolher em",
   "entrega en":"Entrega em",
   "Distancia":"Distância",
   " ¡Promociones! ":" promoções! ",
   "Para recoger tu pedido debes dirigirte a:":"Para recolher a sua encomenda deve dirigir-se a:",
   "Entrega a domicilio":"Entrega ao domicilio",
   "Recoger en tienda":"Recolher na loja",
   "Menú":"Menu",
   "Información":"Informação",
   "Informaciónshort":"Informação",
   "Tu pedido":"O seu pedido",
   "Entregar a ":"Entregar para ",
   "Agregar instrucciones de entrega":"Adicionar instruções de entrega",
   "Para recoger tu pedido debes dirigirte a":"Para recolher a sua encomenda deve dirigir-se a",
   "Ver el mapa":"Ver mapa",
   "Sí enviar cubiertos":"Talheres",
   "No envíar cubiertos":"Talheres",
   "Agregar instrucciones de preparación":"Adicionar instruções de preparação",
   "Selecciona un cupón de descuento":"Selecione um vale de desconto",
   "Seleccione más cupones de descuento":"Selecione mais vale de desconto",
   "Selecciona un cupón de descuento2":"Faça login na sua conta para aproveitar os descontos, se disponíveis",
   "Borrar":"Excluir",
   "Agregar propina para el repartidor":"Adicionar gorjeta para o estafeta",
   "¡Las entregas solo son posible gracias a ellos!":"As entregas só são possíveis graças a eles!",
   "Todos los productos":"Subtotal",
   "Tarifa de servicio":"Taxa de serviço",
   "Costo de envío":"Taxa de entrega",
   "Descuento": "Desconto",
   "Propina":"Gorjeta",
   "Método de pago":"Método de pagamento",
   "Total":"Total",
   "Teléfono":"Telefone:",
   "Elige tu método de pago ":"Escolha o seu método de pagamento ",
   "Esperando a que el negocio acepte el pedido":"À espera que o restaurante aceite o pedido",
   "Nuevo pedido de ":"Nova pedido de ",
   "Nuevo orden":"Novo pedido",
   "Pedido realizado con éxito":"Pedido bem sucedido",
   "Algo salió mal al crear el pedido":"Algo deu errado ao criar o pedido",
   "El negocio está cerrado":"O restaurante está fechado",
   "Pagar pedido ":"Pagar pedido ",
   "Hacer pedido ":"Fazer um pedido ",
   "Detalles del pedido":"Detalhes do pedido",
   "Cancelar pedido":"Cancelar pedido",
   "Pedido número":"Número do pedido",
   "ID del pedido":"ID do pedido",
   "Entrega estimada": "Entrega estimada",
   "Recogida estimada":"Recolha estimada",
   "Ver más":"Ver mais",
   "Ver menos":"Ver menos",
   "Tu repartidor":"Seu entregador",
   "Te avisaremos una vez que el negocio acepte tu pedido":"Iremos notificá-lo quando o restaurante aceitar o seu pedido",
   "El negocio está preparando tu pedido":"O restaurante está a preparar o seu pedido",
   "Tu pedido está siendo preparado":"Seu pedido está a ser preparado",
   " está en camino para recoger tu pedido":" está a caminho para pegar seu pedido",
   " ha llegado al negocio y recogerá tu pedido":" chegou no restaurante e vai pegar seu pedido",
   "Tu pedido ha sido entregado":"Seu pedido foi entregue",
   "Tu pedido ha sido cancelado":"Seu pedido foi cancelado",
   "Tu ubicación":"Sua localização",
   "Nivel: ":"Nível: ",
   "Dirección de entrega":"Endereço de entrega",
   "Tarjeta contra entrega":"Pague com cartão na entrega",
   "Efectivo":"Dinheiro",
   "Tarjeta":"Cartão",
   "Día y hora de su pedido":"Dia e hora do seu pedido",
   "Enviar cubiertos":"Enviar talheres",
   "Sí":"Sim",
   "No":"Não",
   "Detalles del pedido:":"Detalhes do pedido:",
   "Total parcial":"Subtotal",
   "Pagado con ":"Pago com ",
   "tarjeta":"Cartão",
   "Pagar en efectivo":"Pagar em dinheiro",
   "Regresar":"Regressar",
   "Nombre":"Nome",
   "Perfil actualizado correctamente":"Perfil atualizado com sucesso",
   "Por favor ingresa de nuevo para actualizar tu contraseña":"Faça login novamente para atualizar sua senha",
   "Algo salió mal":"Algo deu errado",
   "Actualizar Perfil":"Atualizar perfil",
   "Ahora puedes cambiar tu contraseña":"Agora você pode alterar sua senha",
   "No puedo iniciar sesión":"Eu não consigo aceder ",
   "No existen pedidos para mostrar":"Não há pedidos para exibir",
   "Solicitar ID":"Identificação do Pedido",
   "Tu orden ha sido entregada":"O Seu pedido foi entregue",
   "Pendiente":"Pendente",
   "Tarjeta con terminación ":"cartão terminando em ",
   "Agregar tarjeta":"Adicionar cartão",
   "Número de tarjeta":"Número de cartão",
   "Nombre de la titular":"Nome do titular",
   "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"O banco rejeitou a transação. Crie e insira um cartão digital aqui para tornar as compras mais seguras",
   "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"Os dados do cartão estão incorretos. Verifique-os para continuar.",
   "Algo salió mal al crear el pago":"Algo deu errado ao criar o pagamento",
   "Ahora":"Agora",
   " Hoy, ":" Hoje, ",
   " Mañana, ":" Amanhã, ",
   "hoy":"Hoje",
   "mańana":"de manhã",
   "Programar tu entrega":"Programar entrega",
   "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Seu pedido chegará dentro do período selecionado. Faltando 40 minutos para a entrega seu pedido não poderá ser cancelado",
   "Programar y avanzar":"Agendar e avançar",
   "Hubo un error al confirmar el pago. Detalles: ":"Ocorreu um erro ao confirmar o pagamento. Detalhes: ",
   "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"Ocorreu um erro ao confirmar o pagamento. Tente novamente com outro cartão",
   "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"O pagamento ainda está no status de \"procesamiento\". Isso é incomum entre em contato conosco",
   "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"O restaurante está fechado ou não pode aceitar pedidos no momento, agende seu pedido para mais tarde",
   "allergyText1": "Digite suas alergias e intolerâncias alimentares sobre como você deseja receber sua comida. Esses comentários serão vistos pelo restaurante ao preparar seu pedido.",
   "allergyHello": "Olá ",
   "profileAllergy": "Preferências/alergias alimentares",
   "allergyAdd": "Adicionar alergia",
   "allergy": "Alergias",
   "ckeckout_allergy_tittle": "Habilite suas alergias",
   "ckeckout_allergy_descr": "A Nossa equipe poderá vê-los para que possamos cuidar de você!",
   "edit_allergy": "Editar alergias",
   "allergy_holder": "Adicione suas alergias aqui",
   "my_allergies_list_tittle": "Minha lista de alergias ativas",
   "save": "Guardar",
   "Agotado":"Esgotado",
   "Restablecer instrucciones enviadas al correo electrónico ingresado":"Enviamos a você as instruções para redefinir sua senha por e-mail",
   "No puedo encontrar el correo electrónico o algo salió mal.":"Não consigo encontrar o e-mail ou algo deu errado",
   "Por favor ingrese un correo electrónico válido primero":"por favor digite um email válido",
   "profileMyCard":"Os meus cartões",
   "Enviar":"Enviar",
   "Ingrese OTP una vez que reciba":"Informe o código recebido",
   "Editar teléfono":"Editar telefone",
   "cardData241": "Cartão terminando em ",
   "¿Olvidaste tu contraseña?":"Esqueceu a sua senha?",
   "Alergias inactivas":"Alergias desativadas para este pedido",
   "Alergias activadas":"Alergias ativadas para este pedido",
   "allergyBannerTitle": "Saiba mais sobre alergias",
   "allergyBannerSubTitle": "e intolerâncias alimentares",
   "allergyTerms1": "O que posso comer?",
   "allergyTerms2": "Criamos este perfil para promover uma alimentação segura para nossos usuários que possuem algum tipo de alergia ou intolerância alimentar, já que 25% da população do país sofre com isso. Pretendemos desta forma ajudá-lo a obter uma compra segura priorizando e destacando as suas alergias e intolerâncias alimentares em todas as suas encomendas sem que tenha de as colocar em cada uma das suas encomendas. Se você não conhece esse assunto, pode ler até o final e aprender sua importância.",
   "allergyTerms3": "O que é uma alergia alimentar?",
   "allergyTerms4": "A alergia alimentar ocorre quando a pessoa reage com sintomas ao contato, ingestão ou inalação das proteínas de um alimento causando uma reação imune do organismo diante daquela substância externa que entra nele e se manifesta clinicamente através de diversos sinais e sintomas mais leves ou mais graves dependendo no órgão afetado. Uma intolerância alimentar não surge no momento da ingestão, pode demorar horas ou dias e só vai causar desconforto, sem riscos fatais como os que uma alergia pode causar.",
   "allergyTerms5": "Que reação pode causar um alérgeno?",
   "allergyTerms6": "As reações das alergias alimentares variam muito dependendo da pessoa afetada. Algumas dessas reações podem ser muito leves e afetar apenas uma parte ou sistema do corpo. Outros podem ser mais graves e afetar mais de um sistema corporal. As reações podem ocorrer nos primeiros minutos ou levar várias horas para aparecer após a exposição ao alérgeno alimentar.",
   "allergyTerms7": "Os sintomas ou manifestações clínicas variam dependendo do órgão ou sistema do organismo afetado:",
   "allergyTerms8": "Sistema Respiratório: Tosse persistente, chiado no peito, desconforto respiratório (sufoco, dificuldade para engolir, alteração da voz e rinite.",
   "allergyTerms9": "Sistema cutâneo (freqüente): urticária, urticária (feijão). Coceira e aperto no palato e na garganta. Inflamação dos lábios e pálpebras. Conjuntivite.",
   "allergyTerms10": "Sistema digestivo: Vómitos, náuseas, diarreia, dor abdominal aguda.",
   "allergyTerms11": "Sistema nervoso: Perda de consciência.",
   "allergyTerms12": "Sistema cardiovascular: Tonturas, hipotensão, desmaios, cianose.",
   "allergyTerms13": "Anafilaxia: Reação alérgica grave de início rápido e potencialmente fatal, após contato com o alérgeno, e quando há envolvimento de 2 ou mais órgãos ou sistemas do organismo.",
   "allergyTerms14": "As consequências mais graves são: obstrução das vias aéreas (edema laríngeo) e parada cardiorrespiratória por broncoespasmo e/ou colapso vascular. As causas mais comuns incluem alimentos, medicamentos e picadas de insetos (abelhas e vespas).",
   "allergyTerms15": "Existem mais de 120 alimentos descritos como causadores de alergias alimentares, entre os mais comuns estão: ",
   "allergyTerms16": "- Cereais que contêm glúten.",
   "allergyTerms17": "- Crustáceos e produtos à base de crustáceos.",
   "allergyTerms18": "- Ovos e produtos à base ou derivados de ovos",
   "allergyTerms19": "- Peixe e produtos à base de peixe.",
   "allergyTerms20": "- Soja e produtos à base de soja.",
   "allergyTerms21": "- Leite e seus derivados (incluindo lactose).",
   "allergyTerms22": "- Nozes e produtos derivados. (amendoim, nozes, etc.).",
   "allergyTerms23": "- Aipo e produtos derivados.",
   "allergyTerms24": "- Mostarda e produtos derivados.",
   "allergyTerms25": "- Sementes de Sésamo.",
   "allergyTerms26": "- Dióxido de enxofre e sulfito.",
   "allergyTerms27": "- Leguminosas.",
   "allergyTerms28": "- Moluscos e produtos à base de moluscos.",
   "allergyTerms29": "Agora que você já conhece esse tema, te convidamos a criar seu perfil de forma consciente e se você não está entre esses 25% da população a se conscientizar caso precisem da sua ajuda a qualquer momento. ",
   "begin": "Começar",
   "No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable":"Desculpe, não entregamos no local de entrega selecionado",
   "Fecha":"Encontro",
   "Continuar":"Continuar",
   "Ingresar dirección":"Insira o endereço",
   "Por favor ingresa tu dirección":"Digite seu endereço",
   "Ingresar dirección2":"Adicionar",
   "Por favor ingresa tu dirección2":"Adicionar ao pedido",
   " Tarifa de servicio: ":" Taxa de serviço: ",
   "Añadir más productos":"Adicionar mais produtos",
   "La compra mínima debe ser de €":"O pedido mínimo é €",
   "Domingo":"domingo",
   "Lunes":"segunda-feira",
   "Martes":"terça-feira",
   "Miércoles":"quarta-feira",
   "Jueves":"quinta-feira",
   "Viernes":"sexta-feira",
   "Sábado":"sábado",
   "Gratis":"Grátis",
   "Continuar como invitado":"Continuar como convidado",
   "Proporcione detalles adicionales para continuar":"Forneça detalhes adicionais para continuar",
   "Proporcione detalles adicionales para continuar3":"Digite seu número de telefone antes de continuar",
   "Agregar nueva tarjeta2":"Adicionar novo cartão",
   "Introduce el código de descuento":"Insira seu vale de desconto",
   "La identificación de correo electrónico ya está en uso":"Este email ja está en uso",
   "Codigo invalido":"Cupom inválido",
   "Creando orden, no cierre ni actualice la ventana":"Criando pedido, não feche ou atualize a janela",
   "Buscando direcciones":"Procurando endereços…",
   "Reducir la cantidad de": "Reduza a quantidade de",
   "Proporcione detalles adicionales":"Forneça detalhes adicionais",
   "Hay ":"Existem ",
   " disponibles, reduce la cantidad o programa tu pedido para otro momento":" disponíveis, reduza a quantidade ou agende seu pedido para outro horário",
   "Su pedido ha sido cancelado con éxito.":"Seu pedido foi cancelado com sucesso.",
   "Disponible:":"Disponível: ",
   "Usar":"Usar",
   "Cartera":"Carteira",
   "MiCartera":"A minha carteira",
   "Ingresa el monto a utilizar en esta orden":"Insira o valor a ser usado neste pedido",
   "Máximo posible en esta orden: ":"Máximo possível neste pedido: ",
   "Saldo utilizado: ":"Saldo usado: ",
   "Eliminar":"Remover",
   "El reembolso se acreditará dentro de las 24 horas.":"O reembolso será creditado em até 24 horas.",
   "Reembolso máximo de €":"Reembolso máximo de €",
   "Detalles adicionales2":"Detalhes adicionais antes de prosseguir",
   "DEJA TU OPINIÓN":"DEIXE A SUA OPINIÃO",
   "Haz click en las estrellas para hacer tu review":"Clique nas estrelas para avaliar o teu pedido",
   "Comentarios":"Comentários",
   "ESPERAMOS QUE VUELVAS PRONTO!":"ESPERAMOS QUE VOLTE EM BREVE!",
   "¡MUCHAS GRACIAS!":"OBRIGADA!",
   "Hemos recibido tu valoración correctamente":"Recebemos sua avaliação corretamente",
   "Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?":"Alterar o tipo de entrega excluirá seu carrinho. Você tem certeza que quer continuar?",
  "No puedo aplicar ":'Eu não posso aplicar ',
  "Actualmente solo estamos aceptando pedidos anticipados dentro de las ":"O tempo mínimo de preparação das nossas encomendas é ",
  " horas a partir de ahora":" horas. Escolha o horário que deseja agendar seu pedido",
  " minutos a partir de ahora":" minutos. Escolha o horário que deseja agendar seu pedido",
  "Te faltan €":"Você está perdendo €",
  " para completar el pedido mínimo y así evitar la tarifa de de pedido pequeño":" para preencher o pedido mínimo e evitar a taxa de pedido pequeno",
  "Tarifa de pedido pequeño":"Taxa de pedido pequeno",
  "Esta tarifa nos ayuda a gestionar nuestro sistema y a cubrir los costes de pedidos pequeños":"Essa taxa nos ajuda a administrar nosso sistema e cobrir os custos de pedidos pequenos",
  "Tarifa de bolsa":"tarifa de saco/embalagem"
},
});
